
html body {
  .jqstooltip,
  .flotTip {
    width: auto !important;
    height: auto !important;
    background: $gray-900;
    color: $white;
    padding: 5px 10px;
  }
}
@mixin scrollbars($size, $foreground-color, $hover-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-color: #898A8D;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $hover-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }
  &::-webkit-scrollbar-track-piece {
    background: #898A8D; 
    border-color: #898A8D;
  }
  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
body .jqstooltip {
  border-color: transparent;
  border-radius: 60px;
}

// Tooltip for the chartist chart charts
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  border-radius: $border-radius;
  padding: 10px 20px;
  background: $info;
  color: $white;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -5px;
  border: 5px solid transparent;
  border-top-color: $info;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

.c3-chart-arcs-title {
  fill: $text-muted;
}

.c3 line,
.c3 path {
  stroke: $gray-200;
}
.c3-chart-arc path {
  stroke: transparent;
}

.ReactModal__Overlay {
  z-index: 100;
  .modal-header > span {
    width: 100%;
  }
}
.react-bootstrap-table-page-btns-ul {
  float: right;
}

// Form Steps
.step-progress-adjustment {
  ol.progtrckr {
    list-style-type: none;
    padding: 0;
    text-align: center;
    // padding-left: 15%;
    @media (max-width: 839px) {
      padding: 0;
      text-align: center;
    }
  }
  .row {
    margin: 0;
  }
  .col {
    padding: 0 !important;
  }
}
ol.progtrckr {
  list-style-type: none;
  padding: 0;
  // text-align: center;
  //padding-left: 15%;
  @media (max-width: 839px) {
    padding: 0;

    text-align: center;
  }
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  width: 20%;
  position: relative;
}

ol.progtrckr li span {
  //  padding: 0 1.5rem;
  position: absolute;
  left: 0;
  bottom: -50px;
}

@media (max-width: 1200px) {
  .progtrckr li {
    width: 15%;
  }
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 839px) {
  .progtrckr em {
    display: none;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: $gray-600;
  font-weight: 500;
  border-bottom: 3px solid $gray-300;
  //pointer-events: none;
  @media (max-width: 839px) {
    width: 85px;
  }
}

ol.progtrckr li.progtrckr-doing {
  color: $light-green;
  font-weight: 500;
  border-bottom: 3px solid $gray-300;
  @media (max-width: 839px) {
    width: 85px;
  }
}

ol.progtrckr li.progtrckr-done {
  color: $light-green;
  font-weight: 500;
  border-bottom: 3px solid $light-green;
  @media (max-width: 839px) {
    border-bottom: 3px solid $light-green;
    width: 85px;
  }
  span {
    color: $label;
  }
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -24px;
  float: left;
  // left: 50%;
  @media (max-width: 839px) {
    bottom: -67px;
  }
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\2022";
  color: $gray-600;
  background-color: $gray-600;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  pointer-events: none;
  @media (max-width: 839px) {
    width: 10px;
    line-height: 10px;
  }
}

// ol.progtrckr li.progtrckr-todo:hover:before {
//   color: $light-green2;
// }

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: $white;
  background-color: $light-green;
  font-size: 30px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  // box-shadow: 0px 0px 0 15px $light-green2;
  z-index: 10;
  @media (max-width: 839px) {
    font-size: 15px;
    width: 10px;
    line-height: 10px;
    border-radius: 50%;
    box-shadow: 0px 0px 0 10px $light-green2;
  }
}

// ol.progtrckr li.progtrckr-doing:hover:before {
//   color: $light-green;
//   box-shadow: 0px 0px 0 20px rgba($color: $light-green2, $alpha: 0.7);
//   @media (max-width: 839px) {
//     box-shadow: 0px 0px 0 10px rgba($color: $light-green2, $alpha: 0.7);
//   }
// }

ol.progtrckr li.progtrckr-done:before {
  content: "\2022";
  color: $light-green;
  background-color: $light-green;
  font-size: 20px;
  font-weight: bold;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  //  box-shadow: 0px 0px 0 15px $light-green2;
  @media (max-width: 839px) {
    font-size: 15px;
    width: 15px;
    line-height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 0 10px $light-green2;
  }
}
.step-progress-new-case ol.progtrckr li:first-child {
  pointer-events: none;
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: $light-green;
  font-size: 25px;
  // box-shadow: 0px 0px 0 20px rgba($color: $light-green2, $alpha: 0.7);
}

ol.progtrckr li:last-child {
  border: none;
}

/********************** custome style ****************************/

.layer::before {
  position: absolute;
  top: 25%;
  left: 35%;
  display: block;
  color: #fff;
  text-align: center;
  content: "\2713";
  font-size: 65px;
  line-height: 65px;
  font-weight: 900;
  justify-content: center;
  align-content: center;
  @media (max-width: 839px) {
    font-size: 50px;
    line-height: 50px;
  }
}
.layer {
  background-color: rgba(0, 199, 177, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
}

.loading {
  position: absolute;
  top: 42%;
  left: 43%;
  background: rgba($color: $light-green, $alpha: 0.5);
  border-radius: 50%;
  height: 30px;
  width: 30px;

  box-shadow: 0 0 0 0 rgba($color: $light-green, $alpha: 0.5);
  transform: scale(1);
  animation: pulse2 2s infinite;
}

@keyframes pulse2 {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba($color: $light-green2, $alpha: 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba($color: $light-green2, $alpha: 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba($color: $light-green2, $alpha: 0);
  }
}

.grid {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(16, minmax(auto, 1fr));
  align-items: center;
  text-align: center;
  font-size: 1em;
  img {
    width: 100%;
  }
  @media (max-width: 900px) {
    font-size: 0.75em;
  }
  @media (max-width: 839px) {
    grid-template-columns: repeat(16, minmax(6px, 25px));
    grid-column-gap: 0;
  }
}
.grid2 {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(16, minmax(auto, auto));
  align-items: center;
  text-align: center;
  img {
    width: 100%;
  }
  @media (max-width: 839px) {
    grid-column-gap: 3px;
  }
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, auto));
  align-items: center;
  text-align: center;
  grid-gap: 1em;
  @media (max-width: 1200px) {
    text-align: start;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
  }
  @media (max-width: 993px) {
    text-align: start;
    grid-template-columns: 1fr;
    grid-gap: 0.5em;
  }
}
.grid3_tooth_system {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, auto));
  align-items: center;
  text-align: start;
  grid-gap: 1em;
  // @media (max-width: 1200px) {
  //   text-align: start;
  //   grid-template-columns: 1fr 1fr;
  //   grid-gap: 0.5em;
  // }
  @media (max-width: 993px) {
    text-align: start;
    grid-template-columns: 1fr;
    grid-gap: 0.5em;
  }
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, auto));
  align-items: center;
  grid-gap: 1em;
  @media (max-width: 1200px) {
    grid-gap: 0.7em;
    text-align: start;
    grid-template-columns: 1fr;
  }
}

.grid5 {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  // align-items: center;
  text-align: center;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
}
.grid6 {
  display: grid;
  grid-template-columns: repeat(3, minmax(20px, 1fr));
  text-align: right;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    margin-bottom: 15px;
    text-align: left;
  }
}
.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.buttons-container {
  .upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: relative;

    // height: 300px;
    overflow: hidden;
    border-radius: 5px;
    @media (min-width: 840px) {
      // width: 350px;
    }

    label {
      width: 100%;
      margin: 0;
      position: relative;
      img {
        cursor: pointer;
        // position: absolute;
        // top: 50%;
        // left: 0;
        // transform: translateY(-50%);
        object-fit: cover;
        @media (min-width: 650px) {
          height: 220px;
          max-height: 220px;
          max-width: 350px;
        }
        @media (max-width: 649px) {
          height: 180px;
          max-height: 180px;
        }
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
      }
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .receive-button {
      margin-top: 32px;
      width: 327px;
      @media screen and (max-width: 600px) {
        margin-top: 16px;
      }
    }
    .link {
      margin: 16px 0 0 0;
      text-decoration: underline;
      @media screen and (max-width: 600px) {
        margin: 0;
      }
    }
  }
}

.buttons-container3 {
  .upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: relative;

    height: 300px;
    overflow: hidden;
    border-radius: 5px;
    @media (min-width: 840px) {
      width: 100%;
    }

    label {
      width: 100%;
      margin: 0;
      position: relative;
      img {
        cursor: pointer;
        // position: absolute;
        // top: 50%;
        // left: 0;
        // transform: translateY(-50%);
        object-fit: cover;
        @media (min-width: 650px) {
          height: 220px;
          max-height: 220px;
          max-width: 350px;
        }
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
      }
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .receive-button {
      margin-top: 32px;
      width: 327px;
      @media screen and (max-width: 600px) {
        margin-top: 16px;
      }
    }
    .link {
      margin: 16px 0 0 0;
      text-decoration: underline;
      @media screen and (max-width: 600px) {
        margin: 0;
      }
    }
  }
}
.buttons-container2 {
  .upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: relative;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
    @media (min-width: 840px) {
      height: 160px;
    }

    label {
      width: 100%;
      margin: 0;
      position: relative;
      img {
        cursor: pointer;
        // vertical-align:middle;
        // position: absolute;
        // top: 50%;
        // left: 0;
        // transform: translateY(-50%);
        object-fit: cover;
        @media (min-width: 840px) {
          height: 180px;
          max-height: 180px;
        }
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
      }
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .receive-button {
      margin-top: 32px;
      width: 327px;
      @media screen and (max-width: 600px) {
        margin-top: 16px;
      }
    }
    .link {
      margin: 16px 0 0 0;
      text-decoration: underline;
      @media screen and (max-width: 600px) {
        margin: 0;
      }
    }
  }
}
.title_active {
  color: $light-green;
  font-weight: 500;
}
.title_active-gco {
  color: $dark-green;
  font-weight: 500;
}
.title_active-gcoTS-new {
  color: #00c7b1;
  font-weight: 500;
  font-size: 14px !important;
}
.title_tertment_option {
  padding-top: 32px;
  color: #00c7b1;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: 839px) {
    padding: 16px 0px 16px;
    font-size: 14px;
    font-weight: normal;
  }
}
.title_laowerAnd_upper {
  padding-top: 0px;
  color: #00c7b1;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.title_laowerAnd_upper2 {
  padding-top: 0px;
  padding-bottom: 16px;
  color: #00c7b1;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
}
.title_active-gco2 {
  color: $dark-green;
  font-weight: 500;
}
.detailes_title {
  color: $dark-green;
  font-weight: 500;
  font-size: 18px !important;
}
.title_card_active {
  color: $light-green;
  font-weight: 500;
  font-size: 18px;
}
.side {
  font-size: 24px;
  @media (max-width: 900px) {
    font-size: 16px;
    padding: 6px !important;
  }
}

textarea.green_input::-webkit-input-placeholder {
  color: $light-green2;
}

textarea.green_input:-moz-placeholder {
  /* Firefox 18- */
  color: $light-green2;
}

textarea.green_input::-moz-placeholder {
  /* Firefox 19+ */
  color: $light-green2;
}

textarea.green_input:-ms-input-placeholder {
  color: $light-green2;
}

textarea.green_input::placeholder {
  color: $light-green2;
}

.green_input {
  border-color: $light-green3;
}

input.green_input::-webkit-input-placeholder {
  color: $light-green2;
}

input.green_input:-moz-placeholder {
  /* Firefox 18- */
  color: $light-green2;
}

input.green_input::-moz-placeholder {
  /* Firefox 19+ */
  color: $light-green2;
}

input.green_input:-ms-input-placeholder {
  color: $light-green2;
}

input.green_input::placeholder {
  color: $light-green2;
}
.patient-form .css-151xaom-placeholder {
  color: $light-green2 !important;
}
.patient-form .css-bg1rzq-control {
  border-color: $light-green3 !important;
}
.not-valid-select {
  .css-bg1rzq-control {
    border-color: red !important;
  }
}
.teeth:hover {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.nav-tabs {
  border-bottom: 1px solid $light_green;
}
.nav-link.active {
  color: $light_green !important;
  border-color: $light_green $light_green #fff !important;
  font-weight: 500;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: $light_green3 $light_green3 $light_green3;
}

#next-button {
  background-color: $white !important;
  border-color: $light_green !important;
  color: $light_green !important;
  @media (min-width: 840px) {
    width: 150px;
  }
}
#finish-button {
  background-color: $white !important;
  border-color: $light_green !important;
  color: $light_green !important;
  @media (min-width: 840px) {
    width: 150px;
  }
}
#prev-button {
  background-color: $white !important;
  border-color: $light_green !important;
  color: $light_green !important;
  @media (min-width: 840px) {
    width: 150px;
  }
}

.terms_date {
  color: $red;
  padding-left: 30px;
  display: inline;
  @media (max-width: 839px) {
    padding-left: 0;
    display: block;
  }
}
.new_terms_date {
  color: $red;
  font-size: 14px;
  @media (max-width: 839px) {
  }
}
.header {
  font-size: 18px;
  @media (max-width: 839px) {
    font-size: 14px;
  }
}
.terms {
  height: 52vh;
  overflow-y: scroll;
  border: 1px solid silver;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-left: 20px;
  background: $input-bg;
  @media (max-width: 839px) {
    height: auto;
  }
}

.terms_header {
  font-weight: 800;
  padding: 20px 0;
}
.term-subHeader {
  font-weight: 500;
}
.term-content-bold {
  font-weight: 500;
}

.page-wrapper > .page-content {
  padding: 32px !important ;
  @media (max-width: 839px) {
    padding: 10px 10px 70px 10px !important;
  }
}
// .card-body {
//   @media (max-width: 839px) {
//     padding: 0.7em !important;
//   }
// }

.summary_header {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: $light_green;
  @media (max-width: 839px) {
    font-size: 14px;
  }
}
.pdf-btn {
  padding: 16px;
  @media (max-width: 839px) {
    font-size: 12px;
  }
}
.pdf {
  margin: 20px;
  text-align: left;
  @media (max-width: 839px) {
    text-align: center;
  }
}
.gray-scale {
  filter: grayscale(100%) opacity(70%);
}

.impression_img {
  width: 300px;
  height: 250px;
  @media (max-width: 839px) {
    height: 100%;
    width: 100%;
  }
}
.center {
  @media (max-width: 839px) {
    text-align: center;
  }
}
.right {
  text-align: right;
  @media (max-width: 839px) {
    text-align: center;
  }
}
.border2 {
  border: 1px solid #f5f5f5;
}

.border-l-r-none {
  border-left: none;
  border-right: none;
}
.icon-md {
  cursor: pointer;
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
  @media (max-width: 839px) {
    font-size: 18px;
    line-height: 18px;
  }
}
.icon-md2 {
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  @media (max-width: 839px) {
    font-size: 14px;
    line-height: 14px;
  }
}

#container {
  text-align: center;
  // height: 99.4vh;
  height: 100%;
  @media (max-width: 839px) {
   // height: 88.9vh;
  }
}
.modalx {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
}
.padding-top-45 {
    padding-top: 45px;
}
.modalx .modal-header {
  height: 60px !important;
  @media (max-width: 839px) {
    height: auto !important;
  }
}
.modalx2 {
  top: 15%;
  width: 55%;
  // height: 85%;
  margin: 0 auto;
  max-width: 55%;
  @media (max-width: 839px) {
    top: 2%;
    //   height: 80%;
    width: 95%;
    max-width: 95%;
  }
}

.modal-rqtainer-request {
  top: 5%;
  width: 65%;
  // height: 85%;
  margin: 0 auto;
  max-width: 65%;
  @media (max-width: 839px) {
    top: 2%;
    //   height: 80%;
    width: 95%;
    max-width: 95%;
  }
}

.modal-list {
  overflow-y: auto;
}
.modalx3 {
  top: 30%;
  // height: 35%;
  width: 50%;
  max-width: 50%;
  @media (max-width: 839px) {
    // height: 60%;
    top: 15%;
    width: 95%;
    max-width: 95%;
  }
}
.modal_revised_data {
  top: 2%;
  // height: 35%;
  width: 60%;
  max-width: 60%;
  height: 85%;
  max-height: 85%;
  @media (max-width: 839px) {
    // height: 60%;
    top: 2%;
    width: 95%;
    max-width: 95%;
  }
  .modal-content {
    overflow: auto;
  }
}
.modal-copy-link {
  top: 20%;
}
.modal-express {
  top: 20%;
}
.modalx4 {
  top: 2%;
  // height: 35%;
  width: 60%;
  max-width: 60%;
  @media (max-width: 839px) {
    // height: 60%;
    top: 2%;
    width: 95%;
    max-width: 95%;
  }
}
.FixStepModal {
  top: 2%;
  // height: 35%;
  width: 60%;
  max-width: 60%;
  @media (max-width: 839px) {
    // height: 60%;
    top: 2%;
    width: 95%;
    max-width: 95%;
  }
}
.FixStepModalBite {
  top: 2%;
  height: 100%;
  width: 60%;
  max-width: 60%;
  margin: 0 auto;

  @media (max-width: 839px) {
    // height: 60%;
    top: 2%;
    width: 95%;
    max-width: 95%;
    height: 95%;
    margin: 0 auto;
  }
  .modal-dialog {
    margin: 0 auto;
  }
  .modal-body {
    @media (max-width: 839px) {
      padding: 0 2rem;
    }
  }
  .modal-content {
    height: 95%;
    @media (max-width: 839px) {
      height: 99%;
      display: flex;
      align-self: center;
      border-radius: 12px !important;
  
    }
  }
}
.fix-preferances-modal {
  top: 2%;
  // height: 35%;
  width: 75%;
  max-width: 75%;
  @media (max-width: 839px) {
    // height: 60%;
    top: 2%;
    width: 95%;
    max-width: 95%;
  }
}
.FixXrayStepModal {
  top: 2%;
  // height: 35%;
  width: 40%;
  max-width: 40%;
  @media (max-width: 839px) {
    // height: 60%;
    top: 2%;
    width: 95%;
    max-width: 95%;
  }
}
.modalx5 {
  top: 10%;
  max-width: 50% !important;
  @media (max-width: 839px) {
    width: 100%;
    max-width: 95% !important;
  }
}
// .modalx5 .modal-dialog {

// }
.modalx2 .modal-dialog {
  width: 65%;
  // height: 70%;
  margin: 0 auto;
  max-width: 65%;
  @media (max-width: 839px) {
    height: 80%;
    width: 95%;
    max-width: 95%;
  }
}

.modal-rqtainer-request .modal-dialog {
  width: 65%;
  // height: 70%;
  margin: 0 auto;
  max-width: 65%;
  @media (max-width: 839px) {
    height: 80%;
    width: 95%;
    max-width: 95%;
  }
}
.modal-rqtainer-request .modal-content {
  min-height: 300px;
}

.modalx2 .modal-content {
  min-height: 300px;
}
.modalx3 .modal-dialog {
  width: 50%;
  max-width: 50%;
  @media (max-width: 839px) {
    // height: 80%;
    width: 95%;
    max-width: 95%;
  }
}
.modalx6 {
  top: 5%;
  // height: 35%;
  width: 60%;
  max-width: 60%;
  @media (max-width: 1032px) and (min-width: 839px  ) {
    width: 80%;
    max-width: 80%;
  }
  @media (max-width: 839px) {
    // height: 60%;
    top: 0%;
    width: 100%;
    max-width: 100%;
    margin: 0rem !important;
  }
}
.modalx6 .modal-dialog {
  width: 50%;
  max-width: 50%;
  @media (max-width: 839px) {
    width: 100%;
    max-width: 100%;
    margin: 0rem !important;
  }
}
.modalx4 .modal-dialog {
  width: 80%;
  max-width: 80%;
  @media (max-width: 839px) {
    // height: 80%;
    width: 100%;
    max-width: 100%;
    // margin: 0rem !important;
  }
}
.FixStepModal .modal-dialog {
  width: 60%;
  max-width: 60%;
  @media (max-width: 839px) {
    // height: 80%;
    width: 95%;
    max-width: 95%;
  }
}
.fix-preferances-modall .modal-dialog {
  width: 75%;
  max-width: 75%;
  @media (max-width: 839px) {
    // height: 80%;
    width: 95%;
    max-width: 95%;
  }
}
.FixXrayStepModal .modal-dialog {
  width: 50%;
  max-width: 50%;
  @media (max-width: 839px) {
    // height: 80%;
    width: 95%;
    max-width: 95%;
  }
}
.replacements {
  // height: 260px;
  max-height: 200px;
  overflow-y: auto;
  @media (max-width: 800px) {
    max-height: 150px;
  }
}
.modalx .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
}
.modalx .modal-content {
  height: 100% !important;
}
.modalx .modal-title {
  @media (max-width: 839px) {
    width: 100%;
  }
}
.steps_num {
  width: 100%;
}
.delete {
  color: red;
  cursor: pointer;
}
.modal-content {
  // height: 100vh !important;
}

.viewer-detail_header {
  font-size: 24px;
  font-weight: 500;
}
.stageTitle {
  padding: 14px;
  width: 100%;
  // height: 70px;
  @media (max-width: 800px) {
    padding: 10px;
  }
}
.stageDate {
  padding: 12px;
}
.inProgress {
  color: $white;
  background-color: $light_green;
}
.squre {
  height: 10px;
  width: 10px;
  background-color: red;
  display: inline-block;
}
.shipped {
  background-color: #888b8d;
  color: white;
}
.additional {
  background-color: #c84630;
  color: white;
}
.unused {
  background-color: #e3b505;
}
.progress-title {
  font-size: 12px;
  font-weight: 500;
}
.shipment {
  float: right;
  display: block;
  @media (max-width: 839px) {
    float: left;
  }
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.hidebtn {
  display: none !important;
}

.step2 #prev-button {
  display: none !important;
}

.green-spinner {
  color: $light-green;
  width: "5rem";
  height: "5rem";
}

.small-date {
  color: #888b8d;
  font-size: 12px;
}
.step-progress-new-case #next-button:before {
  content: "\2192" !important;
  font-size: 24px;
}
.step-progress-new-case #next-button:after {
  font-weight: 800;
}
.step-progress-new-case #prev-button:before {
  content: "\2190" !important;
  font-size: 24px;
}
.step-progress-new-case #prev-button:after {
  font-weight: 800;
}
.icon-small {
  @media (max-width: 900px) {
    padding: 0.375rem 0.35rem !important;
  }
}

.chat-grid {
  display: grid;
  grid-template-columns: 10% 80% 10%;
}

.chat-content-new {
  border-radius: 4px;
  margin-bottom: 3px;
}

.admin-msg {
  height: 100%;
  border-radius: 4px;
  background-color: rgba($color: $light-green, $alpha: 0.5) !important;
  color: $white !important;
  font-weight: 500;
  overflow: auto;
  word-wrap: break-word;

  a {
    color: $white !important;
  }
}

.doctor-msg {
  height: 100%;
  border-radius: 4px;
  background-color: rgba($color: $light-green2, $alpha: 0.7) !important;
  color: #888b8d !important;
  font-weight: 500;
  overflow: auto;
  word-wrap: break-word;
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
.border-top {
  border-top: 1px solid lightgrey;
}
.chat_grid {
  display: grid;
  grid-template-columns: 10% 90%;
}

.chat_grid2 {
  display: grid;
  grid-template-columns: 90% 10%;
}
.notifications {
  max-height: 300px;
  overflow-y: scroll;
}
.disable_notifications {
  pointer-events: none;
}
.disable_attachment {
  pointer-events: none;
  opacity: 0.65;
}
.tech-notes_header {
  font-size: 24px;
  font-weight: 500;
}
.tech-notes_detail {
  font-size: 18px;
  font-weight: 400;
  overflow-wrap: break-word;
}
.viewer_case_details {
  // max-height: 550px;
  // overflow: auto;
  // overflow-x: hidden;
  padding-right: 1em;
}

.device {
  height: 280px;
}

.dropDown_container {
  border-radius: 8px;
  padding: 16px;
  margin: 0 auto;
  display: grid;
  text-align: start;
  align-items: center;
  font-weight: 400;
  grid-template-columns: 3fr auto;
  border: 1px solid #f0f3f5;
}
.dropDown_container_content {
  position: relative;
  display: flex;
  margin: 0 auto;
}

.dropDown_list_item {
  padding: 10px 20px;
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  //border-bottom: 1px solid #f0f3f5;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #f0f3f5;
  }
  @media (max-width: 839px) {
    font-size: 12px;
  }
}

.dropDown_select_content_container {
  overflow: auto;
  position: absolute;
  align-content: center;

  top: 0;
  left: 0;
  width: 100%;
  margin-top: 5px;
  padding: 10px 0;
  list-style: none;
  background-color: white;
  border-radius: 8px;
  border: solid 1px #f0f3f5;
  z-index: 10;
}
.border-l-r {
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  height: 10px;
}
.border-r {
  border-right: 1px solid silver;
  height: 10px;
}
.mobile-padding {
  // @media (max-width: 1000px) {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
  //}
}
.case-info {
  @media (max-width: 700px) {
    text-align: center;
  }
}
.treatment-setups {
  @media (max-width: 839px) {
    padding: 10px 0 10px 0 !important;
  }
}
.spinner-container {
  height: 200px;
}
.retiainer-border {
  padding: 16px;
  border: 1px solid silver;
  // @media (max-width: 839px) {
  //   border: none;
  // }
}

.disable-btn {
  pointer-events: none;
}
#next-button.disable-btn {
  pointer-events: none;
  // background-color: lightgray !important;
  // color: darkslategray !important ;
  // border: 1px solid lightgray !important;
  // box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  // transform: scale(1);
  // animation: pulse 2s infinite;
}
#prev-button.disable-btn {
  pointer-events: none;
  // background-color: lightgray !important;
  // color: darkslategray !important ;
  // border: 1px solid lightgray !important;
  // box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  // transform: scale(1);
  // animation: pulse 2s infinite;
}
#finish-button.disable-btn {
  pointer-events: none;
  // background-color: lightgray !important;
  // color: darkslategray !important ;
  // border: 1px solid lightgray !important;
  // box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  // transform: scale(1);
  // animation: pulse 2s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.loyalty {
  font-size: 20px;
}

.pdf-content {
  box-shadow: none !important;
}
.FoilView-marginText-8 {
  margin: 0 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.download-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}

.pdf-grid {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
}
.notifications-card {
  max-height: 440px;
  overflow: auto;
}
.pause-btn {
  // background-color: lightgray !important;
  // color: darkslategray !important ;
  // border: 1px solid lightgray !important;
  box-shadow: 0 0 0 0 rgb(52, 230, 155);
  transform: scale(1);
  animation: pulse3 2s infinite;
}
@keyframes pulse3 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 230, 155, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 230, 155, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 230, 155, 0);
  }
}

.home-carousel .carousel-item {
  @media (max-width: 839px) {
    height: 170px;
  }
}
.home-carousel .carousel-inner {
  @media (max-width: 839px) {
    height: 170px;
  }
}

.gallery-carousel .carousel-item {
  @media (max-width: 839px) {
    height: 250px;
  }
}
.gallery-carousel .carousel-inner {
  height: 500px;
  @media (max-width: 839px) {
    height: 250px;
  }
  img {
    height: 720px !important;
  }
}

.case-container {
  .upload-container {
    //display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // position: relative;

    // height: 300px;
    // overflow: hidden;
    // border-radius: 5px;
    @media (min-width: 840px) {
      width: 100%;
    }
  }
}
.loading2::before {
  position: absolute;
  top: 38%;
  left: 40%;
  display: block;
  color: #fff;
  text-align: center;
  // content: "loading .....";
  font-size: 65px;
  line-height: 65px;
  font-weight: 900;
  justify-content: center;
  align-content: center;
  @media (max-width: 839px) {
    font-size: 50px;
    line-height: 50px;
  }
  z-index: 10;
}
.loading2 {
  background-color: rgba(219, 215, 215, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
  z-index: 10;
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner_3,
.spinner_3:before,
.spinner_3:after {
  border-radius: 50%;
  width: 1.75em;
  height: 1.75em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.spinner_3 {
  color: $light-green;
  font-size: 5px;
  margin-left: 2%;
  margin-top: -2.5%;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  @media (min-width: 599px) and (max-width: 839px) {
    margin-left: 5% !important;
    margin-top: -5% !important;
  }
  @media (max-width: 600px) {
    margin-left: 15% !important;
    margin-top: -12% !important;
  }
}
.spinner_3:before,
.spinner_3:after {
  content: "";
  position: absolute;
  top: 0;
}
.spinner_3:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner_3:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
#searchinput {
  width: 200px;
}
#searchclear {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 2px;
  height: 18px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  //color: #ccc;
  align-items: center;
  justify-content: center;
  //color: $light-green;
}
#searchclear2 {
  position: absolute;
  right: 35px;
  top: 0;
  bottom: 2px;
  height: 18px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  //color: #ccc;
  align-items: center;
  justify-content: center;
  //color: $light-green;
}
.disabled_search {
  pointer-events: none;
  color: #ccc;
}
.add-case {
  align-items: center;
  justify-content: center;
  background-color: $light-green !important;
  cursor: pointer;
  //  top: 90% !important;
  margin: 0 auto !important;
  // left: calc(-100%) !important;
  width: 100%;
  padding: 12px;
  position: fixed;
  width: 90%;
  bottom: 5%;
  right: 0;
  left: 0;
  //padding: 20px 25px !important;
}

#audioclear {
  position: absolute;
  padding-left: 10px;
  top: 30%;
  bottom: 0;
  // height: 18px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  align-items: center;
  justify-content: center;
}
#customizer2 {
  position: fixed;
  // padding-left: 50px;
  left: 85% !important;
  top: 25%;
  bottom: 0;
  // height: 18px;
  margin: auto;
  font-size: 14px;
  color: #ccc;
  align-items: center;
  justify-content: center;
  background: none !important;
  box-shadow: none !important;
  height: auto !important;
  z-index: 999;
}
.customizer2 {
  /* background: #fff; */
  position: fixed;
  height: 100%;
  width: 280px;
  top: 0px;
  right: -280px;
  z-index: 100;
  /* box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05); */
  /* transition: 0.3s ease-in;
*/
}
.status_list {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: blue;
  cursor: pointer;
}
.status_list_disabled {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabled-btn {
  pointer-events: none;
  color: gray;
}
.notification_list {
  cursor: pointer;
  &:hover,
  &.active {
    background: $gray-100;
  }
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ts_viewer {
  @media (max-width: 839px) {
    font-size: 12px;
  }
}
.ts_title {
  @media (max-width: 839px) {
    font-size: 12px;
    font-weight: 500;
  }
}
#replacment-table .table thead th {
  border-top: none !important;
  border-bottom: none !important;
}
#replacment-table .table tbody td {
  border-top: none !important;
  border-bottom: none !important;
  padding: 10px !important;
  text-align: center;
  align-items: center;
  justify-content: center;
}
#cases_table .table thead th {
  border-top: 1px solid #c8c8ca !important;
  padding: 10px 5px !important;
  color: $light-green;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: inherit;
}
#cases_table .table tbody th {
  padding: 10px 5px !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  vertical-align: inherit;
}

#cases_table .table tbody td {
  padding: 10px 5px !important;
  text-align: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  vertical-align: inherit;
}
#cases_table .green-btn {
  @media (max-width: 839px) {
    font-size: 10px;
  }
}
.stage_table {
  box-sizing: unset !important;
  max-width: 100%;
  margin: 0;
}
.table thead th {
  vertical-align: middle;
  border-top: none;
  // border-top: 2px solid $light-green;
  @media (max-width: 839px) {
    font-size: 12px;
  }
}
.table tbody th {
  vertical-align: middle;

  @media (max-width: 839px) {
    font-size: 12px;
  }
}
.table tbody td {
  vertical-align: middle;

  @media (max-width: 839px) {
    font-size: 12px;
  }
}
.progress-table {
  @media (max-width: 839px) {
    padding: 0 !important;
    margin: 0 auto;
  }
}

.case-status {
  @media (max-width: 839px) {
    justify-content: center;
  }
}

.list-title {
  @media (max-width: 839px) {
    display: grid;
  }
}
.order_1 {
  @media (max-width: 839px) {
    grid-row: 3;
  }
}
.order_2 {
  @media (max-width: 839px) {
    grid-row: 2;
  }
}
.order_3 {
  @media (max-width: 839px) {
    grid-row: 1;
  }
}
.green-border {
  border: 1px solid $light-green;
}
.active-clear {
  color: $light-green;
}

#container_btn {
  position: absolute;
  top: 50%;
  left: 50%;
}

#content {
  position: relative;
  left: 50%;
  top: 50%;
}

.-pageSizeOptions {
  @media (max-width: 839px) {
    display: none;
  }
}

.pagination_grid {
  display: grid;
  grid-column-gap: 0.5em;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pagination_grid2 {
  display: grid;
  grid-column-gap: 0.2em;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.page-btn {
  font-size: 10px;
}
.divider {
  margin: 10px 0;
}
.replacment-tabs {
  max-height: 300px;
}
.add-replacment {
  font-size: 25px;
  margin-bottom: 25px;
  cursor: pointer;
  @media (max-width: 839px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.custom-checkbox {
  .custom-control-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #2e2e2f;
    @media (max-width: 839px) {
      line-height: 22px;
    }
  }
}

.replacment-checkbox {
  .custom-control-label::after {
    background-color: $light-green !important;
  }
}
.replacment-checkbox_white {
  .custom-control-label::before {
    background: none !important;
  }
}
.replacment-checkbox_green {
  .custom-control-label::after {
    background-color: $light-green !important;
  }
}
.replacment-checkbox_white {
  .custom-control-label::after {
    background-color: #e5e5e5 !important;
  }
}

.inbox-grid {
  display: grid;
  grid-template-columns: 25px 1fr auto;
  grid-column-gap: 1em;
  align-items: center;
  @media (max-width: 839px) {
    grid-column-gap: 0.75em;
    grid-template-columns: 25px 100px auto;
  }

  label {
    margin-bottom: 0 !important;
  }

  img {
    width: 18px;
    height: 18px;
  }
}
.recording-grid {
  display: grid;
  grid-template-columns: 20px 50px 20px 20px;
  grid-column-gap: 1em;
  align-items: center;
  @media (max-width: 839px) {
    grid-column-gap: 0.75em;
  }
}
.recButton {
  width: 12px;
  height: 12px;
  font-size: 0;
  background-color: red;
  border: 0;
  border-radius: 50%;
  outline: none;
}
.Rec {
  animation-name: pulseRec;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes pulseRec {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
  }
  65% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
  }
  90% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
  }
}
.icon-md-new {
  font-size: 16px;
  cursor: pointer;
}
.icon-md-new2 {
  font-size: 20px;
  cursor: pointer;
  width: fit-content;
}
.red-icon {
  color: red;
}
.green-icon {
  color: #00cc00;
}

.disable-icon {
  pointer-events: none;
  opacity: 0.65;
}

.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .loading-bar-file {
    display: flex;
    height: 10px;
    width: 100%;
    max-width: 320px;
    box-shadow: inset 0px 0px 0px 1px #c8c8cd;
    border-radius: 50px;
    overflow: hidden;

    @keyframes progress-animation {
      0% {
        width: 0%;
      }
      20% {
        width: 10%;
      }
      40% {
        width: 30%;
      }
      50% {
        width: 60%;
      }
      100% {
        width: 90%;
      }
    }

    .progress-bar-file {
      display: flex;
      height: 100%;
      width: 90%;
      background: #409be9;
      animation: progress-animation 5s ease-in-out;
    }
  }
}

.case-container {
  position: relative;
}
.current-case {
  position: absolute;
  left: 0;
  right: 0;
  //  top: 1rem;
}

/********************************************************/
.main-tree {
  position: relative;
}
.tree-content {
  position: absolute;
  width: 100%;
  left: -0.6%;
  top: 50%;
  @media (max-width: 1200px) {
    left: -0.1%;
  }
  @media (max-width: 839px) {
    left: -0.5%;
  }
  @media (max-width: 600px) {
    left: 1%;
  }
}
.tree2 {
  position: relative;
}
.tree2-link {
  content: "";
  position: absolute;
  left: 28.7%;
  width: 11px;
  height: 35px;
  border-left: 2px solid $light-green2;
  z-index: 1;
  @media (max-width: 839px) {
    left: 21.2%;
    border-left: 2px solid $light-green2;
    z-index: 1;
    top: -10px;
  }
}
.tree2-link2 {
  content: "";
  position: absolute;
  left: 10%;
  right: 0;
  width: 40%;
  height: 2px;
  border-top: 2px solid $light-green2;
  z-index: 1;
  top: 35px;
  @media (max-width: 839px) {
    top: 25px;
    width: 70%;
  }
}

.tree2-link3 {
  content: "";
  position: absolute;
  left: 10%;
  height: 40px;
  border-left: 2px solid $light-green2;
  z-index: 1;
  top: 35px;
  @media (max-width: 839px) {
    height: 30px;
    border-left: 2px solid $light-green2;
    z-index: 1;
    top: 25px;
    left: 9.8%;
  }
}

.tree2-link4 {
  content: "";
  position: absolute;
  left: 28.7%;
  height: 40px;
  border-left: 2px solid $light-green2;
  z-index: 1;
  top: 35px;
  @media (max-width: 839px) {
    height: 30px;
    left: 44%;
    border-left: 2px solid $light-green2;
    z-index: 1;
    top: 25px;
  }
}

.tree2-link5 {
  content: "";
  position: absolute;
  left: 49.9%;
  height: 40px;
  border-left: 2px solid $light-green2;
  z-index: 1;
  top: 35px;
  @media (max-width: 839px) {
    height: 30px;
    left: 80%;
    border-left: 2px solid $light-green2;
    z-index: 1;
    top: 25px;
  }
}
.tree2-link6 {
  content: "";
  position: absolute;
  left: 9.4%;
  height: 10px;
  width: 10px;
  z-index: 1;
  background-color: $gray-600;
  border-radius: 50%;
  top: 70px;
  color: $light-green;
  background-color: $light-green2;
  font-size: 20px;
  font-weight: bold;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 0 15px $light-green2;
  @media (max-width: 839px) {
    left: 9.5%;
    top: 50px;
    width: 10px;
    height: 10px;
    line-height: 3px;
    box-shadow: 0px 0px 0 10px $light-green2;
  }
  @media (max-width: 600px) {
    left: 9%;
  }
  @media (max-width: 320px) {
    left: 9%;
  }
}
.tree2-link6:before {
  content: "\2713";
  @media (max-width: 839px) {
    font-size: 13px;
    border-radius: 50%;
    line-height: 12px;
  }
}

.tree2-link6-doing {
  content: "";
  position: absolute;
  left: 9.3%;
  height: 10px;
  width: 10px;
  z-index: 1;
  background-color: $gray-600;
  border-radius: 50%;
  top: 70px;
  color: $light-green;
  background-color: $light-green2;
  font-size: 25px;
  font-weight: bold;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 0 15px $light-green2;
  text-align: center;
  @media (max-width: 839px) {
    left: 9.3%;
    top: 50px;
    width: 10px;
    height: 10px;
    line-height: 3px;
    box-shadow: 0px 0px 0 10px $light-green2;
  }
  @media (max-width: 600px) {
    left: 8.5%;
  }
}
.tree2-link6-doing:before {
  content: "\2022";
  color: $light-green;
  // background-color: $light-green2;
  font-size: 30px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  z-index: 10;
  @media (max-width: 839px) {
    font-size: 14px;
    border-radius: 50%;
  }
}
.tree2-link6-doing:hover:before {
  background-color: rgba($color: $light-green2, $alpha: 0.7);
  @media (max-width: 839px) {
    background-color: rgba($color: $light-green2, $alpha: 0.7);
  }
}
.tree2-link7 {
  content: "";
  position: absolute;
  left: 28.1%;
  height: 10px;
  width: 10px;
  z-index: 1;
  background-color: $gray-600;
  border-radius: 50%;
  top: 70px;
  color: $light-green;
  background-color: $light-green2;
  font-size: 20px;
  font-weight: bold;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 0 15px $light-green2;
  @media (max-width: 839px) {
    left: 43%;
    top: 50px;
    width: 10px;
    height: 10px;
    line-height: 3px;
    box-shadow: 0px 0px 0 10px $light-green2;
  }
}
.tree2-link7:before {
  content: "\2713";
  @media (max-width: 839px) {
    font-size: 13px;
    border-radius: 50%;
    line-height: 12px;
  }
}
.tree2-link7-todo {
  content: "";
  position: absolute;
  left: 28.3%;
  z-index: 1;
  background-color: $gray-600;
  border-radius: 50%;
  top: 70px;
  color: $light-green;
  // background-color: $light-green2;
  font-size: 15px;
  font-weight: bold;
  width: 10px;
  height: 10px;
  line-height: 15px;
  border-radius: 50%;
  // box-shadow: 0px 0px 0 15px $light-green2;
  text-align: center;
  @media (max-width: 839px) {
    left: 43.3%;
    top: 48px;
  }
  @media (max-width: 600px) {
    left: 42.7%;
  }
  @media (max-width: 320px) {
    left: 42.5%;
  }
}

.tree2-link7-doing {
  content: "";
  position: absolute;
  left: 28.1%;
  height: 10px;
  width: 10px;
  z-index: 1;
  background-color: $gray-600;
  border-radius: 50%;
  top: 70px;
  color: $light-green;
  background-color: $light-green2;
  font-size: 25px;
  font-weight: bold;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 0 15px $light-green2;
  text-align: center;
  @media (max-width: 839px) {
    left: 43.4%;
    top: 50px;
    width: 10px;
    height: 10px;
    line-height: 3px;
    box-shadow: 0px 0px 0 10px $light-green2;
  }
  @media (max-width: 600px) {
    left: 43%;
  }
  @media (max-width: 320px) {
    left: 42.5%;
  }
}
.tree2-link7-doing:before {
  content: "\2022";
  @media (max-width: 839px) {
    font-size: 14px;
    border-radius: 50%;
  }
}

.tree2-link8 {
  content: "";
  position: absolute;
  left: 49.56%;
  height: 10px;
  width: 10px;
  z-index: 1;
  background-color: $gray-600;
  border-radius: 50%;
  top: 70px;
  color: $light-green;
  // background-color: $light-green2;
  font-size: 15px;
  font-weight: bold;
  width: 10px;
  height: 10px;
  line-height: 15px;
  border-radius: 50%;
  // box-shadow: 0px 0px 0 15px $light-green2;
  text-align: center;
  @media (max-width: 839px) {
    left: 79.5%;
    top: 48px;
  }

  @media (max-width: 600px) {
    left: 79%;
  }
  @media (max-width: 320px) {
    left: 78.5%;
  }
}
.tree2-link8-doing {
  content: "";
  position: absolute;
  left: 49.4%;
  height: 10px;
  width: 10px;
  z-index: 1;
  background-color: $gray-600;
  border-radius: 50%;
  top: 70px;
  color: $light-green;
  background-color: $light-green2;
  font-size: 25px;
  font-weight: bold;
  width: 15px;
  height: 15px;
  line-height: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 0 15px $light-green2;
  text-align: center;
  @media (max-width: 1200px) {
    left: 49.2%;
  }
  @media (max-width: 839px) {
    left: 79.4%;
    top: 50px;
    width: 10px;
    height: 10px;
    line-height: 3px;
    box-shadow: 0px 0px 0 10px $light-green2;
  }
  @media (max-width: 600px) {
    left: 79.2%;
  }
  @media (max-width: 320px) {
    left: 79%;
  }
}
.tree2-link8-doing:before {
  content: "\2022";
  @media (max-width: 839px) {
    font-size: 14px;
    border-radius: 50%;
  }
}
.tree2-link9 {
  position: absolute;
  left: 6%;
  z-index: 1;
  top: 110px;
  font-weight: 500;
  color: $light-green;
  font-size: 12px;
  cursor: pointer;
  @media (max-width: 839px) {
    width: 25%;
    left: 0.1%;
    top: 70px;
    font-size: 10px;
    text-align: center;
    margin: 1%;
  }
  @media (max-width: 600px) {
    width: 30%;
  }
}
.tree2-link10 {
  position: absolute;
  left: 24%;
  z-index: 1;
  top: 110px;
  font-weight: 500;
  color: $light-green;
  font-size: 12px;
  cursor: pointer;
  @media (max-width: 839px) {
    left: 33%;
    width: 25%;
    top: 70px;
    font-size: 10px;
    text-align: center;
    margin: 1%;
  }
  @media (max-width: 600px) {
    width: 30%;
  }
}
.tree2-link10-disabled {
  position: absolute;
  left: 23.5%;
  z-index: 1;
  top: 110px;
  font-weight: 500;
  color: $gray-600 !important;
  font-size: 12px;
  pointer-events: none !important;
  @media (max-width: 839px) {
    left: 33%;
    width: 30%;
    top: 70px;
    font-size: 10px;
    text-align: center;
    margin: 1%;
  }
}
.tree2-link11 {
  position: absolute;
  left: 45%;
  z-index: 1;
  top: 110px;
  font-weight: 500;
  color: $light-green;
  font-size: 12px;
  width: 150px;
  cursor: pointer;

  @media (max-width: 839px) {
    left: 65%;
    width: 30%;
    top: 70px;
    font-size: 10px;
    text-align: center;
    margin: 1%;
  }
}
.tree2-link11-disabled {
  position: absolute;
  left: 45%;
  z-index: 1;
  top: 110px;
  font-weight: 500;
  color: $gray-600 !important;
  font-size: 12px;
  width: 150px;
  pointer-events: none !important;
  @media (max-width: 839px) {
    left: 65%;
    width: 30%;
    top: 70px;
    font-size: 10px;
    text-align: center;
    margin: 1%;
  }
}
.loading-progress .progress {
  height: 1.5em;
  border: 1px solid $gray-600;
}

#clearFile {
  position: absolute;
  padding-left: 15px;
  top: -1px;
  // bottom: 0;
  // height: 18px;
  right: 0;
  font-size: 14px;
  cursor: pointer;
  color: $gray-600;
  align-items: center;
  justify-content: center;
}

.upload-file-progress {
  display: grid;
  grid-template-columns: 7fr 5fr;
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  font-size: 14px;
  @media (max-width: 839px) {
    font-size: 12px;
  }
  i {
    cursor: pointer;
    width: fit-content;
  }
  .bg-info {
    background-color: #00c7b150 !important;
  }
}

.tooth-slider {
  input[type="range"] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: $gray-200;
    border-radius: 25px;
    border: 0px solid #000101;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $light-green;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.6px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $gray-200;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: $gray-200;
    border-radius: 25px;
    border: 0px solid #000101;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $light-green;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: $gray-200;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type="range"]::-ms-fill-upper {
    background: $gray-200;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type="range"]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $light-green;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: $gray-200;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: $gray-200;
  }
}

.toggle-slider {
  input[type="range"] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: $gray-200;
    border-radius: 25px;
    border: 0px solid #000101;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $dark-green;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.6px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $gray-200;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: $gray-200;
    border-radius: 25px;
    border: 0px solid #000101;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $dark-green;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 12.8px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: $gray-200;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type="range"]::-ms-fill-upper {
    background: $gray-200;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type="range"]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: $dark-green;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: $gray-200;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: $gray-200;
  }
}
.ts-mobile-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  text-align: right;
  align-items: flex-end;
}
.copy-grid {
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-column-gap: 1em;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }
}

.action-btn {
  border-radius: 8px;
  @media (max-width: 839px) {
    font-size: 10px;
  }
}

.new-img {
  image {
    width: 100%;
    height: 720px;
  }
}
.fhNQJW div {
  svg {
    path {
      stroke: $black;
    }
  }
}
.fhNQJW {
  border-radius: 0 !important;
  z-index: 99;
  box-shadow: none !important;
  border-bottom: 1px solid rgb(204, 204, 204);
}
.fhNQJW div:last-child {
  svg {
    height: 0;
    width: 0;
    padding: 12px;
    margin-top: 5px;
    background: url(https://img.icons8.com/windows/32/000000/rotate-left.png)
      no-repeat center / cover;
  }
}

.react-viewer-canvas {
  .react-viewer-image {
    @media (max-width: 839px) {
      width: 90% !important;
      height: 40% !important;
      transform: translateX(5%) translateY(50%) rotate(0deg) scaleX(1) scaleY(1) !important;
    }
  }
}

.react-viewer-attribute {
  font-size: 18px !important;
  color: $white !important;
  font-weight: 500;
  margin-bottom: 1% !important;
  @media (max-width: 839px) {
    margin-bottom: 5% !important;
  }
}

.react-viewer-toolbar {
  margin: 0 !important;
  margin-bottom: 1% !important;
  @media (max-width: 839px) {
    margin-bottom: 2% !important;
  }
}
.react-viewer-img-details {
  display: none;
}
.react-viewer-footer {
  margin-bottom: 1%;
  // top: 85%;
  @media (max-width: 839px) {
    top: 70%;
  }
}

.react-viewer-list {
  margin: 0 auto !important;
  text-align: center !important;
}

.reset-image {
  position: absolute;
  right: 27px;
  z-index: 1;
  top: 147px;
  user-select: none;
  box-shadow: rgba(53, 67, 93, 0.32) 0px 2px 6px;
  background: rgb(255, 255, 255);

  text-align: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  @media (max-width: 839px) {
    right: 27px;
  }
  i {
    color: black;
    font-size: 17px;
    // padding: 14px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
  }
}

.lb-canvas {
  transform: translate3d(0px, 0px, 0px) scale(0.7) rotate(0deg);
  @media (max-width: 839px) {
    transform: translate3d(0px, 0px, 0px) scale(0.9) rotate(0deg);
  }
}

.patient-name {
  text-align: start;
  @media (max-width: 839px) {
    text-align: center;
  }
}

.case-details-tabs {
  .nav .nav-tabs {
    display: flex !important;
  }

  ul li:last-child {
    flex: 1 !important;
    text-align: end;
    pointer-events: none;
    color: $light-green;
    font-weight: 500;
    @media (max-width: 839px) {
      text-align: center;
    }
  }
  .chat-list li:last-child {
    pointer-events: auto !important;
  }
}

.exite-stepper {
  position: relative;
  .exite-stepper-icon {
    position: absolute;
    color: red;
    right: 2%;
    z-index: 2;
    font-size: 18px;
    @media (max-width: 839px) {
      font-size: 14px;
      right: 5%;
    }
  }
}

.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba($color: $light-green, $alpha: 0.7) !important;
}
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba($color: $light-green, $alpha: 0.7) !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    @media (max-width: 839px) {
      font-size: 16px !important;
    }
  }
}
input[type="email"],
input[type="text"],
input[type="number"],
textarea {
  color: #525f7f !important;
  @media (max-width: 839px) {
    font-size: 16px !important;
    color: #525f7f !important;
  }
}

.lb-canvas .lb-img.lb-loading {
  opacity: 1 !important;
}

.filter-msg-num {
  @media (max-width: 839px) {
    padding: 20px 0;
  }
}

.highlight {
  background-color: yellow;
  font-weight: 500;
}
.highlight2 {
  background-color: rgba(255, 187, 0, 0.486);
  font-weight: 500;
}
.status_button {
  // padding: 5px;
  // border-radius: 4px;
  // text-align: center;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
  align-items: center;
  
  
}
.grid_container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
}
.auto_grid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.custom-label {
  display: inline-block;
  width: auto;
  text-align: left;
  align-self: end;
}

.label-container {
  .custom-label {
    display: inline-block;
    width: auto;
    text-align: left;
  }
}

// .auto_grid2 {
//   display: grid;
//   grid-template-columns: 40px 40px auto 50px;
//   align-items: center;
//   justify-content: left;
//   grid-gap: 1em;
// }
.auto_grid2 {
  display: inline-block;
  width: 50px;
}
.auto_grid3 {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: left;
  grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.auto_grid4 {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.auto_grid5 {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;
  grid-gap: 1em;
}
.num-btn {
  height: 40px;
  background-color: $white;
  color: $dark-green;
  font-weight: bold;
  border: 1px solid $gray-200;
  &:hover {
    background-color: $white;
    color: $dark-green;
    border: 1px solid $gray-200;
  }
}
.num-btn-active {
  height: 40px;
  background-color: $dark-green;
  color: $white;
  font-weight: bold;
  border: none;
  &:hover {
    background-color: $dark-green;
    color: $white;
  }
}
.preference-btn {
  height: 40px;
  background-color: $white;
  color: $dark-green;
  font-weight: bold;
  border: 1px solid $gray-200;
  width: 200px;
  &:hover {
    background-color: $white;
    color: $dark-green;
    border: 1px solid $gray-200;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}
.preference-btn-active {
  height: 40px;
  background-color: $dark-green;
  color: $white;
  font-weight: bold;
  border: none;
  width: 200px;
  &:hover {
    background-color: $dark-green;
    color: $white;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}
.custom-number::-webkit-outer-spin-button,
.custom-number::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.custom-number[type="number"] {
  // -moz-appearance: textfield;
  &:focus {
    background-image: linear-gradient($light-green, $light-green),
      linear-gradient(#dee2e6, #dee2e6) !important;
  }
}

.btn-radius {
  border-radius: 4px;
}
.card-footer {
  background-color: $white;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-column-gap: 16px;
  // padding: 0 !important;
  // padding-top: 24px !important;
  @media (max-width: 839px) {
    border-top: unset;
  }
}

.sidenav-gco {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: $light-green;
  overflow-x: hidden;
  // transition: 0.5s;
  padding-top: 60px;
  color: $white;
}
.side-container {
  position: relative;
  top: 1em;
  padding: 1em;
  padding-right: 0;
  @media (max-width: 839px) {
    padding: 0.5em;
    padding-right: 0;
  }
}
.sidenav-gco .step {
  position: relative;
  padding: 0.5em 1em;
  padding-right: 0;
  text-decoration: none;
  font-size: 18px;
  color: rgba($color: $white, $alpha: 0.5);
  display: block;
  transition: 0.3s;
  cursor: pointer;
  height: 50px;
  @media (max-width: 839px) {
    font-size: 16px;
  }
}

.sidenav-gco .step:hover {
  color: #f1f1f1;
}
.sidenav-gco .custome-step {
  text-decoration: none;
  color: rgba($color: $white, $alpha: 0.5);
  display: block;
  transition: 0.3s;
  cursor: pointer;
  @media (max-width: 839px) {
  }
}

.sidenav-gco .custome-step:hover {
  color: #f1f1f1;
}

.sidenav-gco .closebtn {
  cursor: pointer;
  // position: absolute;
  // top: 0;
  //right: 25px;
  font-size: 36px;
  margin-left: 0.5em;
}

#main-gco {
  transition: margin-left 0.5s;
  padding: 16px;
  padding-bottom: 0;
  border-radius: 0px 0px 0px 41px;
  @media (max-width: 839px) {
    padding: 0px;
  }
}

@media screen and (max-height: 450px) {
  .sidenav-gco {
    padding-top: 15px;
  }
  .sidenav-gco .step {
    font-size: 18px;
  }
}
.close-gco {
  font-size: 25px;
  cursor: pointer;
}

.active-step {
  color: $light-green !important;
  height: 100px !important;
  padding: 0 !important;
  // position: absolute;
  // right: 20%;
  // bottom: 30%;
  .step-icon-grid {
    bottom: 30%;
  }
}

.case-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  margin-left: 250px;
  @media (max-width: 839px) {
    display: unset;
  }
}

.case-title {
  font-size: 24px;
  @media (max-width: 839px) {
    font-size: 18px;
    text-align: center;
  }
}

.css-19bqh2r {
  fill: $light-green;
}
.custom-invalid-feedback {
  color: #fc4b6c;
  text-align: start;
  font-weight: 500;
  // max-width: 200px;
  // text-align: center;
}

.case-type-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  @media (max-width: 1120px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}

.photos-xrays-grid {
  display: grid;
  grid-template-columns: 5fr 1.5fr;
  grid-gap: 7em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-gap: 2em;
  }
}
.card-img {
  // height:170px;
  //  max-height: 150px;
  // max-width: 220px;
}
.inner-photos-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }
}
.inner-xrays-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  grid-template-rows: 3fr;
}
.border-bottom-2 {
  display: none;
  border-bottom: 1px solid lightgrey;
  @media (max-width: 839px) {
    display: block;
  }
}

.image-overlay-container {
  position: relative;
  margin: 0 auto;
  @media (max-width: 839px) {
    margin: 0 0;
  }
}

.image-overlay-container-fix-xray {
  position: relative;
  margin: 0 auto;
  @media (max-width: 839px) {
    // margin: 0 0;
  }
}

.overlay-loader {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.test {
  img {
    position: relative;
  }
}

.circule-progress-bar {
  height: 100%;
  background-color: #dcdcdc;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: $dark-green !important;
  font-weight: 500;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: $dark-green !important;
}

.main-photos-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.25fr 1fr;
  grid-gap: 1em;
  row-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
  }
}
.custome-input-text {
  @include callout;
}
.main-xrays-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  text-align: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
}
.main-fix-photos-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  row-gap: 1em;
  place-items: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
  }
}
.impressions_grid {
  display: grid;
  grid-template-columns: 200px 200px 200px;
  grid-gap: 1em;
  text-align: center;
  align-items: center;
  justify-content: start;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

.active_impression_card {
  background-color: $dark-green;
  color: $white;
  font-weight: 500;
  cursor: pointer;
  .treatment-type-title {
    color: $white;
  }
}
.impression_card {
  color: $dark-green;
  font-weight: 500;
  cursor: pointer;
  .treatment-type-title {
    color: $dark-green;
  }
}

.treatment_type_grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1em;
  text-align: center;
  align-items: center;

  justify-content: start;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
.add-arrow {
  position: relative;
}
.arrow_up {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
}

.prescription_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.prescription_summery_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 50px;
  grid-gap: 16px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
}
.treat_arch_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}
.treat_arch_btn {
  height: 40px;
  background-color: $white;
  color: $dark-green;
  font-weight: bold;
  border: 1px solid $gray-200;
  width: 200px;
  // padding: 10px 35px;
  &:hover {
    background-color: $white;
    color: $dark-green;
    border: 1px solid $gray-200;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}
.treat_arch_btn_active {
  height: 40px;
  background-color: $dark-green;
  color: $white;
  font-weight: bold;
  border: none;
  width: 200px;
  //  padding: 10px 35px;

  &:hover {
    background-color: $dark-green;
    color: $white;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}
.wear_cycle_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}

.prescription_footer_grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1em;
  align-items: center;
  text-align: center;
  @media (max-width: 839px) {
    width: 100%;
    margin-top: 2em;
  }
}

.prescription-btn {
  background-color: $white;
  color: $dark-green;
  font-weight: bold;
  border: 1px solid $gray-200;
  padding: 1em;
  &:hover {
    background-color: $white;
    color: $dark-green;
    border: 1px solid $gray-200;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}

.f-icon {
  &:hover {
    background: none !important;
  }
}
.tooltip-inner {
  background-color: $white !important;
  border: solid 1px gray;
}
.tooltip .arrow {
  background-color: $white !important;
}
.icon-container {
  padding: 0 5px !important;
}

.bs-tooltip-right .arrow::before {
  background-color: $white !important;
  border-right-color: $white !important;
}

.tooltip1 {
  display: inline-block;
  position: relative !important;
  // border-bottom:1px dotted #666;
  text-align: left;
  /*i used these margins to position tooltip around the conatainer so dont include them*/
  //  margin-top:150px;
  //   margin-left:100px;
}
/*right tolltip*/

.tooltip1 .tooltip1-right {
  min-width: 240px;
  top: 50%;
  left: 110%;
  margin-left: 8px;
  transform: translate(0, -50%);
  padding: 10px 30px;
  color: $white;
  background-color: $text-color;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0px 2px 4px rgba(33, 36, 41, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;
}


.tooltip1:hover .tooltip1-right {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip1-right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip1 .tooltip1-right i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: $text-color;
  box-shadow: 0px 2px 4px rgba(33, 36, 41, 0.05);
}
.tooltip-basic .tooltip-basic-right {
  top: 450% !important; 
  left: 110% !important;
}

.tooltip-basic .tooltip-basic-right i {
  top: 15.5% !important;
}
.tooltip-plus .tooltip-plus-right {
  top: 280% !important; 
  background-color: #00C7B1 !important;
  left: 150% !important;
}

.tooltip-plus .tooltip-plus-right i {
  top: 30% !important;
}
.tooltip-plus .tooltip-plus-right i::after {
  background-color: #00C7B1 !important;
}
.tooltip-pro .tooltip-pro-right {
  top: 120% !important; 
  background-color: #00776A !important;
  left: 160% !important;
}
.tooltip-pro .tooltip-pro-right i::after {
  background-color: #00776A !important;
}
.tooltip-pro .tooltip-pro-right i {
  top: 44.5% !important;
}


/*end right*/
/*start bottom*/
.tooltip1 .tooltip1-left {
  min-width: 500px;
  top: 50%;
  left: 0%;
  bottom: 100%;
  margin-left: 0;
  transform: translate(0, -50%);
  padding: 10px 30px;
  color: $white;
  background-color: $text-color;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0px 2px 4px rgba(33, 36, 41, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;
}
.tooltip1 .tooltip-bottom {
  min-width: 275px;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  color: $white;
  background-color: $text-color;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;

  @media (max-width: 839px) {
    min-width: 200px;
  }
}
.tooltip1 .tooltip1-left {
  min-width: 500px;
  top: 50%;
  left: 0%;
  bottom: 100%;
  margin-left: 0;
  transform: translate(0, -50%);
  padding: 10px 30px;
  color: $white;
  background-color: $text-color;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0px 2px 4px rgba(33, 36, 41, 0.05);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;
}

.tooltip1:hover .tooltip-bottom {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip1 .tooltip-bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: $text-color;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
.tooltip-title {
  color: $dark-green;
  font-size: 18px;
  line-height: 25px;
}
.tooltip-body {
  // padding-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: $white;
}
.custom-tooltip-body {
  // padding-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: $white; 
  @media (max-width: 839px) {
    overflow: auto; 
    height: 400px;
    @include scrollbars(5px, #44e2d0, #00a78c ,rgba(100, 100, 100, 0.2));
  }
}

.modal-title {
  color: $dark-green;
}
.active-btn {
  background-color: $dark-green;
  color: $white;
  font-weight: 500;
  border: 1px solid $dark-green;
  padding: 10px 16px;
  &:hover {
    background-color: $dark-green;
    color: $white;
    border: 1px solid $dark-green;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}
.inactive-btn {
  background-color: $white;
  color: $dark-green;
  font-weight: 500;
  border: 1px solid $gray-200;
  padding: 10px 16px;

  &:hover {
    background-color: $white;
    color: $dark-green;
    border: 1px solid $gray-200;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}
.braces-type-grid {
  display: grid;
  grid-template-columns: 35% 35%;
  grid-gap: 1em;
  align-items: center;
  justify-content: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}

.aligner-braces-grid {
  display: grid;
  grid-template-columns: 80px 122px 122px;
  grid-gap: 1em;
  text-align: right;
  align-items: center;
  justify-content: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
.aligners-grid {
  display: grid;
  grid-template-columns: 10% 15% 15% 15%;
  grid-gap: 1em;
  text-align: right;
  align-items: center;
  justify-content: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

.modal-top-20.modal-dialog {
  //  .modal-dialog{
  top: 20% !important;
  //}
  max-width: 100% !important;
  width: 50%;
  @media (max-width: 839px) {
    top: 10% !important;

    width: 95%;
    margin: 0 auto;
  }
}
.treatment-type-img {
  width: 100px;
  height: 100px;
  align-self: center;
}
.treatment-type-title {
  color: $dark-green;
}
.upload_impressions_grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 16px;
  text-align: center;
  align-items: center;
  justify-content: start;
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
    text-align: center;
    // grid-row-gap: 16px;
    margin-bottom: 24px;
  }
}

.upload-impression-container {
  padding: 20px;
  color: $dark-green;
}

.upload-impression-img {
  height: auto;
  cursor: pointer;
  border-radius: 8px;
}

.main_advanced_module_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8em;
  // text-align: center;
  // align-items: center;
  justify-content: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
  }
}
.advanced_module_grid {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(6, minmax(auto, 120px));
  grid-gap: 1em;
  text-align: center;
  align-items: center;

  @media (max-width: 1330px) {
    grid-template-columns: repeat(4, minmax(auto, 120px));
  }
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }
}

.active_advanced_module_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-width: 1330px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }
}
.modules-img {
  height: auto;
  width: auto;
  @media (max-width: 839px) {
    height: 35px;
    width: 35px;
  }
}
.advanced_module-card {
  height: 120px;
  margin: auto 0;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  @media (max-width: 839px) {
    height: auto;
    font-size: 16px;
    padding: 1em !important;
  }
}
.active_advanced_module_card {
  height: 120px;
  margin: auto 0;
  justify-content: center;
  font-size: 12px;
  position: relative;
  background-color: $dark-green;
  color: $white;
  @media (max-width: 839px) {
    height: auto;
    font-size: 16px;
    padding: 1em !important;
  }
}

.active_module_title {
  font-weight: 500;
  color: $white;
}
.module_title {
  font-weight: 500;
  color: $dark-green;
}

.close-active-module {
  // position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: white;
  font-size: 24px;
  line-height: 20px;
  font-weight: 500;
  vertical-align: middle;
  @media (max-width: 839px) {
    top: 8px;
    right: 8px;
    font-size: 35px;
    line-height: 25px;
  }
}

.main_modules_title {
  font-size: 20px;
  color: $dark-green;
  font-weight: 500;
  @media (max-width: 839px) {
    font-size: 18px;
  }
}
.case-submission-title {
  color: $dark-green;
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 839px) {
    font-size: 18px;
    text-align: center;
  }
}

.steps-title {
  display: grid;
  grid-template-columns: 1fr auto;
  // text-align: center;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  padding: 1em;
  align-items: center;
  text-align: end;
  justify-content: center;
}

.address_discrepancy {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  max-height: 50%;
  // grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-rows: 1fr;
  }
}
.passive_aligners_grid {
  display: grid;
  grid-template-rows: 3fr 3fr 2fr;
  // grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-rows: 1fr;
  }
}

.discrepancy-btn {
  background-color: $white;
  color: $dark-green;
  padding: 10px 12px;
  border: none;
  &:hover {
    background-color: $white;
    color: $dark-green;
    padding: 10px 12px;
    border: none;
  }
}

.active_discrepancy-btn {
  background-color: $dark-green;
  color: $white;
  padding: 10px 12px;
  border: none;
  &:hover {
    background-color: $dark-green;
    color: $white;
    padding: 10px 12px;
    border: none;
  }
}

.discrepancy-btn2 {
  background-color: $white !important;
  color: white !important;
}

.active_discrepancy-btn2 {
  background-color: $light-green;
  color: $white;
}
.arch_expansion_grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  // grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-rows: 1fr;
  }
}

.h-8 {
  height: 80px;
}
.w-8 {
  width: 100px;
}

.malocclusion-main-grid {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-gap: 1em;
}
.malocclusion-btn-text {
  padding-left: 8px;
}
.malocclusion-btn-text2 {
  padding-left: 8px;
  margin-top: 20px;
}
.malocclusion-btn {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 1em;

  @media (max-width: 839px) {
    grid-template-rows: 0fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }
}

.malocclusion-modal {
  // top: 10%;
  // height: 35%;
  width: 65%;
  max-width: 65%;
  @media (max-width: 839px) {
    // height: 60%;
    // top: 5%;
    width: 100%;
    max-width: 100%;
  }
}
.modalx6 .modal-dialog {
  width: 80%;
  max-width: 80%;
  @media (max-width: 839px) {
    // height: 80%;
    width: 95%;
    max-width: 95%;
  }
}

.summary_sub_title {
  font-size: 18px;
  color: $dark-green;
  line-height: 20px;
  font-weight: 500;
}
.malocclusion-title {
  font-size: 18px;
  color: $dark-green;
  padding: 0px 0 10px 0;
  border-bottom: 1px solid $dark-green;
  line-height: 20px;
  font-weight: 500;
  .close-btn {
    font-size: 30px;
    line-height: 24px;
    cursor: pointer;
  }
}
.cross-bite-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}
.checkbox-hint {
  font-size: 12px;
}

.midline_grid {
  display: grid;
  grid-template-columns: auto auto 1fr;
  padding-left: 8px;
  grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns:  1fr;

    // row-gap: 1em;
    padding-left: 0px;
    grid-gap: 0em;
  }
}
.midline-section-grid {
  display: grid;
  grid-template-rows: 0.1fr auto;

  // border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  @media (max-width: 839px) {
    grid-template-rows: 1fr;
    row-gap: 1em;
  }
}
.displacement {
  display: grid;
  grid-template-columns: auto 70px;
  grid-gap: 1em;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}
.displacement-Shifted {
  display: grid;
  grid-template-columns: auto 70px;
  grid-gap: 1em;
  max-width: fit-content;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}

.disabled-teeth {
  filter: grayscale(100%);
  pointer-events: none;
  font-weight: 500;
}

.setting-icon {
  position: relative;
  top: calc(50% - 10px);
  color: $dark-green;
  font-size: 20px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
.topbar .top-navbar .navbar-nav > .nav-item:hover {
  background: linear-gradient(
      360deg,
      #c8c8ca -6.25%,
      rgba(255, 255, 255, 0) 13.28%
    ),
    #f5f5f5 !important;
}

.main-page-grid {
  display: grid;
  grid-template-columns: 2fr 4fr;
  grid-gap: 1em;
  // align-items: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}
.controles-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  row-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
    grid-row: 2;
  }
}
.main-filters-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  row-gap: 1em;
  padding-bottom: 100px;
  align-items: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}

// .summery {
//   .form-control:disabled,
//   .form-control[readonly] {
//     //background-color: $white !important;
//   }
// }
.active-icon {
  font-size: 18px;
  @media (max-width: 839px) {
    font-size: 14px;
  }
}

.patient_info {
  position: absolute;
  right: 0;
  top: -30px;
  font-size: 14px;
  // float: right;
  text-align: end;
  display: grid;
  grid-template-columns: 1fr;
  color: $light-green;
  font-weight: 600;
  // transform: translateY(-60px);
  @media (max-width: 839px) {
    text-align: end;
    // grid-template-columns: 1fr 1fr;
  }
}
// .patient_info {
//   font-size: 14px;
//   // float: right;
//   text-align: end;
//   display: grid;
//   grid-template-columns: 1fr;
//   color: $light-green;
//   font-weight: 600;
//   transform: translateY(-60px);
//   @media (max-width: 839px) {
//     text-align: end;
//     // grid-template-columns: 1fr 1fr;
//   }
// }
.main-filter-header {
  font-size: 32px;
  color: $dark-green;
  // font-weight: 500;
  @media (max-width: 839px) {
    font-size: 18px;
    text-align: center;
  }
}
.main-filters-title {
  font-size: 16px;
  color: $dark-green;
  font-weight: 500;
}

.fa-archive {
  color: $dark-green;
}
.fa-undo {
  color: $dark-green;
}
.actions-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1em;
  align-items: center;
  text-align: end;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.actions-btn {
  width: 100%;
}
.w-action {
  width: 160px;
}
.status-number {
  font-size: 35px;
  font-weight: 500;
}
.patient-info-grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 1em;
  font-size: 14px;
  color: $dark-gray;
  margin-top: 20px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
    text-align: start;
    padding: 0 1em;
  }
}
.ReactTable .rt-tr {
  align-items: center !important;
}

.teeth_selector {
  // max-width: 760px;
  max-width: fit-content;
  // margin: 0 auto;
}

.paragraph {
  color: #67757c;
  // font-size: 10px;
}
.l-title {
  font-size: 20px;
}
.custome-toggle {
  max-width: 300px;
  color: $dark-green !important;

  label {
    color: $dark-green !important;
  }
}

.ipr-title {
  font-size: 38px;
  line-height: 48px;
  color: $dark-green;
  font-weight: 500;
}

.ipr-title-active {
  font-size: 38px;
  line-height: 48px;
  color: $white;
  font-weight: 500;
}

.step-header-title {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
    text-align: start;
    padding: 0 1em;
  }
}

.message-icon {
  color: $dark-green;
}

.nav-grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: end;
  text-align: end;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    position: unset;
  }
}

.doctor-name-nav {
  position: absolute;
  right: 48px;
  top: 0%;
  @media (max-width: 839px) {
    position: unset;
  }
}

.topbar .top-navbar {
  min-height: 64px !important;
  background: linear-gradient(
      360deg,
      #c8c8ca -6.25%,
      rgba(255, 255, 255, 0) 13.28%
    ),
    #f5f5f5 !important;
}
.custom-date-picker {
  input {
    z-index: 99;
    width: 100%;
    height: 38px;
    padding: 2px 8px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }
}
.react-datepicker-wrapper {
  width: 100%;
}
.prescription_new_footer_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.c-w {
  width: 250px;
  @media (max-width: 839px) {
    width: 100%;
  }
}
.not-active-img {
  color: $text-color;
}
.dso_logo {
  position: absolute;
  height: 60%;
  left: 69%;
  width: 32%;
  bottom: 0;
  @media (max-width: 839px) {
    height: auto;
    width: 200px;
    bottom: 0;
    left: 45%;
  }
}
.main-page-con {
  position: relative;
  padding: 0 5em;
  @media (max-width: 839px) {
    padding: 0;
  }
}
.h-card {
  height: 100px;
}
.dso_logo2 {
  position: absolute;
  height: 28px;
  width: 110px;
  left: 88%;
  bottom: 0;
  @media (max-width: 839px) {
    height: 15px;
    width: 80px;
    bottom: 0;
    left: 80%;
  }
}
.banner_mobile {
  @media (max-width: 839px) {
    grid-row: 1;
  }
}
.submit-page-title-grid {
  display: grid;
  grid-template-columns: auto auto;
  @media (max-width: 839px) {
    grid-template-columns: 3fr auto;
  }
}
.close-case-submission {
  font-size: 25px;
  cursor: pointer;
  color: red;
  font-weight: 500;
}
.sidenav-rl {
  position: relative;
}
.gco-light {
  // position: absolute;
  height: auto;
  width: 100%;
  top: 45%;
  right: 0;
  @media (max-width: 839px) {
    // bottom: -70%;
  }
}
.gco-2-light {
  // position: absolute;
  // bottom: -60%;
  width: 40%;

  margin-top: 20px;
  @media (max-width: 839px) {
    // bottom: -80%;
  }
}
.logo-container {
  position: absolute;
  height: auto;
  width: 100%;
  top: 42%;
  right: 0;
  z-index: -99;
  @media (max-width: 839px) {
    top: 50%;
  }
}
.photos-xrays-img {
  img {
    height: 125px;
    aspect-ratio: 16/9;
    /* display: block; 
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #FFFFFF; */
    object-fit: contain;
    // width: 177px;
    @media (max-width: 839px) {
      height: auto;
    }
  }
}



.photos-xrays-img-fix-xray {
  img {
    height: 125px;
    aspect-ratio: 16/9;
    /* display: block; 
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #FFFFFF; */
    object-fit: contain;
    // width: 177px;
    @media (max-width: 839px) {
      height: 100px;
    }
  }
}








.fix-xrays-img {
  img {
    height: auto;
    // width: 177px;
    @media (max-width: 839px) {
      height: auto;
    }
  }
}
.fix-photos-img {
  img {
    height: 145px;
    // width: 177px;
    @media (max-width: 839px) {
      height: auto;
    }
  }
}

.chose_one {
  width: 620px;
  @media (max-width: 839px) {
    width: 100%;
  }
}

.arrows-icon {
  font-size: 20px;
  vertical-align: middle;
}
.arrows-icon-ts {
  color: #dbdbdb;
}
.submit-loader {
  width: 36px;
}
.submit-loader-retainer {
  width: 100px;
}
.h-100 {
  height: 100px;
  //width: 100px;
}
.w-100px {
  width: 100px;
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid $dark-green;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.loaderRetainer {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid $dark-green;
  width: 100px;
  height: 100px;

  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  @media (max-width: 839px) {
    width: 36px;
    height: 36px;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.coming-soon {
  position: absolute;
  top: 0%;
  left: -1%;
  width: 80px;
  // transform: rotate(-45deg); /* Equal to rotateZ(45deg) */
  @media (max-width: 839px) {
    top: 0%;
    width: 120px;
    left: 0;
  }
}

.cursor-none {
  cursor: unset;
}
.loaderRetainer-container {
  display: grid;
  padding-top: 50px;
  place-items: center;
}
.optional-input {
  color: $text-color;
  font-size: 12px;
}

.fa-exclamation-circle {
  font-size: 18px;
  vertical-align: middle;
}

.custom-tag {
  max-width: 300px;
  height: 350px;
  min-height: 252px;
  border-radius: 24px;
  background: $bg-gray !important;

  @media (max-width: 839px) {
    height: auto;
    min-height: auto;
  }
}
.img-carousel {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 24px;
}
#treatment_type {
  width: 180px;
  height: 170px;
  @media (max-width: 839px) {
    height: auto;
    width: auto;
  }
}
.pl-2 {
  font-size: 10px;
  color: red;
  @media (max-width: 839px) {
  }
}
.margin {
  margin-top: 16px;
  @media (max-width: 839px) {
    margin-top: 0px;
  }
}

.title_active-gco2 {
  color: $dark-green;
  font-weight: 500;
}

.patient_info_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-column-gap: 2em;
  // grid-row-gap: 0.5em;
  align-items: baseline;

  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 0.5em;
  }
}

.auto_grid_timing {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
}
.auto_grid_timing2 {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  grid-gap: 1em;
}

.grid_ipr {
  display: grid;
  grid-column-gap: 3px;
  grid-template-columns: repeat(16, minmax(auto, 1fr));
  // align-items: center;
  text-align: center;
  font-size: 1em;
  img {
    width: 100%;
  }
  @media (max-width: 900px) {
    font-size: 0.75em;
  }
  @media (max-width: 839px) {
    grid-template-columns: repeat(16, minmax(6px, 25px));
    grid-column-gap: 0;
  }
}

.passive-aligner-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  align-items: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
}
.do-not-perform-ipr {
  color: red;
  font-weight: bold;
  font-size: 100%;
}
.do-not-perform-ipr_upper_small {
  cursor: pointer;
  color: red;
  font-weight: bold;
  font-size: 100%;
  position: absolute;
  bottom: 2%;
  margin-left: 35%;
  font-family: sans-serif;

  @media (max-width: 1125px) {
    bottom: 1%;
  }
  @media (max-width: 1030px) {
    bottom: 0;
  }
}
.do-not-perform-ipr_upper_big {
  cursor: pointer;
  color: red;
  font-weight: bold;
  font-size: 100%;
  position: absolute;
  bottom: 10%;
  margin-left: 30%;
  font-family: sans-serif;

  @media (max-width: 1125px) {
    bottom: 5%;
    margin-left: 25%;
  }
}
.do-not-perform-ipr_lower_big {
  cursor: pointer;
  color: red;
  font-weight: bold;
  font-size: 100%;
  position: absolute;
  top: 10%;
  margin-left: 30%;
  font-family: sans-serif;

  @media (max-width: 1125px) {
    top: 5%;
    margin-left: 25%;
  }
}
.do-not-perform-ipr_lower_small {
  cursor: pointer;
  color: red;
  font-weight: bold;
  font-size: 100%;
  position: absolute;
  top: 2%;
  margin-left: 35%;
  font-family: sans-serif;
  @media (max-width: 1125px) {
    top: 1%;
  }
  @media (max-width: 1030px) {
    top: 0;
  }
}
.v-line-upper {
  height: 55px;
  // width: 3px;
  border-right: 2px solid lightgray;
  margin-right: -2px;
  margin-bottom: 5px;
}
.v-line-lower {
  height: 55px;
  // width: 3px;
  border-right: 2px solid lightgray;
  margin-right: -2px;
  margin-top: 5px;
}
.v-line-upper2 {
  height: 100px;
  // width: 3px;
  border-right: 2px dashed lightgray;
  margin-right: -2px;
  margin-bottom: 5px;
}
.v-line-lower2 {
  height: 100px;
  // width: 3px;
  border-right: 2px dashed lightgray;
  margin-right: -2px;
  margin-top: 5px;
}
.v-line-upper3 {
  height: 100px;
  // width: 3px;
  border-right: 0.170915px solid #898a8d50;
  // transform: translateX(3px);
  // transform: translatey(30px);
  transform: translate(3px, 19px);
  margin-bottom: 5px;
  z-index: 1;
}
.v-line-lower3 {
  height: 100px;
  // width: 3px;
  border-right: 0.170915px solid #898a8d70;
  //margin-right: -2px;
  transform: translate(3px, -19px);
  margin-top: 5px;
  z-index: 1;
}
.ipr_teeth_upper {
  align-items: flex-end;
  display: flex;
  position: relative;
}
.ipr_teeth_lower {
  align-items: flex-start;
  display: flex;
  position: relative;
}
.ipr-amount {
  position: absolute;
  //top: -14px;
  // left: -9.2px;
  //transform: translateX(-6.5px);
  transform: translate(-6.8px, -100px);

  width: 20px;
  text-align: center;
}
.custom-teeth-selector-container-summary {
  .ipr-amount-center {
    position: absolute;
    top: 0;
    left: -2px;
    width: 20px;
    text-align: center;
    transform: translate(0.5px, -95px);
  }

  .ipr-amount {
    position: absolute;
    //top: -14px;
    // left: -9.2px;
    //transform: translateX(-6.5px);
    transform: translate(-6.8px, -95px);

    width: 20px;
    text-align: center;
  }

  .ipr-amount-lower-center {
    position: absolute;
    bottom: 0;
    left: -2px;
    width: 20px;
    text-align: center;
    z-index: 3;
    transform: translate(0.5px, 148px);
  }
}
.ipr-amount-empty {
  position: absolute;
  //top: -14px;
  // left: -9.2px;
  //transform: translateX(-6.5px);
  transform: translate(-6.8px, -100px);

  width: 20px;
  text-align: center;
}
.ipr-amount-center {
  position: absolute;
  top: 0;
  left: -2px;
  width: 20px;
  text-align: center;
  transform: translate(0.5px, -100px);
}
.ipr-amount-center-empty {
  position: absolute;
  top: 0;
  left: -2px;
  width: 20px;
  text-align: center;
  transform: translate(0.5px, -100px);
}
.ipr-amount-lower {
  position: absolute;
  // bottom: -14px;
  // left: -9px;
  transform: translate(-6.8px, -5px);
  width: 20px;
  text-align: center;
  z-index: 3;
}
.ipr-amount-lower-empty {
  position: absolute;
  // bottom: -14px;
  // left: -9px;
  transform: translate(-6.8px, -25px);
  width: 20px;
  text-align: center;
  z-index: 3;
}

.ipr-amount-lower-center {
  position: absolute;
  bottom: 0;
  left: -2px;
  width: 20px;
  text-align: center;
  z-index: 3;
  transform: translate(0.5px, 170px);
}
.ipr-amount-lower-center-empty {
  position: absolute;
  bottom: 0;
  left: -2px;
  width: 20px;
  text-align: center;
  z-index: 3;
  transform: translate(0.5px, 102px);
}
.ipr-amount2 {
  position: absolute;
  top: -60px;
  left: -7.5px;
  width: 20px;
  text-align: center;
}
.ipr-amount-lower2 {
  position: absolute;
  bottom: -60px;
  left: -7.5px;
  width: 20px;
  text-align: center;
}
.num-container {
  position: relative;

  .form-material .form-control,
  .form-material .form-control.focus,
  .form-material .form-control:focus {
    background-image: none;
    //border: 1px solid $light-green;
    font-size: 10px !important;
  }
}
.num-container2 {
  position: relative;

  .form-material .form-control,
  .form-material .form-control.focus,
  .form-material .form-control:focus {
    background-image: none;
    border: 1px solid $light-green;
    font-size: 10px !important;
  }
}
.mb-6 {
  margin-bottom: 4rem !important;
}
.mt-6 {
  margin-top: 4rem !important;
}
.example-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 1em;
  .form-material .form-control,
  .form-material .form-control.focus,
  .form-material .form-control:focus {
    background-image: none;
    border: 1px solid $light-green;
    font-size: 10px !important;
    width: 30px;
  }
}
.example-text {
  height: 30px;
  text-align: end;
}
.bb {
  border-bottom: 1px solid lightgray;
  height: 15px;
}
.example {
  padding: 1em;
  border: 1px solid lightgray;
  margin: 0 8em;
  @media (max-width: 1125px) {
    margin: 0 6em;
  }
  @media (max-width: 1030px) {
    margin: 0 4em;
  }
  @media (max-width: 839px) {
    margin: 0 1em;
  }
}
.custom-number-ipr::-webkit-outer-spin-button,
.custom-number-ipr::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
  background-image: none !important;
}
.custom-number-ipr[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
  &:focus {
    background-image: none !important; //linear-gradient($light-green, $light-green), linear-gradient(#dee2e6, #dee2e6) !important;
  }
}
.triangle {
  cursor: pointer;
  width: 0;
  height: 0;
  left: -4px;

  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 25px solid lightgray;
  position: relative;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.triangle:after {
  content: "";
  position: absolute;
  z-index: auto;
  top: 4px;
  left: -13px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 19px solid white;
}
.triangle_lower {
  width: 0;
  height: 0;
  left: -4px;

  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 25px solid lightgray;
  position: relative;
  cursor: pointer;
}
.triangle_lower:after {
  content: "";
  position: absolute;
  top: 4px;
  left: -13px;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 19px solid white;
}
.tri-num {
  cursor: pointer;
  position: absolute;
  font-size: 12px;
  color: $dark-green;
  z-index: 99;
  right: -4px;
  top: 8px;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.tri-num_big {
  cursor: pointer;
  position: absolute;
  font-size: 12px;
  color: $dark-green;
  z-index: 99;
  right: -6px;
  top: 8px;
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.tri-num_lower {
  cursor: pointer;
  position: absolute;
  font-size: 12px;
  color: $dark-green;
  z-index: 99;
  right: -4px;
  top: 8px;
}
.tri-num_lower_big {
  cursor: pointer;
  position: absolute;
  font-size: 12px;
  color: $dark-green;
  z-index: 99;
  right: -6px;
  top: 8px;
}

.ipr-detailes-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
// .actions-grid {
//   display: grid;
//   grid-template-columns: 1fr;
//   align-items: center;
//   justify-content: center;
//   grid-row-gap: 1em;
// }
.actions-grid2 {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
}
.actions-grid3 {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;

  @media (max-width: 839px) {
    grid-template-columns: auto 1fr 1fr;
  }
}

.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-arrows[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
  font-size: 12px;
  height: auto !important;
  width: 100%;
  background-image: none !important;
  &:focus {
    background-image: none !important; //linear-gradient($light-green, $light-green), linear-gradient(#dee2e6, #dee2e6) !important;
  }
}
.number-ipr-detailes {
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid lightgray;
  padding: 0 0.5em;
  align-items: center;
  width: 65px;

  margin: 0 5px;
}

.movement-num-detailes {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 2px;
  align-items: center;
  width: 80px;
}
.auto_grid6 {
  display: grid;
  grid-template-columns: 1fr;
}
.num-angle {
  padding: 0;
  font-size: 12px;
  color: $dark-green;
  cursor: pointer;
}
.disable-num-angle {
  padding: 0;
  font-size: 12px;
  color: lightgray;
}
.movement_tables_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1em;
  //align-items: center;
  padding: 1em;
}
.movement-modules-grid {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-column-gap: 1em;
  align-items: center;
  padding: 1em;
  padding-bottom: 0;
}
.movement-note-modules-grid {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 1em;
  align-items: center;
  padding: 1em;
  padding-top: 0;
}
.use_ipr {
  .v-line-upper {
    border-right: 2px solid $light-green !important;
  }
  .v-line-lower {
    border-right: 2px solid $light-green !important;
  }
  .triangle {
    border-bottom: 25px solid $light-green;
  }
  .triangle_lower {
    border-bottom: 25px solid $light-green;
  }
}
.updated_ipr {
  .v-line-upper {
    border-right: 2px solid $light-green !important;
  }
  .v-line-lower {
    border-right: 2px solid $light-green !important;
  }
  .triangle {
    border-bottom: 25px solid $light-green;
  }
  .triangle_lower {
    border-bottom: 25px solid $light-green;
  }
  .triangle:after {
    border-bottom: 19px solid #ebf5f3;
  }
  .triangle_lower:after {
    border-bottom: 19px solid #ebf5f3;
  }
}

.movement-table {
  // padding: 0px 32px 0px 32px;
  overflow-x: hidden;
  // height: 200px;
  overflow: auto;
  table {
    overflow-x: hidden;
  }
  thead {
    color: #2e2e2f;
    font-weight: bold;
    vertical-align: middle;
    text-align: center;

    border-top: none;
  }
  tbody{
    height: calc(100vh - 300px);
  }
  th {
    width: 10%;
    position: sticky;
    font-size: 12px;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }
  .table-striped tbody tr:nth-of-type(even) {
    background-color: #f5f5f5;
  }
  td {
    // border-right: 1px solid #00a78c;
    text-align: center;
    font-size: 12px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
  tbody {
    display: block;
    overflow-y: auto;
  }
  thead {
    position: sticky;
  }
}
.custom-teeth2 {
  max-width: 600px;
  margin: 0 auto;
}

.small-movement-table {
  .table-bordered {
  }
  .table thead th {
    color: $white;
  }
  td {
    color: #00a78c;
    padding: 0.5em;
    font-size: 12px;
  }
}

.ts-details-grid {
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  align-items: center;
  grid-column-gap: 2em;
  color: #00a78c;
  @media (max-width: 1120px) {
    grid-template-columns: 1fr 1fr;
  }
}
.patient-img-ts {
  img {
    width: 100%;
    max-width: 150px;
    max-height: 150px;
  }
}
.rows-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 0.5em;
  color: #00a78c;
}
.note-header-ts {
  font-weight: bold;
  font-family: sans-serif;
}

.active-advanced-module-ts {
  display: grid;
  padding-top: 30px;
  grid-template-columns: repeat(auto-fit, minmax(auto, 200px));
  grid-gap: 1em;
  text-align: center;
  align-items: center;
  // justify-content: center;

  @media (max-width: 1330px) {
    grid-template-columns: repeat(auto-fit, minmax(auto, 200px));
  }
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
  }
}
.not_active_advanced_module_card_ts {
  height: 100px;
  margin: auto 0;
  justify-content: center;
  font-size: 12px;
  position: relative;
  background-color: $white;
  color: $dark-green;
  @media (max-width: 839px) {
    height: auto;
    font-size: 16px;
    padding: 1em !important;
  }
}
.active_advanced_module_card_ts {
  color: $white !important;
  background-color: $dark-green !important;
}
.modules-img-ts {
  height: 40px;
  width: 40px;
}

.ipr-title-ts {
  font-size: 30px;
  line-height: 40px;
  color: $dark-green;
  font-weight: 500;
}

.ipr-title-active-ts {
  font-size: 30px;
  line-height: 40px;
  color: $white;
  font-weight: 500;
}

.modalx {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.arch_passive_num {
  display: grid;
  grid-template-columns: auto auto 50px;
  align-items: center;
  place-items: center;
  justify-content: left;
  grid-gap: 1em;
}
.readOnly-text {
  .form-control[readonly] {
    background-color: $white;
    overflow: auto;
  }
}

.modalx7 {
  top: 0%;
  width: 60%;
  max-width: 60%;
  @media (max-width: 839px) {
    top: 15%;
    width: 95%;
    max-width: 95%;
  }
}
.sub-title-tooth {
  font-size: 9px;
}

.active-step-tooth-options {
  color: $dark-green;
}
.options-example {
  width: 25px;
  height: auto;
}
.options-example-1 {
  width: 30px;
  height: auto;
}

.hash {
  position: absolute;
  color: $dark-green;
  top: -2px;
  z-index: 999;
  right: 11.3%;
}
.exit-options {
  color: rgb(5, 5, 189);
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
}
.actions-btn-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.bite-record-grid {
  display: grid;
  grid-template-columns: 1fr 20fr 1fr;
  place-items: center;
  background-color: lightgray;
  height: 100vh;
}
.bite-record-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  align-items: center;
}
.test-h {
  height: 40vh;
  max-height: 40vh;
  width: 40vh;
  border: 1px solid red;
  #container {
    height: 40vh !important;
    max-height: 40vh;
  }
}

.tableFixHead {
  max-height: 300px;
  overflow: auto;
  text-align: center;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    padding: 1em;
    white-space: nowrap;
  }
  td {
    white-space: nowrap;
  }
  th {
    background: $dark-green;
    color: $white;
  }
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
}
.tableFixHead tbody th {
  position: sticky;
  left: 0;
}

/* Just common table stuff. Really. */

.changed-value {
  color: $red;
  font-weight: bold;
  font-family: sans-serif;
}
.h-m-3 {
  height: 35px;
}
.see_more_less {
  text-decoration: underline;
  font-weight: bold;
  font-family: sans-serif;
  color: $dark-green;
  cursor: pointer;
}

.splitter2 {
  background-color: "#939393";
  position: relative;
  top: 25%;
}
.splittercont {
  background-color: "#ccdff2";
  text-align: "center";
  width: "15px";
}
.close-steps {
  position: absolute;
  top: 8px;
  right: 3%;
  z-index: 9999;
}

.status_button_dot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

.radio-container {
  .radio-item {
    display: grid;
  }
}
.grid_container-total {
  .grid_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0em;
  }
}

.blocked_doc_note {
  width: 100%;
  background: #ffcd5d;
  height: 50px;
  color: #515151;
  font-family: Poppins;
  transform: translateY(-5px);
  @media (max-width: 839px) {
    display: none;
    height: auto;
    padding: 12px;
  }
}
.blocked-doc-note-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 12px;
  width: fit-content;
  margin: 0 auto;
  height: 100%;
  align-items: center;
}
.blocked-container {
  .dashboard-container {
    padding: 45px 120px;
    @media (max-width: 839px) {
      padding: 40px 20px;
    }
  }
}
#main-wrapper[data-layout="vertical"][data-header-position="fixed"]
  .blocked-container
  .page-wrapper {
  padding-top: 100px !important;
  @media (max-width: 839px) {
    padding-top: 0px !important;
  }
}
.bold_l_note {
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins;
  padding-right: 4px;
}
.normal_s_note {
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins;
}
.bold_s_note {
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins;
  padding: 0 4px;
  text-decoration: underline;
  cursor: pointer;
  a {
    color: #515151;
  }
}
.info_note {
  @media (max-width: 839px) {
    align-self: flex-start;
  }
}

.optional-input-none {
  font-size: 12px;
  color: white;
}

.full_width_in_button{
  @media (max-width: 839px) {
    width:100%;
    margin-bottom: 12px;
  }}
  .Prescription-bite-record{
  .Prescription-bite-record-inner-cont{
    @media (max-width: 839px) {
    overflow: auto;
    height:  calc(100vh - 200px);
    }
  }}
  .margin-in-address{
    margin-left:8px;
    @media (max-width: 839px) {
      margin-left: 0px;
    }
  }

  .full_width_in_button_inpopup{
    @media (max-width: 839px) {
      width:100%;
    
    }}

    //// gray color for input feild
    .css-dvua67-singleValue{
      color: #525f7f !important;
    } 
.address-cont
   span {
    color: #525f7f !important;
 }
.tooltip-close-mobile {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.toggle-tool-tip {
  @media (max-width: 839px) {
    .custom-tooltip-bottom {
      visibility: hidden !important;
      opacity: 0 !important;
    }
    .fa-question-circle {
      color: #898A8D !important;
    }
  }
}
.t_and_c {
  list-style-type: none;
  padding-left: 10px;
}

.container_loyalty_program{
  display: grid;
  place-items: center;
  @media (max-width: 839px) {
    display: unset;
    place-items: center;
  }
}


.loyalty-program-container{
  display: grid;
  place-items: center;

  grid-template-rows: 1fr 1fr 1fr ;
  grid-row-gap: 12px;
  margin-top: 20px;
  margin-bottom: 50px;
}
.loyalty-program-block{
  width: 666px;
height: 41px;
background: #F7F8FA;
border-radius: 8px;
display: grid;
grid-template-columns: 1fr 1fr ;
@media (max-width: 839px) {
  width: 100%;
  height: 50px;
}



}
.loyalty-program-block-title{
  place-content: start;
  text-align: start;
  color: black;
  padding:7px  35px ;
}
.loyalty-program-block-data{
  color: #00C7B1;
  padding:7px  35px ;


}
.border-botton{
border-bottom: 1px;
}

.Description-loyalty-container{
  padding:  25px 10px ;
}

.Description-loyalty-steps{
  padding:  10px 0px ;
}
.FAQ-step{
padding-top: 30px
}

.centerization{
text-align: center;
}

.borderBottom {
border-bottom: 1px solid #C8C8CA ; 
}
.tableContaianer{
  padding:20px 10px 50px 10px  ;
  .table td {
  padding: 1rem 0px !important;
  @media (max-width: 839px) {
    padding: .5rem 0px !important;
    border-top: none !important;
  }
}
}

.LoyaltyTableContaiane{
  margin-top: 40px;
  margin-bottom: 40px;
  background: #F7F8FA;
border-radius: 25px;
padding: 30px;
display: grid;
grid-template-rows: auto 1fr ;
}
.black{
  color: black;
}
.LoyaltyTablenote{
  padding: 0px 20px;
  @media (max-width: 839px) {
    padding: 0px 10px;
  }
  
}

.LoyaltyTableImage{
  width: 100%;
  height: 100%;
}

.Stepper__info_loyalty{
  .Stepper__info_loyaltty{
    top: -4px;
  }
}

.Stepper__info_loyalty_sec{
  .Stepper__info{
  top: -8px;
}}
.Stepper__info_loyalty{
  .step_indicator{
    content: "";
    width: 16px;
    height: 16px;
    background: #ffff;

  }
}
.centerHorizontal{
  @media (max-width: 839px) {
margin: auto;
width: 60%;
padding: 7px;
  }
}

