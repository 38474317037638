// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
.green-btn {
  border: none;
  color: white;
  background-color: $light-green;
  border-radius: 8px;
  @include hover {
    background-color: $light-green;
    color: white;
    text-decoration: none;
  }
  &.disabled,
  &:disabled {
    pointer-events: none;
    opacity: $btn-disabled-opacity;
    background-color: gray;
    @include box-shadow(none);
  }
}

.red-btn {
  border: none;
  color: white;
  background-color: $danger;
  border-radius: 8px;
}
.green-btn2 {
  display: inline-block;
  border: none;
  color: white;
  background-color: $light-green;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
}
.green-btn3 {
  display: inline-block;
  border: none;
  color: white;
  cursor: pointer;
  background-color: $light-green;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  border-radius: 0;
  border: 1px solid $light-green;
}
.green-btn-active1 {
  border: none;
  color: white;
  background-color: $light-green;
  border-radius: 8px;
  @include hover {
    background-color: $light-green;
    color: white;
    text-decoration: none;
  }
}
.green-btn-not-active1 {
  border: none;
  color: $light-green;
  background-color: $light-green2;
  border-radius: 8px;
  @include hover {
    background-color: $light-green;
    color: white;
    text-decoration: none;
  }
}
.green-btn-active {
  border: none;
  color: white;
  background-color: $light-green;
  border-radius: 8px;
  text-align: start;
  // @media (max-width: 839px) {
  //   text-align: center;
  // }
  font-weight: 500;
  @include hover {
    background-color: $light-green;
    color: white;
    text-decoration: none;
  }
}

.dark-green-btn-active {
  border: none;
  color: white;
  background-color: $dark-green;
  text-align: center;
  height: 40px;
  font-weight: 500;
  @include hover {
    background-color: $dark-green;
    color: white;
    text-decoration: none;
  }
}
// .white-btn {
//   border: none;
//   color: $light-green;
//   background-color: $white;
//   border-radius: 2px;
//   text-align: center;
//   font-size: 12px;
//   @media (max-width: 839px) {
//     font-size: 10px;
//   }
//   font-weight: 500;
//   @include hover {
//     color: $light-green;
//     background-color: $white;
//     text-decoration: none;
//   }
// }
.white-gray-btn {
  border: none;
  color: $gray-600;
  background-color: $white;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  @media (max-width: 839px) {
    font-size: 10px;
  }
  font-weight: 500;
  @include hover {
    color: $light-green;
    background-color: $white;
    text-decoration: none;
  }
}

.green-btn-not-active {
  border: none;
  color: $light-green;
  background-color: $light-green2;
  border-radius: 8px;
  text-align: start;
  border: thin solid $light-green;
  // @media (max-width: 839px) {
  //   text-align: center;
  // }
  font-weight: 500;

  @include hover {
    background-color: $light-green;
    color: white;
    text-decoration: none;
  }
}

.dark-green-btn-not-active {
  border: none;
  color: $dark-green;
  background-color: $white;
  text-align: start;
  //  border: thin solid $dark-green;
  // @media (max-width: 839px) {
  //   text-align: center;
  // }
  font-weight: 500;
  height: 40px;
  @include hover {
    background-color: $dark-green;
    color: white;
    text-decoration: none;
  }
}
.btn-haeder {
  font-size: 18px;
  @media (max-width: 839px) {
    font-size: 12px;
  }
}
.btn-description {
  font-size: 12px;
  @media (max-width: 839px) {
    font-size: 10px;
  }
}

.light-gray-btn {
  border: none;
  color: $gray-600;
  background-color: $gray-200;
  @include hover {
    .ts_viewer {
      color: $light-green;
    }
  }
}


