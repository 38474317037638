.radio-tailwind {
  display: inline-block;
  position: relative;
}

.radio-tailwind label {
  color: $label;
  &:disabled {
    cursor: unset;
  }
  @media (max-width: 839px) {
    font-size: 12px;
  }
}

.radio-tailwind label:before {
  content: " ";
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 3px solid $gray-border;
  background-color: #fff;
  &:disabled {
    cursor: unset;
  }
}

.radio-tailwind label:after {
  cursor: pointer;
  border-radius: 11px;

  width: 8px;
  height: 8px;
  position: absolute;
  content: " ";
  top: calc(50% - 2px);
  left: 6px;

  display: block;
  &:disabled {
    cursor: unset;
  }
}

.radio-tailwind input[type="radio"]:checked + label:before {
  border: 3px solid $light-green;
}

.radio-tailwind input[type="radio"]:checked + label:after {
  background: $light-green;
}

.radio-tailwind input[type="radio"]:checked + label:hover:after,
.radio-tailwind input[type="radio"] + label:hover:after {
  background: #75e5d9;
}
