@mixin modal-postion {
    @media (max-width: 839px) {
        top: 5%; 
    }
}

@mixin toster-postion {
    @media (max-width: 839px) {
        top: 5%
  
    }
}

@mixin toster-style {
    
        width: 95% !important;
        height: auto !important;
        border-radius: 6px !important;
        margin: 0 auto;
    
}

@mixin modal-style {
    width: 95%;
    height: auto;
    border-radius: 15px !important;
    margin: auto;
    overflow: hidden;
}