/* Component variables */

.Stepper {
  position: relative;
  display: table;
  width: 100%;
  z-index: 1;
  table-layout: fixed;
  border-collapse: collapse;
  cursor: pointer;

  
}

.Stepper_loyalty{
  position: relative;
  display: table;
  width:60%;
  z-index: 1;
  table-layout: fixed;
  border-collapse: collapse;
  cursor: unset;
  @media (max-width: 839px) {
    width: 100%;
  }
}

.Stepper__step {
  position: relative;
  display: table-cell;
  padding: 0.5rem  ;
  text-align: center;
  @media (max-width: 839) {
  padding: 0.5rem 0 ;
  }
}

.Stepper__indicator {
  position: relative;
  display: block;
  z-index: 2;

}

.Stepper__label {
  position: relative;
  display: block;
  margin: 0.5rem 0;
  color: $light-gray;
  z-index: 2;
  transform :none;
  width: 100% !important;
  @media (max-width: 839) {
    transform: translateY(-15px)!important;
    width: 25px !important;
    transform: translateY(-15px) !important;
  }

}


.label-cont-stepper .Stepper__label {
  position: relative;
  display: block;
  margin: 0.5rem 0;
  color: $light-gray;
  z-index: 2;
  width: 100% !important;
    transform: translateY(-15px) !important;
}

// .Stepper {
//    .Stepper__step:last-child:after {
// display: none;
// }
// //  div:last-child {
// // background: #000;
// //   }

// }


.Stepper__info_loyaltty{
  position: relative;
  display: inline-block;
  width: 28px !important;
  height: 28px !important; 
  border: 3px solid $light-gray;
  border-radius: 50%;
  background-color: $light-gray;
  font-size: 1.25rem;
  line-height: 30px;
  text-align: center;
  color: #fff;
  z-index: 2;
}



.Stepper__info {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid $light-gray;
  border-radius: 50%;
  background-color: $light-gray;
  font-size: 1.25rem;
  line-height: 18px;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.Stepper__panel {
  display: none;
}

.Stepper .Stepper__step:after {
  content: " ";
  position: absolute;
  left: 60%;
  top: 3rem;
  width: 80%;

  height: 3px;
  background-color: $light-gray;
  z-index: 1;
}



.stepper-Bite-record-photos-and-xray .Stepper .Stepper__step:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 3rem;
  // width: 80%;
  width: 100%;
  height: 3px;
  background-color: $light-gray;
  z-index: 1;
}
.Stepper .Stepper__step:last-child:after {
  display: none;
}
// .test_in:after {
//   display: none;
// }

.Stepper--inline .Stepper__indicator,
.Stepper--inline .Stepper__label {
  display: inline-block;
  vertical-align: inherit;
}
.Stepper--inline .Stepper__label {
  text-align: left;
  padding: 0 0.5rem;
  background: #fff;
}

.Stepper--inline.Stepper--bottom .Stepper__label {
  vertical-align: middle;
}
.Stepper--inline.Stepper--bottom .Stepper__step:after {
  top: auto;
  bottom: 1.75rem;
}

.Stepper--vertical .Stepper__step {
  display: block;
  text-align: left;
}

.Stepper--vertical .Stepper__label {
  padding-left: 1rem;
}

.Stepper--vertical .Stepper__indicator,
.Stepper--vertical .Stepper__label {
  display: table-cell;
}

.Stepper--vertical .Stepper__panel {
  margin-left: 3.5rem;
}

.Stepper--vertical .Stepper__step:after {
  content: " ";
  position: absolute;
  left: 1.75rem;
  top: 2.5rem;
  bottom: -0.5rem;
  width: 0;
  height: auto;
  border-left: 0.125rem solid #e3e8ec;
}

.Stepper .Stepper__step.is-complete .Stepper__info {
  border-color: #a6b6c3;
  background-color: #a6b6c3;
}
.Stepper .Stepper__step.is-complete .Stepper__label {
  color: #b4b4b4;
}

.Stepper .Stepper__step.is-active .Stepper__info {
  border-color: #627c90;
  background-color: #627c90;
}
.Stepper .Stepper__step.is-active .Stepper__label {
  color: #b4b4b4;
}
.Stepper .Stepper__step.done-stepper:after {
  background-color: $light-green !important;
}
.Stepper .Stepper__step.is-warning .Stepper__info {
  border-color: #f1c40f;
  background-color: #f1c40f;
}
.Stepper .Stepper__step.is-warning .Stepper__label {
  color: #f1c40f;
}

.Stepper .Stepper__step.is-error .Stepper__info {
  border-color: #e95a4b;
  background-color: #e95a4b;
}
.Stepper .Stepper__step.is-error .Stepper__label {
  color: #e95a4b;
}

.step_indicator {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $light-gray;
  margin: 2.5px auto;
  // line-height: 30px;
}
.current-step_stepper {
  .step_indicator {
    background: $white;
  }
  .Stepper .Stepper__step:after {
    background-color: $light-green;

  }



  .Stepper__info {
    border: 1px solid $light-green;
    background-color: $light-green;
  }
  .Stepper__label {
    color: $light-green;
  }
}
.done-step_stepper {
  .step_indicator {
    background: $light-green;
  }
  .Stepper__info {
    border: 1px solid $light-green;
    background-color: $light-green;
  }
  .Stepper__label {
    color: $black;
  }

  .Stepper__step:after {
    background-color: $light-green;
  }
}
.Stepper__edit {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  border: 2px solid #ffb100;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 8px;
  height: 24px;
}
.Stepper__edit_empty {
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 8px;
  color: white;
  height: 24px;
  @media (max-width: 839px) {
    padding: 2px   !important;
  }

}

.Stepper__edit_empty {
  @media (max-width: 839px) {
    padding: 2px  !important;
  }
}


