.labelSec {
  display: flex;
}
.labelSec .canvasLabel {
  font-size: 14px;
  font-family: poppins600;
  color: #393838;
}
.labelSec .info {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  padding: 0;
  background-color: transparent;
  border: none;
}
.canvasFooter {
  padding: 8px 24px 28px;
}
.canvasBtn {
  width: 100%;
  background-color: #00c7b1;
  border-radius: 24px;
  border: none;
}

.canvasBtn:hover{
  background-color: #00a592;
}

.canvasBtn:active {
  background-color: #00a592 !important;
}

.canvasBtn:disabled {
  background-color: #393838;
}
