.tp_viewer_container {
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s;
  height: 100vh;
  width: 100%;

  .bounty-tooltip-sprite {
    width: 175px !important;
    max-width: 175px !important;
    white-space: pre-line;
  }
  .bounty-lower-tooltip-sprite {
    width: 175px !important;
    max-width: 175px !important;
    white-space: pre-line;
  }
  .main-inbox-grid {
    width: 100%;
    margin-top: 30px;
    display: inline-block;
    height: inherit;
    .msg_content_container {
      height: calc(100vh - 350px);
    }
  }
  .share_img_content {
    grid-column-gap: 4px;
    right: 4%;
  }
  .share_link_content {
    right: -50px;
  }
  .heartbit {
    border: 5px solid #d90429 !important;
  }
  .point {
    background-color: #d90429 !important ;
  }
}
.tp_header {
  background-color: white;
  height: 60px;
  padding: 8px 12px;
  display: grid;
  //position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid $light-gray;
}
.tp-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  height: 100%;
  min-width: 50%;
  position: absolute;
  width: 100%;
}
.tp_details {
  position: relative;
  //top: 60px;
  height: calc(100vh - 60px);
  min-width: 50%;
  padding: 20px 16px;
}
.tp_viewer {
  // background-color: yellow;
  height: calc(100vh - 60px);
}
.svg_icon {
  path,
  circle {
    stroke: $label;
  }
}
.tp_header_grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tp_case_status {
  .status_button {
    width: fit-content;
    margin: auto;
    font-weight: 400 !important;
  }
}

.tp_actions {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}
.tp_actions_inner {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  .button-seme-bold-text {
    font-size: 16px !important;
    width: 130px !important;

    @media (max-width: 839px) {
      font-size: 14px !important;
      width: 106px !important;
    }
  }
}
.tp-details {
  color: $label;
  font-weight: 400;
  display: grid;
  grid-template-columns:1fr auto ;
  column-gap: 8px;
 
}
.upgraded-container{
  padding: 4px 10px;
  background: #EFF3FF;
  border-radius: 4px;
}
.upgraded-text{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 85%;
color: #3366FF;
}

.active_header_tab {
  border-bottom: 3px solid $dark-green;
}
.not_active_header_tab {
  border-bottom: 3px solid $white;
}
.model_ts {
  .modal-content {
    border: none !important;
  }
}

.menu-title {
  color: $label;
  @include title-3-bold;

  display: grid;
  grid-template-columns: 1fr auto;
}

.details_normal {
  @include subhead;
}

.details_bold {
  @include subhead-bold;
}
.tp_notes {
  @include footnote;
}
.approve_tp_note {
  background: rgba(255, 205, 93, 0.35);
  border-radius: 4px;
  padding: 16px;
}
.approve_tp_header {
  @include seme-bold-button;
  color: #936701;
  padding-bottom: 8px;
}

.approve_ts_container {
  margin-top: 24px;
  max-height: calc(100vh - 250px);
  overflow: auto;
}
.logo_grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button:focus {
  outline: none !important;
}

.rel-container {
  position: relative;
  .abselu-line {
    position: absolute;
    width: 100%;
    height: 3px;
    color: $light-green;
  }
}
