/* basic #898A8D */
/* plus #00C7B1 */
/* pro #00776A */

.recomendation-box{
    padding: 20px;
    width: 360px;
    border-radius: 10px;
    color: #fff;
    font-size: 'poppins600';
    font-weight: 600;
    text-align: center
}

.recomendation-box .heading{
    font-size: 12px;
    font-weight: 600;
}
.recomendation-box p{
    margin: 0px;
    font-size: 16px;
}

.recomendation-box img{
    margin: 10px 0px 15px 0px;
}
.recomendation-box ul{
    padding: 0px;
    margin: 15px 0px 0px;
}
.recomendation-box ul li{
    list-style-position: inside;
    font-weight: 500;
}

.recomendation-box.basic{
    background-color: #898A8D;
}
.recomendation-box.plus{
    background-color: #00C7B1;
}
.recomendation-box.pro{
    background-color: #00776A;
}