/* @tailwind base; */

.tw-ea-relative {
  position: relative !important;
}

.tw-ea-m-auto {
  margin: auto !important;
}

.tw-ea-mb-2 {
  margin-bottom: 0.5rem !important;
}

.tw-ea-ml-2 {
  margin-left: 0.5rem !important;
}

.tw-ea-mt-10 {
  margin-top: 2.5rem !important;
}

.tw-ea-mt-2 {
  margin-top: 0.5rem !important;
}

.tw-ea-mt-3 {
  margin-top: 0.75rem !important;
}

.tw-ea-mt-4 {
  margin-top: 1rem !important;
}

.tw-ea-mt-5 {
  margin-top: 1.25rem !important;
}

.tw-ea-mt-6 {
  margin-top: 1.5rem !important;
}

.tw-ea-mt-9 {
  margin-top: 2.25rem !important;
}

.tw-ea-inline {
  display: inline !important;
}

.tw-ea-flex {
  display: flex !important;
}

.tw-ea-grid {
  display: grid !important;
}

.tw-ea-hidden {
  display: none !important;
}

.tw-ea-max-h-\[calc\(100vh-250px\)\] {
  max-height: calc(100vh - 250px) !important;
}

.tw-ea-w-\[170px\] {
  width: 170px !important;
}

.tw-ea-w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.tw-ea-w-full {
  width: 100% !important;
}

.tw-ea-min-w-\[170px\] {
  min-width: 170px !important;
}

.tw-ea-min-w-\[231px\] {
  min-width: 231px !important;
}

.\!tw-ea-max-w-none {
  max-width: none !important;
}

.tw-ea-max-w-full {
  max-width: 100% !important;
}

.tw-ea-max-w-xs {
  max-width: 20rem !important;
}

.tw-ea-flex-1 {
  flex: 1 1 0% !important;
}

.tw-ea-flex-\[2\] {
  flex: 2 !important;
}

.tw-ea-transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-ea-cursor-pointer {
  cursor: pointer !important;
}

.tw-ea-grid-cols-\[1fr_0fr\] {
  grid-template-columns: 1fr 0fr !important;
}

.tw-ea-flex-row {
  flex-direction: row !important;
}

.tw-ea-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.tw-ea-flex-col {
  flex-direction: column !important;
}

.tw-ea-items-center {
  align-items: center !important;
}

.tw-ea-justify-between {
  justify-content: space-between !important;
}

.tw-ea-gap-10 {
  gap: 2.5rem !important;
}

.tw-ea-gap-2 {
  gap: 0.5rem !important;
}

.tw-ea-gap-3 {
  gap: 0.75rem !important;
}

.tw-ea-gap-4 {
  gap: 1rem !important;
}

.tw-ea-gap-5 {
  gap: 1.25rem !important;
}

.tw-ea-overflow-auto {
  overflow: auto !important;
}

.tw-ea-whitespace-nowrap {
  white-space: nowrap !important;
}

.tw-ea-border-0 {
  border-width: 0px !important;
}

.tw-ea-border-b-\[1px\] {
  border-bottom-width: 1px !important;
}

.tw-ea-border-solid {
  border-style: solid !important;
}

.tw-ea-border-none {
  border-style: none !important;
}

.tw-ea-border-b-\[\#C8C8CA\] {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(200 200 202 / var(--tw-border-opacity)) !important;
}

.tw-ea-bg-\[\#F3F3F3\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 243 243 / var(--tw-bg-opacity)) !important;
}

.tw-ea-bg-\[\#d5d5d5\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(213 213 213 / var(--tw-bg-opacity)) !important;
}

.tw-ea-bg-transparent {
  background-color: transparent !important;
}

.tw-ea-p-2 {
  padding: 0.5rem !important;
}

.tw-ea-p-3 {
  padding: 0.75rem !important;
}

.tw-ea-p-\[4px\] {
  padding: 4px !important;
}

.tw-ea-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.tw-ea-py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.tw-ea-pb-5 {
  padding-bottom: 1.25rem !important;
}

.tw-ea-pb-6 {
  padding-bottom: 1.5rem !important;
}

.tw-ea-text-\[10px\] {
  font-size: 10px !important;
}

.tw-ea-text-\[12px\] {
  font-size: 12px !important;
}

.tw-ea-text-\[16px\] {
  font-size: 16px !important;
}

.tw-ea-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.tw-ea-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.tw-ea-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.tw-ea-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.tw-ea-font-bold {
  font-weight: 700 !important;
}

.tw-ea-font-medium {
  font-weight: 500 !important;
}

.tw-ea-font-normal {
  font-weight: 400 !important;
}

.tw-ea-font-semibold {
  font-weight: 600 !important;
}

.tw-ea-leading-5 {
  line-height: 1.25rem !important;
}

.tw-ea-text-\[\#2E2E2F\] {
  --tw-text-opacity: 1 !important;
  color: rgb(46 46 47 / var(--tw-text-opacity)) !important;
}

.tw-ea-text-\[\#898A8D\] {
  --tw-text-opacity: 1 !important;
  color: rgb(137 138 141 / var(--tw-text-opacity)) !important;
}

.tw-ea-text-\[\#FC4A69\] {
  --tw-text-opacity: 1 !important;
  color: rgb(252 74 105 / var(--tw-text-opacity)) !important;
}

.tw-ea-text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.tw-ea-shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.focus-within\:tw-ea-bg-white:focus-within {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.hover\:tw-ea-border-none:hover {
  border-style: none !important;
}

@media (min-width: 768px) {
  .md\:tw-ea-grid-cols-\[2fr_1fr\] {
    grid-template-columns: 2fr 1fr !important;
  }

  .md\:tw-ea-flex-row {
    flex-direction: row !important;
  }

  .md\:tw-ea-flex-col {
    flex-direction: column !important;
  }

  .md\:tw-ea-gap-20 {
    gap: 5rem !important;
  }
}

@media (min-width: 1024px) {
  .lg\:tw-ea-flex-row {
    flex-direction: row !important;
  }

  .lg\:tw-ea-gap-\[92px\] {
    gap: 92px !important;
  }
}

.\[\&\:focus-within\>div\>\*\>div\]\:tw-ea-bg-\[\#F3F3F3\]:focus-within>div>*>div {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 243 243 / var(--tw-bg-opacity)) !important;
}

.\[\&\>div\>\*\>div\]\:tw-ea-relative>div>*>div {
  position: relative !important;
}

.\[\&\>div\>\*\>div\]\:\!tw-ea-w-\[32px\]>div>*>div {
  width: 32px !important;
}

.\[\&\>div\>\*\>div\]\:tw-ea-cursor-default>div>*>div {
  cursor: default !important;
}

.\[\&\>div\>\*\>div\]\:\!tw-ea-rounded-\[4px\]>div>*>div {
  border-radius: 4px !important;
}

.\[\&\>div\>\*\>div\]\:\!tw-ea-border-0>div>*>div {
  border-width: 0px !important;
}

.\[\&\>div\>\*\>div\]\:\!tw-ea-p-0>div>*>div {
  padding: 0px !important;
}

.\[\&\>div\>\*\>div\]\:\!tw-ea-text-center>div>*>div {
  text-align: center !important;
}

.\[\&\>div\>\*\>div\]\:\!tw-ea-leading-\[30px\]>div>*>div {
  line-height: 30px !important;
}

.\[\&\>div\>\*\]\:\!tw-ea-px-\[4px\]>div>* {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.\[\&\>div\>\*\]\:\!tw-ea-py-1>div>* {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.\[\&_\*\]\:tw-ea-bg-\[\#d5d5d5\] * {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(213 213 213 / var(--tw-bg-opacity)) !important;
}

.\[\&_\.form-control\:focus\]\:\!tw-ea-bg-white .form-control:focus {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.\[\&_input\]\:tw-ea-pointer-events-none input {
  pointer-events: none !important;
}

.\[\&_input\]\:tw-ea-cursor-not-allowed input {
  cursor: not-allowed !important;
}

.\[\&_svg\:hover\]\:\!tw-ea-scale-\[0\.8\] svg:hover {
  --tw-scale-x: 0.8 !important;
  --tw-scale-y: 0.8 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.\[\&_svg\>path\:first-child\]\:\!tw-ea-stroke-\[\#ff0000\] svg>path:first-child {
  stroke: #ff0000 !important;
}

.\[\&_svg\>path\:first-child\]\:tw-ea-stroke-\[0\.5px\] svg>path:first-child {
  stroke-width: 0.5px !important;
}

.\[\&_svg\]\:tw-ea-absolute svg {
  position: absolute !important;
}

.\[\&_svg\]\:tw-ea-left-\[11px\] svg {
  left: 11px !important;
}

.\[\&_svg\]\:tw-ea-top-\[-9px\] svg {
  top: -9px !important;
}

.\[\&_svg\]\:\!tw-ea-scale-\[0\.6\] svg {
  --tw-scale-x: 0.6 !important;
  --tw-scale-y: 0.6 !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.\[\&_svg\]\:tw-ea-fill-\[\#ff0000\] svg {
  fill: #ff0000 !important;
}
