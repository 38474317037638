.light-green-btn {
    border: 2px solid $light-green;
    color: white;
    background-color: $light-green;
    border-radius: 20px;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
   // grid-column: 2;
    min-width: 100px;
    // padding: 8px 32px;
    @include hover {
        border: 2px solid #75e5d9;
        color: white;
        background-color: #75e5d9;
        border-radius: 20px;
        font-size: 16px;
        line-height: 20px;
        box-shadow: none;
        // padding: 8px 32px;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        border: 2px solid $light-gray !important;
        background-color: $light-gray;
        @include box-shadow(none);
    }

    &:active {
        background: #00776a !important;
        border: 2px solid #00776a !important;
        color: $white !important;
        box-shadow: none !important;
    }
}

.light-green-btn-ts {
    border: 2px solid $light-green;
    color: white;
    background-color: $light-green;
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    box-shadow: none;
    min-width: 120px;
    padding: .5rem 1rem!important;
    @include hover {
        border: 2px solid #75e5d9;
        color: white;
        background-color: #75e5d9;
        border-radius: 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        box-shadow: none;
        // padding: 8px 32px;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        border: 2px solid $light-gray !important;
        background-color: $light-gray;
        @include box-shadow(none);
    }

    &:active {
        background: #00776a !important;
        border: 2px solid #00776a !important;
        color: $white !important;
        box-shadow: none !important;
    }
}
.light-gray-btn {
    border: none;
    color: $gray-600;
    background-color: $gray-200;
    @include hover {
        .ts_viewer {
            color: $light-green;
        }
    }
}

.white-btn {
    border: 2px solid $light-green;
    box-sizing: border-box;
    color: $light-green;
    background-color: $white;
    border-radius: 20px;
    font-size: 16px;
    line-height: 20px;
    box-shadow: none;
    min-width: 100px;
    // padding: 8px 32px;
    @include hover {
        border: 2px solid #75e5d9;
        box-sizing: border-box;
        color: #75e5d9;
        background-color: $white;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $light-gray !important;
        border: 2px solid $light-gray !important;

        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $white !important;
        border: 2px solid #00776a !important;
        color: #00776a !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100% !important;
    }
}
.white-btn-ts {
    border: 2px solid $light-green;
    box-sizing: border-box;
    color: $light-green;
    font-family: Poppins;
    background-color: $white;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    box-shadow: none;
    // min-width: 120px;
    // padding: .5rem 1rem!important;
    padding: 8px 16px!important;
    @include hover {
        border: 2px solid #75e5d9;
        box-sizing: border-box;
        color: #75e5d9;
        background-color: $white;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $light-gray !important;
        border: 2px solid $light-gray !important;

        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $white !important;
        border: 2px solid #00776a !important;
        color: #00776a !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
    }
}

.white-gray-btn {
    border: 1px solid $text-color;
    box-sizing: border-box;
    color: $text-color;
    background-color: $white;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    font-family: Poppins;
    padding: 8px 16px;
    // padding: 8px 32px;
    @include hover {
        border: 1px solid $text-color;
        box-sizing: border-box;
        color: $text-color;
        background-color: $white;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color:$text-color !important;
        border: 1px solid $text-color !important;
        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $text-color !important;
        border: 1px solid $text-color !important;
        color: $white !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
    }
}
.dark-gray-btn {
    border: 1px solid $light-gray;
    box-sizing: border-box;
    color: $white;
    background-color: $light-gray;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    font-family: Poppins;
    padding: 8px 16px;
    // padding: 8px 32px;
    @include hover {
        border: 1px solid $light-gray;
        box-sizing: border-box;
        color: $white;
        background-color: $light-gray;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $light-gray !important;
        border: 1px solid $disabled-text !important;
        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $light-green !important;
        border: 1px solid $light-green !important;
        color: $white !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
    }
}
.gray-green-btn {
    border: 2px solid $light-green;
    box-sizing: border-box;
    color: $light-green;
    background-color: $input-bg;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    font-family: Poppins;
    padding: 8px 16px;
    // padding: 8px 32px;
    @include hover {
        border: 2px solid $light-green;
        box-sizing: border-box;
        color: $light-green;
        background-color: $input-bg;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $light-gray !important;
        border: 2px solid $disabled-text !important;
        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $input-bg !important;
        border: 2px solid $light-green !important;
        color: $light-green !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
    }
}
.gray-gray-btn {
    border: 2px solid $light-gray;
    box-sizing: border-box;
    color: $light-gray;
    background-color: $input-bg;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    font-family: Poppins;
    padding: 8px 16px;
    // padding: 8px 32px;
    @include hover {
        border: 2px solid $light-green;
        box-sizing: border-box;
        color: $light-green;
        background-color: $input-bg;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $light-gray !important;
        border: 2px solid $disabled-text !important;
        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $input-bg !important;
        border: 2px solid $light-green !important;
        color: $light-green !important;
        box-shadow: none !important;
    }

    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
    }
}
.green-green-btn {
    border: 2px solid $light-green;
    box-sizing: border-box;
    color: $white;
    background-color: $light-green;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    font-family: Poppins;
    padding: 8px 16px;
    // padding: 8px 32px;
    @include hover {
        border: 2px solid $light-green;
        box-sizing: border-box;
        color: $white;
        background-color: $light-green;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        border: 2px solid $light-gray !important;
        background-color: $light-gray;
        @include box-shadow(none);
    }
    &:active {
        background: $light-green !important;
        border: 2px solid $light-green !important;
        color: $white !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
        padding: 8px;
    }
}
.view-ts-btn {
    @media (max-width: 839px) {
        font-size: 10px;
        padding: 8px;
    }
}
.red-btn {
    border: 2px solid $white;
    box-sizing: border-box;
    color: $white;
    background-color: $error;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    min-width: 100px;
    padding: 8px 67px;
    font-family: "Poppins";
    font-weight: 600;
    @include hover {
        border: 2px solid $white;
        box-sizing: border-box;
        color: $white;
        background-color: $error;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $light-gray !important;
        border: 2px solid $light-gray !important;

        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $error !important;
        border: 2px solid $white !important;
        color: $white !important;
        box-shadow: none !important;
    }

    @media (max-width: 839px) {
        
        min-width: unset !important;
    }
}
.send-btn {
    min-width: 200px;
    @media (max-width: 839px) {
        min-width: 100px;
        max-width: 100px;
        width: 100%;
        justify-self: end;
    }
}

.dark-gray-btn2 {
    border: 1px solid $text-color;
    box-sizing: border-box;
    color: $white;
    background-color: $text-color;
    border-radius: 20px;
    font-size: 12px;
    line-height: 20px;
    box-shadow: none;
    font-family: Poppins;
    @include hover {
        border: 1px solid $text-color;
        box-sizing: border-box;
        color: $white;
        background-color: $text-color;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $text-color !important;
        border: 1px solid $disabled-text !important;
        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $text-color !important;
        border: 1px solid $text-color !important;
        color: $white !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
        border-radius: 12px;
        font-size: 10px;
    }

    i {
        color: $white;
    }
}

.new-red-btn {
    @media (max-width: 839px) {
        padding: 4px 16px;
    }
}

.white-dark-green-btn {
    border: 2px solid #00776A;
    box-sizing: border-box;
    color: #00776A;
    font-family: Poppins;
    background-color: $white;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    box-shadow: none;
    // min-width: 120px;
    // padding: .5rem 1rem!important;
    padding: 8px 16px!important;
    @include hover {
        border: 2px solid #00776A;
        box-sizing: border-box;
        color: #00776A;
        background-color: $white;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $light-gray !important;
        border: 2px solid $light-gray !important;

        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $white !important;
        border: 2px solid #00776a !important;
        color: #00776a !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
    }
}

.white-green-btn {
    border: 2px solid $light-green;
    box-sizing: border-box;
    color: $light-green;
    background-color: #ffff;;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    font-family: Poppins;
    padding: 8px 16px;
    // padding: 8px 32px;
    @include hover {
        border: 2px solid $light-green;
        box-sizing: border-box;
        color: $light-green;
        background-color:#ffff;;
        border-radius: 20px;
        box-shadow: none;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        color: $light-gray !important;
        border: 2px solid $disabled-text !important;
        background-color: $white;
        @include box-shadow(none);
    }
    &:active {
        background: $input-bg !important;
        border: 2px solid $light-green !important;
        color: $light-green !important;
        box-shadow: none !important;
    }
    @media (max-width: 839px) {
        min-width: 100px;
        width: 100%;
    }
}