$sizes: 30;

@mixin margin-classes {
  @for $i from 1 through $sizes {
     $margin: $i * 8px;
    /* margin #{$margin} */
    .cm#{$i}  {margin: $margin;}
    .cml#{$i} {margin-left: $margin;}
    .cmr#{$i} {margin-right: $margin;}
    .cmt#{$i} {margin-top: $margin;}
    .cmb#{$i} {margin-bottom: $margin;}
    .cmx#{$i} {margin-left: $margin; margin-right: $margin;}
    .cmy#{$i} {margin-top: $margin; margin-bottom: $margin;}
  }
}
@include margin-classes;


@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 8px;
    /* padding #{$padding} */
    .cp#{$i} {padding: $padding;}
    .cpl#{$i} {padding-left: $padding;}
    .cpr#{$i} {padding-right: $padding;}
    .cpt#{$i} {padding-top: $padding;}
    .cpb#{$i} {padding-bottom: $padding;}
    .cpx#{$i} {padding-left: $padding; padding-right: $padding;}
    .cpy#{$i} {padding-top: $padding; padding-bottom: $padding;}
  }
}
@include padding-classes;

.cpx5{
  @media (max-width: 839px) {
    padding-left: 16px; padding-right: 16px;
  }
}
