.custom-toggle {
    // Inactive toggle
    input[type="checkbox"] {
        position: relative;
        cursor: pointer;
        width: 40px;
        height: 20px;
        appearance: none;
        -webkit-apappearance: none;
        background: $input-bg;
        outline: none;
        border-radius: 20px;
        transition: 0.5s;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    }
    //Inactive circle
    input[type="checkbox"]:before {
        content: "";
        cursor: pointer;
        position: absolute;
        width: 20px;
        height: 20px;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        text-align: center;
        color: $white;
        border-radius: 20px;
        top: 0;
        left: 0;
        top: -15px;
    left: -20px;
        background: $text-color;
        // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
        transition: 0.5s;
    }
    //Hover Inactive circle
    input:hover[type="checkbox"]:before {
        background: #00776a;
    }
    //Active toggle
    input:checked[type="checkbox"] {
        background: #75e5d9;
    }
    //Active circle
    input:checked[type="checkbox"]:before {
        content: "\2713";
        left: 20px;
        font-size: 14px;
        background-color: $light-green;
    }
    //Hover Active circle
    input:checked:hover[type="checkbox"]:before {
        content: "\2713";
        left: 20px;
        font-size: 14px;
        background-color: #00776a;
    }
    //Disabled toggle
    input:disabled[type="checkbox"] {
        background: $input-bg;
        cursor: default;
    }
    //Disabled circle
    input:disabled[type="checkbox"]:before {
        background: $disabled-text;
        cursor: default;
    }
}

.custom-checkbox {


    input[type="checkbox"] {
        position: relative;
        cursor: pointer;
        // width: 18px;
        // height: 18px;
        appearance: none;
        -webkit-apappearance: none;
        background: $input-bg;
        outline: none;
        border-radius: 4px;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    }
    //Default
    input[type="checkbox"]:before {
        content: "";
        cursor: pointer;
        position: absolute;
        width: 18px;
        height: 18px;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        text-align: center;
        color: $white;
        top: 0;
        left: 0;
    
        background: $input-bg;
        border-radius: 4px;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
        transform: scale(1.1);
        transition: 0.5s;
    }

    //Active toggle
    input:checked[type="checkbox"] {
        background: #75e5d9;
        color: $white;
    }
   //Hover Active Selected
   input:checked:hover[type="checkbox"]:before {
    content: "\2713";
    background-color: $input-bg;
    color: $light-green;
    }
    //Disabled
    input:disabled[type="checkbox"] {
        background: $input-bg;
        cursor: default;
    }
    //Disabled
    input:disabled[type="checkbox"]:before {
        background: $input-bg;
        cursor: default;
    }
    .custom-checkbox-archSelection{
        //Default
input[type="checkbox"]:before {
    content: "";
    cursor: pointer;
    position: absolute;
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    text-align: center;
    color: $white;
    // top: 0;
    // left: 0;
    top: -2px;
    left: -0px;
    background: $input-bg;
    border-radius: 4px;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    // transform: scale(1.1);
    // transition: 0.5s;
}
}
}

 .custom-checkbox-archSelection{


    input[type="checkbox"] {
        position: relative;
        cursor: pointer;
        // width: 18px;
        // height: 18px;
        appearance: none;
        -webkit-apappearance: none;
        background: $input-bg;
        outline: none;
        border-radius: 4px;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    }
    //Default
    input[type="checkbox"]:before {
        content: "";
        cursor: pointer;
        position: absolute;
        width: 16px;
        height: 16px;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        text-align: center;
        color: $white;
        // top: 0;
        top:-10px;
        left: 8px;
        // left: 0;
        // top: -15px;
        // left: -20px;
        background: $input-bg;
        border-radius: 4px;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
        // transform: scale(1.1);
        // transition: 0.5s;
    }

    //Active toggle
    input:checked[type="checkbox"] {
        background: #75e5d9;
        color: $white;
    }
    //Active Selected
    input:checked[type="checkbox"]:before {
        content: "\2713";
        background-color: $light-green;
    }
    //Disabled
    input:disabled[type="checkbox"] {
        background: $input-bg;
        cursor: default;
    }
    //Disabled
    input:disabled[type="checkbox"]:before {
        // background: $input-bg;
        cursor: default;
    }

}


 .custome-textarea-attachment {
    // max-width: 300px;
    border-radius: 8px;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    resize: none;
   
}
.textarea-attachment-container {
    .form-control{
        // width :auto !important;
    }
}


.custome-textarea {
    max-width: 420px;
    border-radius: 8px;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    resize: none;
}
.custome-textarea2 {
    border-radius: 8px;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    resize: none;
}

.custome-input-text {
    background-color: $input-bg;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}
.custom-date-picker {
    input {
        z-index: 99 !important;
        background-color: $input-bg;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
    }
}



// .custom-date-picker .react-datepicker-popper {

//         height: auto;
//         @media (max-width: 1500px) {
//             z-index: 1 !important;
//             overflow: auto;
//             height: 170px;
//             }
//     }
  

.footer-date-pic{
    z-index: 999 !important;
}
input[type="email"],
input[type="text"],
input[type="number"],
textarea {
    color: #525f7f !important;
    @media (max-width: 839px) {
        font-size: 16px !important;
        color: #525f7f !important;
    }
}
.css-bg1rzq-control {
    background-color: $input-bg !important;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px !important;
    &:hover {
        background-color: $input-bg !important;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px !important;
    }
}
.css-1szy77t-control {
    border-color: $input-bg !important;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);

    &:hover {
        background-color: $input-bg !important;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 8px !important;
    }
}
