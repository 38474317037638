.radio-item {
  display: inline-block;
  position: relative;
  // padding: 0 6px;
  // margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}


.radio-item label {
  color: $label;
  font-weight: normal;
  line-height: 20px;
  &:disabled {
    cursor: unset;
  }
  @media (max-width: 839px) {
  font-size: 12px;
      }
      
}

.radio-item label:before {
  content: " ";
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid $gray-border;
  background-color: $bg-gray;
  &:disabled {
    cursor: unset;
  }
}


.radio-item input[type=radio]:checked + label:after {
  cursor: pointer;
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: $light-green;

  &:disabled {
    cursor: unset;
  }
}
.radio-item input[type=radio]:checked:hover + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 4px;
  content: " ";
  display: block;
  background: #75E5D9
  ;
  &:disabled {
    cursor: unset;
  }
}
.pointerUnset{
  cursor: unset !important; 
  radio-item label:before {
    cursor: unset !important; 
  }


}





.radio-item-v {
  display: inline-block;
  position: relative;
  // padding: 0 6px;
  // margin: 10px 0 0;
}

.radio-item-v input[type='radio'] {
  display: none;
}
.radio-item-v input[type=radio]:checked + label:before {
  border: 3px solid $light-green ;

}


.radio-item-v label {
  color: $label;
  font-weight: normal;
  line-height: 20px;
  &:disabled {
    cursor: unset;
  }
  @media (max-width: 839px) {
  font-size: 12px;
      }
      
}

.radio-item-v label:before {
  content: " ";
  cursor: pointer;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid $gray-border;
  background-color: $bg-gray;
  &:disabled {
    cursor: unset;
  }
}


.radio-item-v input[type=radio]:checked + label:after {
  cursor: pointer;
  border-radius: 11px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 11px;
  left: 18px;
  content: " ";
  display: block;
  background: $light-green;

  &:disabled {
    cursor: unset;
  }
}
.radio-item-v input[type=radio]:checked:hover + label:after {
  border-radius: 11px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 11px;
  left: 18px;
  content: " ";
  display: block;
  background: #75E5D9
  ;
  &:disabled {
    cursor: unset;
  }
}
.pointerUnset{
  cursor: unset !important; 
  radio-item label:before {
    cursor: unset !important; 
  }


}
