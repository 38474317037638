.font-bold {
  font-weight: $font-weight-bold;
}
.font-normal {
  font-weight: $font-weight-normal;
}
.font-light {
  font-weight: $font-weight-light;
}
.font-medium {
  font-weight: $font-weight-medium;
}
@mixin large-title {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 36px !important;
  line-height: 41px !important;
  font-weight: 400 !important;
  // }
}
@mixin large-title-bold {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 36px !important;
  line-height: 41px !important;
  font-weight: 700 !important;
  // }
}
@mixin huge-title {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 60px !important;
  line-height: 70px !important;
  font-weight: 400 !important;
  // }
}
@mixin huge-title-bold {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 60px !important;
  line-height: 70px !important;
  font-weight: 700 !important;
  // }
}
@mixin title-1 {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
  // }
}
@mixin title-1-bold {
  
  font-family: Poppins !important;
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 700 !important;
   @media (max-width: 839px) {
    font-family: Poppins !important;
    font-size: 18px !important;
    line-height: 30px !important;
    font-weight: 500 !important;
  }
}
@mixin title-2 {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 22px !important;
  line-height: 28px !important;
  font-weight: 400 !important;
  // }
}
@mixin title-2-bold {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 22px !important;
  line-height: 28px !important;
  font-weight: 700 !important;
  // }
}
@mixin title-3 {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 18px !important;
  line-height: 25px !important;
  font-weight: 400 !important;
  // }
}
@mixin title-3-bold {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 18px !important;
  line-height: 25px !important;
  font-weight: 700 !important;
  // }
}
@mixin headline {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 17px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  // }
}
@mixin headline-bold {
  // // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 17px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  // }
}
@mixin callout {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  //}
}
@mixin callout-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 21px !important;
  font-weight: 700 !important;
  //}
}
@mixin subhead {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  @media (max-width: 839px) {
    font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  }
}
@mixin subhead-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  //}
}
@mixin body {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  //}
}
@mixin body-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 600 !important;
  //}
}
@mixin haeder-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 21px !important;
  font-weight: 600 !important;
  //}
}
@mixin body-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  // font-style: italic !important;
  //}
    @media (max-width: 839px) {
      font-family: Poppins !important;
      font-size: 12px !important;
      line-height: 21px !important;
      font-weight: 400 !important;
   }
}
@mixin body-bold-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 600 !important;
  font-style: italic !important;
  //}
}
@mixin footnote {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  //}
}
@mixin footnote-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 600 !important;
  //}
}
@mixin footnote-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  font-style: italic !important;
  //}
}
@mixin footnote-bold-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 600 !important;
  font-style: italic !important;
  //}
}
@mixin caption-1 {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  //}
}
@mixin caption-1-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  //}
}
@mixin caption-1-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  font-style: italic !important;
  //}
}
@mixin caption-1-bold-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 700 !important;
  font-style: 600 !important;
  //}
}
@mixin caption-2 {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 10px !important;
  line-height: 13px !important;
  font-weight: 400 !important;
  //}
}
@mixin caption-2-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 10px !important;
  line-height: 13px !important;
  font-weight: 600 !important;
  //}
}
@mixin caption-2-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 10px !important;
  line-height: 13px !important;
  font-weight: 400 !important;
  // font-style: italic !important;
  //}
}
@mixin caption-2-bold-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 10px !important;
  line-height: 13px !important;
  font-weight: 600 !important;
  font-style: italic !important;
  //}
}
@mixin label-1 {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 10px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  //}
}
@mixin label-1-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 10px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  //}
}
@mixin label-1-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 10px !important;
  line-height: 16px !important;
  font-weight: 400 !important;
  font-style: italic !important;
  //}
}
@mixin label-1-bold-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 10px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  font-style: italic !important;
  //}
}
@mixin label-2 {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  //}
}
@mixin label-2-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  //}
}
@mixin label-2-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  font-style: italic !important;
  //}
}
@mixin label-2-bold-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  font-style: italic !important;
  //}
}
@mixin button {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  //}
}
@mixin seme-bold-button {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 500 !important;
    @media (max-width: 839 ){
      font-family: Poppins !important;
      font-size: 14px !important;
      line-height: 22px !important;
      font-weight: 500 !important;
  }
}


@mixin small-bold-button {
  // @media (max-width: 839px) {
    font-family: Poppins !important;
    font-size: 10px !important;
    line-height: 16px !important;
    font-weight: 500 !important;
    @media (max-width: 839 ){
      font-family: Poppins !important;
      font-size: 10px !important;
      line-height: 16px !important;
      font-weight: 500 !important;
  }
}



@mixin button-bold {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  //}
}
@mixin button-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  font-style: italic !important;
  //}
}
@mixin button-bold-italic {
  // @media (max-width: 839px) {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 22px !important;
  font-weight: 700 !important;
  font-style: italic !important;
  //}
}
