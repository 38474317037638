.terms_component {
  .nav-tabs {
    margin-top: 0;
    margin-bottom: 16px;
  }
  .modal-footer {
    padding-top: 0 !important;
  }
}

.normal_text {
  @include body;
  color: $label;
}
.normal_text_underlined {
  @include body;
  color: $label;
  text-decoration: underline;
}
.custom_padding_start {
  margin-inline-start: 24px;
}
.normal_body_text {
  @include body;
  color: $label;
  padding: 8px 0;
}
.bold_text {
  @include body-bold;
  color: $label;
}
.header_text_bold {
  @include haeder-bold;
  color: $label;
  padding: 8px 0;
}
.terms_container {
  max-height: calc(100vh - 360px);
  width: 100%;
  overflow: auto;
  @media (max-width: 839px) {
    max-height: calc(100vh - 470px);
  }
}
.terms_inner_container {
  padding: 24px 12px;
  background: #f5f5f5;
  /* Text Field Shadow */
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 100%;
}

.onboarding-container {
  overflow-y: "auto";
  position: "relative";
  max-height: calc(100vh - 320px);
  @media (max-width: 839px) {
    max-height: calc(100vh - 250px);
  }
}
.calendly-badge-widget {
  z-index: 999 !important;
}