.related_cases_grid {
  display: flex;
  // flex-direction: column;
  gap: 12px;
   align-items: center;

  // grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  // align-items: center;
  // grid-row-gap: 12px;
}

.related_case_id {
  color: #2977dd;
  text-decoration: underline;
  cursor: pointer;
}
.tooltop-title-hover {
  color: rgba(255, 255, 255, 0.8);
}

.related-cases-row-grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
}
