.c-tabs {
    .nav-tabs {
        border: none !important;
        margin-top: 8px;
    }
    .nav-item {
        font-size: 16px;
        color: $label;
        padding-right: 16px;
        width: 160px;
        font-family: Poppins;
        @media (max-width: 839px) {
            font-size: 14px;
            width: auto;
        }
    }
    .nav-link {
        border-bottom: 4px solid $light-gray;
        padding: 8px;
        padding-left: 0;
    }
    .nav-link.active {
        color: $light-green !important;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 4px solid $light-green !important;
    }

    .nav-tabs .nav-link:hover {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 4px solid $light-gray;
    }

    .tab-pane {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 40px;
    }
}
