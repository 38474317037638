.header_grid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  grid-column-gap: 8px;
  // margin-top: 32px;
}
.header_grid_note {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: baseline;
  // grid-column-gap: 4px;
}
.s_card_grid_active {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  background: $light-green;
  color: $white;
  padding: 16px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 199, 177, 0.3);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500px;
  min-height: 72px;
  grid-column-gap: 8px;
  @media (max-width: 839px) {
    width: 100%;
    max-width: 100%;
  }
}
.onboarding-btns-grid {
  display: grid;
  grid-template-columns: 165px 165px;
  grid-template-rows: 1fr;
  justify-content: end;
  @media (max-width: 839px) {
    justify-content: center;
    justify-items: center;
    grid-template-columns: auto auto;
  }
}
.confarim_preferences-btns-grid {
  display: grid;
  grid-template-columns: 105px 105px;
  grid-template-rows: 1fr;
  justify-content: end;
  column-gap: 8px;
  padding-right: 16px;

  @media (max-width: 839px) {
    justify-content: center;
    justify-items: center;
    grid-template-columns: 1fr;
    row-gap: 8px;
    width: 100%;
    padding-right: 0px;
  }
}
.confarim_preferences_btn_width {
  @media (max-width: 839px) {
    width: 100%;
  }
}
.confarim_preferences_btn_grid {
  @media (max-width: 839px) {
    grid-row: 4;
  }
}
.confarim_preferences_btn_grid1 {
  @media (max-width: 839px) {
    grid-row: 1;
  }
}

.s_card_grid_activeTS {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  background: $light-green;
  color: $white;
  padding: 16px;
  object-fit: fill;
  // max-width: 200px;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 199, 177, 0.3);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500px;
  grid-column-gap: 8px;
  @media (max-width: 839px) {
    width: 100%;
    max-width: 100%;
  }
}
.s_card_grid {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  background: $white;
  color: $text-color;
  padding: 16px;
  max-width: 200px;
  row-gap: 50px;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500px;
  grid-column-gap: 8px;
  min-height: 72px;
  border: 2px solid $white;
  &:active {
    border: 2px solid $light-green;
  }
  @media (max-width: 839px) {
    width: 100%;
    max-width: 100%;
  }
}

.s_card_gridTS {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  background: $white;
  color: $text-color;
  padding: 16px;
  // max-width: 200px;
  row-gap: 50px;
  object-fit: fill;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500px;
  grid-column-gap: 8px;
  min-height: 72px;
  border: 2px solid $white;
  &:active {
    border: 2px solid $light-green;
  }
  @media (max-width: 839px) {
    width: 100%;
    max-width: 100%;
  }
}
.s_card_grid_disabled {
  display: grid;
  cursor: default;
  pointer-events: none;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  background: $white;
  color: $disabled-text;
  padding: 16px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500px;
  grid-column-gap: 8px;
  min-height: 72px;
  @media (max-width: 839px) {
    width: 100%;
    max-width: 100%;
  }
}
.s_card_grid_clicked {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  background: $white;
  color: $text-color;
  padding: 16px 24px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 21px;
  font-weight: 500px;
  grid-column-gap: 8px;
  border: 2px solid $light-green;
  @media (max-width: 839px) {
    width: 100%;
    max-width: 100%;
  }
}

.templete_module_grid {
  display: grid;
  grid-template-columns: 1fr 0.3fr 3fr;
  border-bottom: 1px solid #c8c8ca;
  height: 80%;
  // padding-bottom: 50px;
  @media (max-width: 839px) {
    display: grid;
    grid-template-columns: 1fr;
    border-bottom: none;
  }
}

// .templete_module_grid_maloccluison {
//   padding-bottom: 50px;
//   border-bottom: 1px solid #c8c8ca;
//   padding-top: 24px;
//   display: grid;
//   grid-template-columns: 0.5fr 1fr 0.3fr 7fr 0.5fr;
//   height: 80%;
// }
.relat {
  position: relative;
}
.advance_main_grid_V4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 200px));
  grid-gap: 1em;
  text-align: center;
  align-items: center;

  @media (max-width: 1330px) {
    grid-template-columns: repeat(auto-fit, minmax(auto, 150px));
  }
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1em;
    grid-column-gap: 8px;
  }
  .s_card_grid,
  .s_card_grid_active {
    @media (max-width: 839px) {
      padding: 16px 8px;
      grid-column-gap: 4px;
    }
  }
}
.teeth-row-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
}

.teeth_container_grid {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
  grid-column-gap: 8px;
  align-items: center;
  color: $text-color;
  max-width: 600px;
  margin: 24px auto;
  @media (max-width: 900px) {
    grid-column-gap: 4px;
  }
}
.teeth_divider {
  height: 100%;
  border-left: 1px solid $text-color;
}
.teeth_divider-ipr {
  height: 230px;
  border-left: 1px solid $text-color;
  @media (max-width: 1200px) {
    height: 95%;
  }
}
.side-teeth-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
}
.confarim_preferences_grid {
  display: grid;
  grid-template-columns: auto 1fr;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    text-align: center;
  }

  grid-column-gap: 16px;
}

.teeth-grid {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(8, minmax(auto, 1fr));
  align-items: center;
  text-align: center;
  color: $text-color;
  font-size: 1em;
  line-height: 24px;
  img {
    width: 100%;
  }
  @media (max-width: 900px) {
    font-size: 0.75em;
    grid-column-gap: 2px;
  }
}
// .templete_module_grid_maloccluison {
//   padding-bottom: 50px;
//   border-bottom: 1px solid #c8c8ca;
//   padding-top: 24px;
//   display: grid;
//   grid-template-columns: 0.5fr 1fr 0.3fr 7fr 0.5fr;
//   height: 500px;
//   overflow: auto;
//   @media (max-width: 839px) {
//     grid-template-columns: repeat(8, minmax(6px, 25px));
//     grid-column-gap: 0;
//   }
// }
.templete_module_grid_maloccluison {
  // padding-bottom: 50px;
  border-bottom: 1px solid #c8c8ca;
  padding-top: 24px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.3fr 7fr 0.5fr;
  // height: 500px;
  // overflow: auto;
  @media (max-width: 839px) {
    display: grid;
    padding-top: 0px;
    grid-template-columns: auto;
    border-bottom: none;
  }
}

.navbar-icons-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  grid-template-columns: 16px;
}
.confirmation-buttons-grid {
  display: grid;
  grid-template-columns: 100px 100px;
  justify-content: end;
  grid-column-gap: 16px;
  text-align: center;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
}
.filters-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;
  align-items: center;
  color: $text-color;
  cursor: pointer;
}
.custom-radio-group-grid {
  display: grid;
  grid-template-columns: 1fr;

  grid-gap: 8px;
}
.custom-radio-group-grid-v {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.input-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 8px;
}

.preferance-details-grid {
  max-width: 1150px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  grid-column-gap: 8px;
  grid-row-gap: 24px;
  .wear_cycle {
    width: 90px;
  }
  //border-bottom: 1px solid #c8c8ca;
  padding: 32px 0;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
}
.onboarding-preferance-details-grid {
  max-width: 930px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  border-bottom: 1px solid #c8c8ca;
  padding: 32px 0;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
}
.first-step-grid {
  display: grid;
  grid-template-columns: 20px auto 1fr;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  align-items: flex-end;
}

.d-grid {
  display: grid;
}
.preferance-ipr-attachment-grid {
  max-width: 930px;
  display: grid;
  grid-template-columns: 1.2fr 2fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 24px;
  border-bottom: 1px solid #c8c8ca;
  padding: 32px 0;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
}
.onboarding-preferance-ipr-grid {
  max-width: 930px;
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 24px;
  border-bottom: 1px solid #c8c8ca;
  padding: 32px 0px 0px 0px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  }
}
.onboarding-preferance-attachment-grid {
  max-width: 930px;
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 24px;
  border-bottom: 1px solid #c8c8ca;
  padding: 0px 0px 0px 0px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    margin-bottom: -32px;
  }
}

.step-grid {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  align-items: flex-end;
}
.stepElastic-grid {
  display: grid;
  grid-template-columns: 20px auto auto auto 1fr;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  align-items: flex-end;
}

.address-grid {
  display: grid;
  grid-template-columns: repeat(6, minmax(auto, 1fr));
  grid-row-gap: 48px;
  align-items: flex-start;
  padding: 32px 0;
  color: $light-green;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  max-height: calc(100vh - 300px);
  overflow: auto;
  width: 100%;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-row-gap: 16px;
  }
  @media (max-width: 839px) {
    // max-height: calc(100vh - 375px);
    max-height: 100%;
  }
}
.address-title-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 12px;
  align-items: center;
}
.address-data-grid {
  display: grid;
  grid-template-columns: 1fr;
  margin: 16px 0;
  color: $text-color;
}

.footer-address-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
}
.gco-preferance-grid {
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  // border-bottom: 1px solid #c8c8ca;
  padding: 32px 0;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
}

.general-settings-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 32px;
  max-height: calc(100vh - 250px);
  overflow: auto;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
    max-height: unset;
    overflow: visible;
  }
}
.profile-img-grid {
  display: grid;
  grid-template-columns: 2fr 4fr auto;
  grid-column-gap: 8px;
  align-items: center;
  padding: 0 8px;
  @media (max-width: 1000px) {
    grid-template-columns: 2fr 1fr auto;
    grid-row-gap: 8px;
  }
}
.setting-data-grid {
  display: grid;
  grid-template-columns: 2fr 4fr 1fr;
  grid-column-gap: 8px;
  align-items: center;
  padding: 0 8px;
}
.setting-values-grid {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  grid-column-gap: 8px;
  align-items: center;
  padding: 0 8px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
}
.settings-buttons-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  align-self: flex-end;
  padding: 0 32px;
  @media (max-width: 839px) {
    padding: 0;
  }
}
.date-grid {
  display: "grid";
  grid-template-columns: 1fr 2fr 2fr 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align: center;
}
.card-title-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  // height: 32px;
}

.c_impressions_grid {
  display: grid;
  grid-template-columns: 1fr 30px 3fr;
  align-items: start;
  height: 100%;
  grid-column-gap: 42px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    // grid-row-gap: 16px;
    height: auto;
  }
}

.c_impressions_grid_retainer_request {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: start;
  height: 100%;
  grid-column-gap: 42px;
  @media (max-width: 839px) {
    // grid-template-columns: 1fr;
    grid-row-gap: 16px;
    height: auto;
  }
}

.c_impressions_grid_retainer_request2 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: start;
  padding-top: 10px;
  height: 100%;
  grid-column-gap: 42px;
  @media (max-width: 839px) {
    grid-column-gap: 2px;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    height: auto;
  }
}

.c_impressions_grid-retainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: start;
  // height: 100%;
  padding-bottom: 80px;
  grid-column-gap: 42px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    // grid-row-gap: 16px;
    padding-bottom: 20px;
    height: auto;
  }
}
.prescription_inner_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 16px;
  }
}

.patient-info-summary {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  color: $text-color;
}
.case-info-summary {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  color: $text-color;
  max-width: 250px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  align-items: center;
}
.green-title {
  color: $light-green;
}
.black-title {
  color: #000000 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.submitted_grid {
  display: grid;
  place-items: center;
  justify-items: center;
  height: 65vh;
  @media (max-width: 839px) {
    height: 50vh !important;
  }
}
.case-submitted {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;
  font-size: 24px;
  line-height: 32px;
  color: $text-color;
  font-weight: 600;
  align-items: center;
  @media (max-width: 839px) {
    font-size: 18px;
    grid-template-columns: 1fr;
    place-items: center;
    line-height: 24px;
  }
}
.case-submitted-mobile {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  font-size: 32px;
  line-height: 38px;
  color: #00c7b1;
  place-items: center;
  font-weight: 600;
}
.submitted_actions_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  grid-column-gap: 16px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 16px;
  }
}
.submitted_actions_grid2 {
  display: grid;

  place-items: center;
}

.step-icon-grid {
  width: 75%;
  position: absolute;
  display: grid;
  grid-template-columns: 36px 1fr;
  align-items: center;
  grid-column-gap: 8px;
  font-size: 14px;
  line-height: 20px;
  justify-content: center;
  right: 0;
  @media (max-width: 839px) {
    width: 90%;
  }
}

.cases_review_page {
  .custom-radio-group-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 8px;
    align-items: center;
    @media (max-width: 839px) {
      grid-column-gap: 1px;
      grid-template-columns: 1fr 1fr auto;
    }
  }
}
.cases_filter_search_grid {
  display: grid;
  grid-template-columns: 7fr 2fr;
  grid-column-gap: 16px;
  align-items: center;
  @media (max-width: 839px) {
    grid-row-gap: 16px;

    grid-template-columns: 1fr;
  }
}

.patient_info_actions_grid {
  display: grid;
  grid-template-columns: 3fr 5.5fr;
  grid-column-gap: 8px;
  align-items: center;
  @media (max-width: 839px) {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
}
.patient_case_grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;
  align-items: center;
  @media (max-width: 1055px) {
    grid-row-gap: 16px;
    grid-template-columns: 1fr;
  }
  @media (max-width: 839px) {
    grid-row-gap: 16px;
    grid-template-columns: auto 1fr;
  }
}

.case-info-details {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 4px;
  padding-bottom: 4px;
  color: $label;
  font-weight: 600;
  @media (max-width: 839px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }
}
.case-info-details-info {
  font-weight: 500 !important;
  font-family: "Poppins";
}
.ts-info-details {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 4px;
  padding-bottom: 4px;
  color: $label;
  font-weight: 600;
  grid-column-gap: 4px;
}

.case_actions_grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(150px, 1fr));
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  @media (max-width: 1250px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  @media (max-width: 839px) {
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
    div {
      font-size: 12px;
    }
  }
}
.actions-card {
  padding: 8px !important;
  min-height: 60px !important;
  height: 60px !important;
}

.rejection_list_grid {
  display: grid;
  align-items: center;
  justify-content: end;
  grid-row-gap: 16px;
}
.rejection_card_grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 16px;
  align-items: center;
  color: $white;
  background: $error;
  width: 600px;
  border: 1px solid $error;
  box-shadow: 0px 2px 26px rgba(215, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  b {
    font-family: "Poppins";
    font-weight: 600;
  }
  @media (max-width: 839px) {
    grid-template-columns: 1fr auto;
    grid-row-gap: 16px;
    width: 100%;
  }
}

.inner_rejection_grid {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 16px;
}
.case-history {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  margin: 0;
  padding: 0;
  padding: 16px 0;
  li:nth-child(odd) {
    color: $label;

    background: #f5f5f5;
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    padding: 8px;
  }
  li:nth-child(even) {
    color: $label;
    background: $white;
    border: 1px solid $light-gray;
    border-radius: 8px;
    padding: 8px;
  }
}
.history-card {
  display: grid;
  grid-template-columns: 1.3fr 2fr;
  align-items: center;
  justify-content: center;
}
.main-inbox-grid {
  position: relative;
  display: grid;
  position: relative;
  grid-template-columns: 16px 80% auto;
  align-items: center;
  height: calc(100vh - 250px);
  overflow: auto;

  @media (max-width: 839px) {
    height: auto !important;
    overflow: unset;
    grid-template-columns: 1fr;
  }
}

.audio-grid {
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr auto 10px;
  grid-column-gap: 8px;
  align-items: center;
  width: 100%;

  border-radius: 24px;
  background-color: #c8c8ca;

  audio::-webkit-media-controls-enclosure {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    z-index: 0;
    overflow: hidden;
    text-align: right;
    bottom: auto;
    border: none;
    background-color: #c8c8ca;
  }
  audio {
    width: 100%;
    height: 20px;
  }

  span {
    i {
      cursor: pointer;
    }
  }
}
.impressions_grid_details {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
  }
}

.photos_xrays_grid_title {
  display: grid;
  grid-template-columns: 3.5fr 1fr;
  grid-column-gap: 16px;
  color: $light-green;
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins;
  max-width: 960px;
  @media (max-width: 839px) {
    display: none;
  }
}
.photos_xrays_title2 {
  display: none;
  @media (max-width: 839px) {
    display: block;
    color: $light-green;
    font-size: 18px;
    font-weight: 600;
    font-family: Poppins;
    max-width: 960px;
  }
}
.treatment_setup_grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr auto auto auto;
  grid-column-gap: 16px;
  padding: 16px 24px 16px 32px;
  background: $input-bg;
  border-radius: 8px;
  align-items: center;
  @media (max-width: 839px) {
    padding: 16px 8px 16px 8px;
  }

  .front_title {
    font-size: 16px;
    color: $label;
    font-family: Poppins;
    @media (max-width: 839px) {
      font-size: 14px;
    }
  }

  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr auto;
    grid-row-gap: 8px;
    grid-column-gap: 8px;
    .collapse_btn {
      grid-column: 3;
      grid-row: 1;
    }
    .status_button {
      grid-column: 2;
      grid-row: 1;
      @media (max-width: 839px) {
        grid-template-columns: auto 1fr auto;
      }
    }
    .ts_btn {
      grid-column: 1;
      grid-row: 3;
      width: 100%;
      @media (max-width: 839px) {
        display: none;
      }
    }

    .share_link_toggle {
      grid-column: 1;
      grid-row: 4;
      width: 100%;
      @media (max-width: 839px) {
        display: none;
      }
    }
  }
}
.ts-collapse-open {
  border: none !important;
  border-radius: 15px 15px 0 0 !important;
  // border-radius: 8px 8px 0 0 ;
}
.arrow-grid {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.ts_collapse_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  grid-column-gap: 16px;
  padding: 32px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    display: block;
    padding: 16px;
  }
}
.btn_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  width: fit-content;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
  a[disabled] {
    pointer-events: none;
    cursor: default;
  }
  button {
    min-width: 100px;
  }
}
.revised-btn {
  button {
    min-width: 216px;
    @media (max-width: 839px) {
      width: fit-content;
      min-width: 208px !important;
    }
  }
}

.new-btn-grid {
  @media (max-width: 839px) {
    display: grid;
    grid-template-columns: 1fr;
    margin-right: 50px;
    padding-bottom: 24px;
    column-gap: 8px;
    width: 100%;
    button {
      // min-width: 100px;
    }
  }
}

.c-table {
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 12px;
}
.c-table-header-grid {
  display: grid;
  grid-template-columns: 1.2fr 1fr 0.8fr 0.8fr 1.2fr 1.2fr 0.5fr;
  grid-column-gap: 8px;
  background: #c8c8ca20;
  padding: 8px 32px;
  color: $label;
  border-bottom: 1px solid $light-gray;
  align-items: center;
  height: 56px;
  font-size: 14px;
  border-radius: 8px 8px 0 0;
  @media (max-width: 950px) {
    grid-template-columns: 1.2fr 1fr 0.8fr 0.8fr 1.2fr 1.2fr;
    .case_archive {
      display: none;
    }
  }

  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr 1fr;
    .case_id {
      display: none;
    }
    .case_type {
      display: none;
    }
    .case_date {
      display: none;
    }
    .created_at {
      display: none;
    }
    .case_archive {
      display: none;
    }
  }
}
.c-table-body-grid {
  display: grid;
  grid-template-columns: 1.2fr 1fr 0.8fr 0.8fr 1.2fr 1.2fr 0.5fr;
  grid-column-gap: 8px;
  background: $white;
  padding: 0 32px;
  color: $label;
  border-bottom: 1px solid $light-gray;
  align-items: center;
  font-size: 14px;
  min-height: 56px;
  @media (max-width: 950px) {
    grid-template-columns: 1.2fr 1fr 0.8fr 0.8fr 1.2fr 1.2fr;
    .case_archive {
      display: none;
    }
  }
  @media (max-width: 839px) {
    grid-template-columns: auto auto 1fr;
    .case_id {
      display: none;
    }
    .case_type {
      display: none;
    }
    .case_date {
      display: none;
    }
    .created_at {
      display: none;
    }
    .case_archive {
      display: none;
    }
  }
}
.max-body-h {
  max-height: calc(100vh - 410px);
  overflow: auto;
  @media (max-width: 839px) {
    max-height: calc(100vh - 520px);
  }
}
.c-table-footer {
  height: 56px;
  font-size: 14px;
  border-radius: 0 0 8px 8px;
  display: grid;
  align-items: center;
}

.onboarding_grid {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 16px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
}
.tooth_movement_grid {
  display: grid;
  grid-template-columns: 1.3fr 1fr 0.8fr;
  grid-column-gap: 8px;
  //align-items: center;
  font-size: 12px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  .custom-options-title {
    font-size: 12px;
  }

  .add-step {
    font-size: 12px;
    color: $light-gray;
  }
}

.movement_value_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  font-size: 12px;
  line-height: 21px;
  align-items: center;
  color: #000000;
  .header {
    font-family: Poppins;
    font-size: 14px;
  }
}
.value_grid {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 8px;
  align-items: center;
}
.ToothAttachments .attachment-movement-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 4px;
  font-size: 12px;
  .form-control {
    display: inline !important;
  }
  .step-number {
    margin-bottom: 4px;
  }

  .remove-step {
    margin-top: 4px;
  }
}

.attachment-movement-grid {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 4px;
  font-size: 12px;
  .form-control {
    display: inline !important;
  }
  .step-number {
    margin-bottom: 4px;
  }

  .remove-step {
    margin-top: 4px;
  }
}

.add-attachment-movement-grid {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 4px;
  .add-step {
    margin-top: 0;
    color: $light-gray;
  }
}
.attachment-movement-step {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 4px;
}

.custom-teeth-selector-container {
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-column-gap: 16px;
  padding-right: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
  .teeth_divider {
    height: 200px;
  }
  .dir {
    margin-bottom: 12px;
    line-height: 24px;
  }
}

.ipr_teeth_selector {
  .dir {
    margin-bottom: 0 !important;
    line-height: 24px;
  }
}
.custom-teeth-selector-container-ipr {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 16px;
  padding-right: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
  .teeth_divider {
    height: 80%;
  }

  .teeth_container_grid {
    //width: 30vw;
    //max-width: 1200px;
    margin: 0 auto;
  }
}
.custom-teeth-selector-container-summary {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  margin: 0 auto;
  .teeth_divider {
    height: 200px;
  }

  .teeth_container_grid {
    //width: 30vw;
    //max-width: 1200px;
    margin: 0 auto;
  }

  .dir {
    margin-bottom: 12px;
    line-height: 24px;
  }
}
.custom-teeth-selector-codes {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  align-self: flex-end;
  margin-top: 150px;
  @media (max-width: 1200px) {
    margin-top: 0;
    justify-content: end;
  }
}

.custom-teeth-selector-codes-iprr {
  // display: grid;
  display: none;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  align-self: flex-end;
  margin-top: 150px;
  @media (max-width: 1200px) {
    margin-top: 0;
    justify-content: end;
  }
}
.color-code-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 4px;
  align-items: center;
  .color {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
  .title {
    color: #515151;
  }
  .not_edited {
    background: $text-color;
  }
  .edited {
    background: #ffb100;
  }
  .active {
    background: #75e6d9;
  }
  .restrict {
    position: relative;
  }
  // .note-tooltip {
  //   position: absolute;
  //   right: 40px;
  //   @media (max-width: 1200px) {
  //     left: 140px;
  //   }
  // }

  // .ellipsis{
  //   white-space: nowrap;

  //   // display: -webkit-box;
  //   // -webkit-line-clamp: 2;
  //   // -webkit-box-orient: vertical;
  //   max-width: 150px;
  // }
}
.restrict-grid {
  display: grid;
  grid-template-columns: auto auto 1fr;
  .new-tooltip {
    transform: translateY(-5px);
  }
  .note-tooltip {
    position: relative;
    right: 0;
    z-index: 99 !important;
    .tooltip-bottom {
      min-width: fit-content;
    }
  }
}
.ts-stepper {
  display: grid;
  grid-template-columns: repeat(5, minmax(auto, 1fr));
  grid-column-gap: 8px;
  align-items: center;
}

.ipr_steps_grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  grid-column-gap: 8px;
}

.bite-check-grid {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 16px;
  width: fit-content;
  margin: 0 auto;
}
.positions {
  display: grid;
  grid-row-gap: 16px;
  svg {
    cursor: pointer;
  }
}

.movement-summary-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  // align-items: center;
  color: $label;
  font-size: 14px;
  font-family: Poppins;
  overflow: auto;

  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.summary-movement-details {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
  // align-items: center;
  height: fit-content;
}

.summary-grid {
  height: 100%;
}
.revised-summary-grid {
  height: calc(100vh - 150px);
  overflow: auto;
}
.case-submitted-mobile-image {
  width: 50px;
}

// .prescription-mobile-order{
//   @media (max-width: 839px) {
//     display: grid;
//     grid-template-columns: 1fr;
//   }
// }
// .prescription-mobile-order-inner1{
//   @media (max-width: 839px) {
//     grid-row: 1;
//   }
// }
// .prescription-mobile-order-inner2{
//   @media (max-width: 839px) {
//     grid-row: 2;
//   }
// }

.ts-view-share-grid {
  display: none;
  @media (max-width: 839px) {
    display: grid;
    grid-template-columns: 2fr;
    column-gap: 16px;
    align-items: baseline;
  }
}

.braces_before_aligner_note {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-column-gap: 16px;
}

.stepper_grid {
  display: grid;
  grid-template-columns: 1fr auto;
  // grid-column-gap: 8px;
  align-items: center;
}

.adjustment_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.adjus_impression_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.adjus_impression_upload_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.adjus_photos_grid {
  display: grid;
  grid-template-columns: 1fr 20fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.adjus_photos_row_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}

.order-button-mobile {
  @media (max-width: 839px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
  }
}
.custom-margin-mobile {
  margin-left: 8px;
  @media (max-width: 839px) {
    margin-left: 0px;
  }
}

.cases_filter_search_grid_inner_mobile {
  display: none;
  @media (max-width: 839px) {
    align-items: baseline;
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
}
.cases_filter_search_grid_inner_mobile_button {
  @media (max-width: 839px) {
    // display: grid;
    // grid-template-columns:1fr auto ;
  }
}
.cases_filter_search_grid_inner_website {
  @media (max-width: 839px) {
    display: none;
  }
}
.light-green-btn-mobile-table {
  @media (max-width: 839px) {
    font-size: 10px !important;
  }
}
//mobile
.btn-mobile-View-ts {
  @media (min-width: 839px) {
    display: none;
  }
}
.case-info-details-desktop {
  @media (max-width: 839px) {
    display: none;
  }
}
.technician_notes_padding {
  padding: 8px;
  font-size: 14px;
}

.address-botton-mobile {
  width: 100% !important;
}

.Impression_hieght_retainer_request .custom-radio-group-grid-v {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 450px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.case-info-details-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  // grid-column-gap: 20px;
}

.case-info-details-grid-button {
  align-self: end;
  place-content: end;
  padding-bottom: 20px;
  display: flex;
}
.fixed_width {
  width: 60% !important;
}

.hr_remove_padding {
  display: none;
  @media (max-width: 839px) {
    display: grid;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
}
.resources_grid {
  display: grid;
  margin-top: 16px;
  grid-template-columns: repeat(auto-fit, minmax(auto, 200px));
  align-items: center;
  text-align: center;
  grid-column-gap: 16px;
  .resource {
    cursor: pointer;
    height: 250px;
    width: auto;
    margin: 0 auto;
    border: 1px solid #00000030
  }
  .title {
    margin-top: 8px;
    @include body;
    font-weight: 500;
    cursor: pointer;
  }
}


