/*these tooltips can work with any element eg buttons,div etc
*/

/*just create a beautiful button,this is a sample;*/

.tooltip1 {
  display: inline-block;
  position: relative;
  // border-bottom: 1px dotted #666;
  text-align: left;
  /*i used these margins to position tooltip around the conatainer so dont include them*/
  // margin-top: 150px;
  // margin-left: 100px;
  z-index: 99;
  &:hover {
    .fa-question-circle {
      color: $light-green;
    
    }
  }
  @media (max-width: 839px ) {
    position: unset;
  }
}


.tooltip1 .tooltip-top {
  min-width: 200px;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 20px;
  /*all of the tooltips tweaks the margins to fit you*/
  margin-top: 10px;
  color: #444444;
  background-color: #121212;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltip1:hover .tooltip-top {
  visibility: visible;
  opacity: 1;
}
.tooltip1:focus .tooltip-top {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip1 .tooltip-top i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*ends here and you can tweak it to fit your needs*/

/*right tolltip*/

.tooltip1 .tooltip-right {
  min-width: 500px;
  top: 50%;
  left: 100%;
  margin-left: 20px;
  transform: translate(0, -50%);
  padding: 10px 30px;
  color: #525252;
  background-color: $white;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;
  @media (max-width: 839px) {
    min-width: 200px;
    top: 0px;
    left: -80px;
    transform: translate(50%, 0);
    padding: 10px 30px;
    color: #525252;
    background-color: $white;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    border: 1px solid $border-color-light;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
    width: 100%;
  }
}

.tooltip1:hover .tooltip-right {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip1 .tooltip-right i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*end right*/

/*start left tip*/
.tooltip1 .tooltip-left {
  min-width: 200px;
  top: 50%;
  right: 100%;
  margin-right: 20px;
  transform: translate(0, -50%);
  padding: 10px 20px;
  color: #444444;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltip1:hover .tooltip-left {
  visibility: visible;
  opacity: 1;
}
.tooltip1:focus .tooltip-left {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-left i {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip1 .tooltip-left i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*end left*/

/*start bottom*/

.tooltip1 .tooltip-bottom {
  min-width: 275px;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  color: $white;
  background-color: $text-color;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;

  @media (max-width: 839px) {
    min-width: 200px;
    left: 50%;
    height: auto; 
    padding: 10px 20px;
    overflow:unset;
    transform: translate(-35%, 0);
  }
} 
.tooltip1 .custom-tooltip-bottom {
  min-width: 275px;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  color: $white;
  background-color: $text-color;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;
  @media (max-width: 839px) {
    min-width: 345px;
    max-width: 355px;
    left: 0%;
    transform: translate(-31%, 10%);
    margin-left: auto; 
    margin-right: auto;
    height: 415px;
    top: -475px;
    min-height: 150px;
    padding: 10px 20px;
    overflow:unset; 
    position: absolute;
  }
}
.tooltip-basic .custom-tooltip-basic-bottom {
  @media (max-width: 839px) {
    left: 50% !important;
    height: auto !important;
    top: -455px;
    padding: 24px !important;
  }
}
.tooltip-plus .custom-tooltip-plus-bottom {
  background-color: #00C7B1;
  @media (max-width: 839px) {
    left: 80% !important;
    height: auto !important;
    top: -455px; 
    padding: 24px !important;
  }
}
.tooltip-pro .custom-tooltip-pro-bottom {
  background-color: #00776A;
  @media (max-width: 839px) {
    left: 110% !important;
    height: auto !important;
    top: -480px;
    padding: 24px !important;
  }
}

.tooltip1:hover .tooltip-bottom {
  visibility: visible;
  opacity: 1;
}
.tooltip1:hover .custom-tooltip-bottom {
  visibility: visible;
  opacity: 1;
}

.tooltip1 .tooltip-bottom i {
  position: absolute;
  bottom: 100%;
  left: 35%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip1 .tooltip-bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: $text-color;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}

/********************************* package type tooltip ****************************************/

.tooltip2 {
  display: inline-block;
  position: relative;
  // border-bottom: 1px dotted #666;
  text-align: left;
  /*i used these margins to position tooltip around the conatainer so dont include them*/
  // margin-top: 150px;
  // margin-left: 100px;
  z-index: 99;
  &:hover {
    .fa-question-circle {
      color: $light-green;
    }
  }
}

.tooltip2 .tooltip-top {
  min-width: 200px;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 20px;
  /*all of the tooltips tweaks the margins to fit you*/
  margin-top: 10px;
  color: #444444;
  background-color: #121212;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltip2:hover .tooltip-top {
  visibility: visible;
  opacity: 1;
}
.tooltip2:focus .tooltip-top {
  visibility: visible;
  opacity: 1;
}

.tooltip2 .tooltip-top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip2 .tooltip-top i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*ends here and you can tweak it to fit your needs*/

/*right tolltip*/

.tooltip2 .tooltip-right {
  min-width: 500px;
  top: 50%;
  left: 100%;
  margin-left: 20px;
  transform: translate(0, -50%);
  padding: 10px 30px;
  color: #525252;
  background-color: $white;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;
  @media (max-width: 839px) {
    min-width: 200px;
    top: 35px;
    left: -80px;
    transform: translate(-50%, 0);
    padding: 10px 30px;
    color: #525252;
    background-color: $white;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    border: 1px solid $border-color-light;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
    width: 100%;
  }
}

.tooltip2:hover .tooltip-right {
  visibility: visible;
  opacity: 1;
}

.tooltip2 .tooltip-right i {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip2 .tooltip-right i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*end right*/

/*start left tip*/
.tooltip2 .tooltip-left {
  min-width: 200px;
  top: 50%;
  right: 100%;
  margin-right: 20px;
  transform: translate(0, -50%);
  padding: 10px 20px;
  color: #444444;
  background-color: #eeeeee;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
}

.tooltip2:hover .tooltip-left {
  visibility: visible;
  opacity: 1;
}
.tooltip2:focus .tooltip-left {
  visibility: visible;
  opacity: 1;
}

.tooltip2 .tooltip-left i {
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  overflow: hidden;
}

.tooltip2 .tooltip-left i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: #eeeeee;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}
/*end left*/

/*start bottom*/

.tooltip2 .tooltip-bottom {
  min-width: 275px;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  color: $white;
  background-color: $text-color;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99;
  box-sizing: border-box;
  border: 1px solid $border-color-light;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  width: 100%;

  @media (max-width: 839px) {
    min-width: 200px;
    left: 50%;
    height: 100%;
    min-height: 150px;
    padding: 10px 20px;
    overflow: scroll;
  }
}

.tooltip2:hover .tooltip-bottom {
  visibility: visible;
  opacity: 1;
}
.custom-tooltip-bottom::after {
	content: '';
	position: absolute;
	bottom: 35px;
	left:  36%;
	width: 0;
	height: 0;
	border: 18px solid transparent;
	border-top-color: #898A8D; 
	border-bottom: 0; 
	border-left: 0;
	margin-left: -15.5px;
	margin-bottom: -31px;
  transform: translate(-50%, 50%) rotate(225deg);
}
.custom-tooltip-plus-bottom::after {
  left:  33%;
	border-top-color: #00C7B1; 

}
.custom-tooltip-pro-bottom::after {
  left:  31%;
	border-top-color: #00776A; 

}

.tooltip2 .tooltip-bottom i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip2 .tooltip-bottom i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: $text-color;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.8);
}

/************************************ end package type tooltip **************************************/
.fa-question-circle {
  font-size: 14px;
  left: 2px;
  bottom: 10px;
  color: $text-color;
}

.new-tooltip {
  transform: translateY(-15px);
}

.package-type-tooltip {
  
}
.note-type {
  font-size: 16px;
  font-weight: 900;
  text-align: end;
  @media (max-width: 839px) {
    text-align: center;
  }
}
.note-retainer {
  font-size: 12px;
  font-weight: 900;
  text-align: start;
  @media (max-width: 839px) {
    text-align: center;
  }
}
.note-description {
  font-size: 10px;
  padding-top: 4px;
  @media (max-width: 839px) {
    padding-top: 8px;
  }
}
.note-devider {
  border-bottom: 1px solid $light-gray;
  @media (max-width: 839px) {
    display: none;
  }
}

.lite-note-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-column-gap: 4px;
  @media (max-width: 839px) {
    grid-template-columns: 30px 30px 70px; 
    grid-column-gap: unset;
    justify-content: center;
    padding-top: 32px;
  }
}
.fourteen-lite {
  font-size: 12px;
  position: absolute;
  top: 10%;
  left: 26%; 
  @media (max-width: 839px) {
    left: 31.5%;
  }
}
.lite-img {
  position: relative;
}
.lite_arch_note_grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-column-gap: 4px;
  text-align: center;
  @media (max-width: 839px) {
    grid-template-columns: 135px 75px 70px;
    grid-column-gap: unset;
    justify-content: center;
    padding-top: 32px;
    grid-row-gap: 8px;
  }
}
.upper_note_grid {
  display: grid;
  text-align: center;
  @media (max-width: 839px) {
    justify-content: center;
  }
}
.fourteen-lite_arch_1 {
  font-size: 12px;

  transform: translateY(-5px);
}
.fourteen-lite_arch_2 {
  font-size: 12px; 
  transform: translateY(5px);

}
.unlimited_not_grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-column-gap: 4px;
  @media (max-width: 839px) {
    grid-template-columns: 85px 50px 70px; 
    grid-column-gap: unset;
    justify-content: center;
    padding-top: 24px; 
    grid-row-gap: 8px;
  }
}
.inner-lite-arch-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  grid-column-gap: 4px;
  text-align: center;
  @media (max-width: 839px) {
    grid-template-columns: auto auto auto;
    grid-row-gap: 8px;
  }
}
.inner_unlimited_arch {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 4px;
  @media (max-width: 839px) {
    grid-template-columns: 20px 20px;
    grid-row-gap: 0px;
    justify-content: center;
  }
}
.c-tooltip-body {
  width: 600px !important;
  font-size: 14px;
  line-height: 20px;
  color: $white;
  @media (max-width: 839px) {
    width: 280px !important;
    font-size: 12px;
    line-height: 20px;
    padding: 0px 16px 0px 16px !important;
  }
}
