.react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__month-container {
    background: $white !important;
    box-shadow: 0px 6.80563px 13.6113px 1.70141px rgba(97, 97, 97, 0.1),
      0px 13.6113px 27.2225px 1.70141px rgba(97, 97, 97, 0.1) !important;
    border-radius: 12px;
    width: 290px;
  }
  .react-datepicker__header {
    background: $white !important;
    border: none !important;
  }
  .react-datepicker {
    border: none !important;
  }
  .react-datepicker__day-name {
    font-size: 0;
  }
  .react-datepicker__day-name::first-letter {
    font-size: 12px;
    color: $light-green !important;
    line-height: 18px;
    vertical-align: middle !important;
  }
  .react-datepicker__day--keyboard-selected {
    border-radius: 50% !important;
    background: $light-green !important;
  }
  .react-datepicker__day--selected {
    border-radius: 50% !important;
    background: $light-green !important;
  }
  
  .arrow-button {
    cursor: pointer;
    color: $light-green !important;
  }
  .border-none {
    border: none !important;
    width:  100%;
    text-align: center;
  }
  .react-datepicker__day--outside-month{
    color: $light-gray !important;
  }
  .react-datepicker__month{
    margin: 8px 16px 32px !important;
  }