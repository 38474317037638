@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: 21px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px 12px 12px 0px;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 18px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 12px;
    border: none;
    margin: 16px 0px;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
.c-line::after {
  display: inline-block;
  content: "";
  border-top: 1px solid $light-gray;
  width: 100%;
  margin: 0;
  transform: translateY(-2px);
}
.preferance-details-bottom-border {
  border-bottom: 1px solid #c8c8ca;
  max-width: 930px;
  //height: 1px;
}
.c-line-bold::after {
  display: inline-block;
  content: "";
  border-top: 3px solid $light-gray;
  width: 100%;
  margin: 0;
  transform: translateY(-2px);
}
.c-line-summary::after {
  display: inline-block;
  content: "";
  border-top: 1px solid $light-gray;
  width: 100%;
  margin: 16px 0;
}
.onbording_header_title {
  color: $light-green;
  font-size: 28px;
  font-family: Poppins;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  line-height: 32px;
  font-weight: 600;
  padding-bottom: 16px;
}
.header_title {
  color: $light-green;
  font-size: 24px;
  font-family: Poppins;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  line-height: 32px;
  font-weight: bold;

  @media (max-width: 839px) {
    font-size: 24px;
    font-weight: normal;
  }
  .optional {
    font-size: 16px;
    line-height: 21px;
    color: #00c7b1;
    // font-weight: bold;
    padding: 0 4px;
  }
}

.header_title3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 36px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $light-green;
}
.header_title2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: $light-green;

  .optional {
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    padding: 0 4px;
  }
}
.not-capitalize {
  text-transform: none !important;
}
// .c-scroll::-webkit-scrollbar-track
// {
//     width: 5px;

// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	background-color: $text-color;
// }

// .c-scroll::-webkit-scrollbar
// {
// 	width: 5px;
// 	background-color: $text-color;
// }

// .c-scroll::-webkit-scrollbar-thumb
// {
//     width: 5px;
// 	background-color: $light-green;
//     border-radius: 5px;
// }
// .c-scroll::-webkit-resizer{
//     width: 2px;

// }
// ::-webkit-scrollbar,
// ::-webkit-scrollbar-thumb,
// ::-webkit-scrollbar-track {
//     width: 4px;
//     border: none;
//     background: transparent;
// }

// ::-webkit-scrollbar-button,
// ::-webkit-scrollbar-track-piece,
// ::-webkit-scrollbar-corner,
// ::-webkit-resizer {
//     display: none;
// }

// ::-webkit-scrollbar-thumb {
//     border-radius: 6px;
//     background-color: $light-green;
// }

// ::-webkit-scrollbar-track {
//     // background-image: url("https://i.imgur.com/GvV1R30.png");
//     // background-repeat: repeat-y;
//     // background-size: contain;
//     display: inline-block;
//     content: "";

// }

.mailbox {
  ::-webkit-scrollbar {
    width: 20px;
    border-width: 5px;
    background-color: $light-gray;
  }

  ::-webkit-scrollbar-track-piece {
    // background-color: $text-color;
    border-color: $text-color !important;
    border-width: 1px 9px 1px 9px;
    border-style: solid;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $light-green;
    border-color: $text-color !important;
    border-style: solid;
    border-width: 1px 7px 1px 7px;
  }
}

.terms-agreement {
  ::-webkit-scrollbar {
    width: 20px;
    border-width: 5px;
    background-color: $light-gray;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  }

  ::-webkit-scrollbar-track-piece {
    // background-color: $text-color;
    border-color: $input-bg !important;
    border-width: 1px 9px 1px 9px;
    border-style: solid;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  }

  ::-webkit-scrollbar-thumb {
    background-color: $light-green;
    border-color: $input-bg !important;
    border-style: solid;
    border-width: 1px 7px 1px 7px;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  }
}

::-webkit-scrollbar {
  width: 20px;
  border-width: 5px;
  background-color: $light-gray;
}

::-webkit-scrollbar-track-piece {
  // background-color: $text-color;
  border-color: $white;
  border-width: 1px 9px 1px 9px;
  border-style: solid;
}

::-webkit-scrollbar-thumb {
  background-color: $light-green;
  border-color: $white;
  border-style: solid;
  border-width: 1px 7px 1px 7px;
}

.teeth-devider {
  position: absolute;
  height: 100%;
  border-left: 1px solid red;
  transform: translatex(150px);
}
.dir {
  font-size: 14px;
  // margin-top: 4px;
  font-weight: 800;
}
.headers-icons {
  // padding: 8px;
  // background: $white;
  border-radius: 50%;
  cursor: pointer;
}
.notification-num {
  height: 20px;
  width: 20px;
  background: $error;
  border-radius: 50px;
  position: absolute;
  right: -10px;
  top: 12px;
  color: $white;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  @media (max-width: 839px) {
    top: -4px;
  }
}
.tsInbox-num {
  height: 20px;
  width: 20px;
  background: $error;
  border-radius: 50px;
  position: absolute;
  right: -10px;
  top: -5px;
  color: $white;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
  right: -150px !important;
  background: $text-color;
  color: $white !important;
  padding: 24px;
  border-radius: 12px;
  @media (max-width: 839px) {
    left: 0;
  }
}

.notification-triangle {
  position: absolute;
  cursor: pointer;
  width: 0;
  height: 0;
  left: 45.5%;
  top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $text-color;
  @media (max-width: 839px) {
    left: 81%;
  }
}
.white-text {
  color: $white !important;
  font-size: 16px;
  width: fit-content;
}

.notification-card {
  background: $white;
  padding: 8px 16px;
  display: grid;
  grid-row-gap: 8px;
  border-radius: 12px;
  color: $dark-text;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 16px;
}
.notification-card2 {
  background: linear-gradient(
      268.82deg,
      #f7f8fa 24.83%,
      rgba(255, 255, 255, 0) 87.86%
    ),
    #ffffff;
  padding: 8px 16px;
  display: grid;
  grid-row-gap: 8px;
  border-radius: 12px;
  color: $dark-text;
  font-size: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
  cursor: pointer;
}
.mailbox {
  min-width: 350px !important;
  @media (max-width: 839px) {
  }
}
.notification-card-title {
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  grid-column-gap: 4px;
  align-items: end;
}
.dot {
  font-size: 24px;
  line-height: 0px;
  height: 12px;
  align-self: center;
}
.notification-status {
  color: $light-green;
}
.notification-status-gray {
  color: $light-gray;
}
.doctor-name {
  color: $dark-text;
  font-size: 16px;
  padding-left: 8px;
}
.back {
  cursor: pointer;
  width: fit-content;
  margin-bottom: 16px;
}
.circle {
  border-radius: 50%;
  background: transparent;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $light-green;
}
.circle-gray {
  border-radius: 50%;
  background: transparent;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $light-gray;
}
.inner-circle {
  width: 6px;
  height: 6px;
  background: $white;
  border-radius: 50%;
}
.notifications-num {
  color: $light-green;
}
.see-all {
  border-bottom: 1px solid $white;
  width: fit-content;
  cursor: pointer;
}

.c-modal {
  top: 20%;
  .modal-header {
    border: none;
    padding-bottom: 8px;
  }
  color: $label;
  font-size: 14px;
}
.t-modal {
  top: 20%;
  @media (max-width: 839px) {
    top: 5%;
  }
  .modal-header {
    border: none;
    padding-bottom: 8px;
  }
  color: $label;
  font-size: 14px;
}
.Toastify__toast-container {
  @include toster-postion;
}
.Toastify__toast {
  @include toster-style;
}
.confarim_preferences_modal {
  max-width: 587px !important;
  top: 20%;
  @media (max-width: 839px) {
    max-width: 95% !important;
    top: 25%;
    //max-width: 95% !important;
    @include modal-postion;

    display: block;
    margin: auto;
  }
  .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbars(5px, #08c7b2, rgba(100, 100, 100, 0));
    @media (max-width: 839px) {
      //padding-bottom: 180px;
      //position: relative;
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 0px 16px;
    }
  }
  .modal-content {
    width: 587px;
    height: 208px;
    display: flex;
    align-self: center;
    border-radius: 12px !important;

    font-size: 22px;
    font-family: Poppines;
    font-weight: 400px;
    line-height: 28px;
    color: #515151;
    @media (max-width: 839px) {
      @include modal-style;
    }
  }
  .modal-footer {
    border: none;
    padding-bottom: 2rem !important;
    @media (max-width: 839px) {
      position: sticky;
      background-color: #ffffff;
      bottom: 0%;
      width: 100%;
      left: 0%;
      right: 0%;
      justify-content: center;
    }
  }
}
.confarim-modal {
  .modal-body {
    padding-top: 32px !important;
  }
  label {
    @media (max-width: 839px) {
      font-size: 16px;
    }
  }
  @media (max-width: 839px) {
    max-width: 587px !important;
    @include modal-postion;
    display: block;
    margin: auto;

    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbars(5px, #08c7b2, rgba(100, 100, 100, 0));

      //padding-bottom: 180px;
      //position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 16px !important;
    }

    .modal-content {
      display: flex;
      align-self: center;

      font-size: 22px;
      font-family: Poppines;
      font-weight: 400px;
      line-height: 28px;
      color: #515151;

      @include modal-style;
    }
    .modal-footer {
      border: none;
      padding-bottom: 2rem !important;

      position: sticky;
      background-color: #ffffff;
      bottom: 0%;
      width: 100%;
      left: 0%;
      right: 0%;
      justify-content: center;
    }
  }
}
.confarim-modal-with-header {
  .modal-body {
    padding-top: 0 !important;
  }
  .modal-header {
    padding: 24px 32px 0 !important;
    @media (max-width: 839px) {
      padding: 16px 16px 0 !important;
    }
  }
  label {
    @media (max-width: 839px) {
      font-size: 16px;
    }
  }
  @media (max-width: 839px) {
    max-width: 587px !important;
    @include modal-postion;
    display: block;
    margin: auto;

    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbars(5px, #08c7b2, rgba(100, 100, 100, 0));

      //padding-bottom: 180px;
      //position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 16px;
    }

    .modal-content {
      display: flex;
      align-self: center;

      font-size: 22px;
      font-family: Poppines;
      font-weight: 400px;
      line-height: 28px;
      color: #515151;

      @include modal-style;
    }
    .modal-footer {
      border: none;
      padding-bottom: 2rem !important;

      position: sticky;
      background-color: #ffffff;
      bottom: 0%;
      width: 100%;
      left: 0%;
      right: 0%;
      justify-content: center;
    }
  }
}
.fix-modal {
  @media (max-width: 839px) {
    max-width: 587px !important;
    @include modal-postion;
    display: block;
    margin: auto;
    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbars(5px, #08c7b2, rgba(100, 100, 100, 0));
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 0px 16px;
    }
    .modal-content {
      display: flex;
      align-self: center;
      font-size: 22px;
      font-family: Poppines;
      font-weight: 400px;
      line-height: 28px;
      height: 90vh !important;
      color: #515151;
      @include modal-style;
    }
    .modal-footer {
      border: none;
      padding-bottom: 2rem !important;
      position: sticky;
      background-color: #ffffff;
      bottom: 0%;
      width: 100%;
      left: 0%;
      right: 0%;
      justify-content: center;
    }
  }
}
.fix-modal3 {
  @media (max-width: 839px) {
    max-width: 587px !important;
    @include modal-postion;
    display: block;
    margin: auto;
    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbars(5px, #08c7b2, rgba(100, 100, 100, 0));
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 0px 16px;
    }
    .modal-content {
      display: flex;
      align-self: center;
      height: 90vh !important;
      color: #515151;
      @include modal-style;
    }
    .modal-footer {
      border: none;
      padding-bottom: 2rem !important;
      position: sticky;
      background-color: #ffffff;
      bottom: 0%;
      width: 100%;
      left: 0%;
      right: 0%;
      justify-content: center;
    }
  }
}
.fix-modal2 {
  @media (max-width: 839px) {
    .modal-body {
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbars(5px, #08c7b2, rgba(100, 100, 100, 0));
      overflow-y: hidden;
      overflow-x: hidden;
      padding: 0px 16px;
      height: 100vh !important;
    }
    .modal-content {
      border: none;
      height: 100vh !important;
      @media (max-width: 839px) {
        display: block;
        margin: auto;
      }
    }
    .modal-footer {
      display: none;
    }
  }
}
.replacements-modal {
  max-width: 600px !important;
  display: block;
  margin: auto;
  @media (max-width: 839px) {
    max-width: 95% !important;
    top: 5%;
    display: block;
    margin: auto;
  }
}
.onboarding-modal {
  top: 0%;
  max-width: 900px !important;
  @media (max-width: 839px) {
    max-width: 95% !important;
    top: 5%;
    display: block;
    margin: auto;
  }
  .preferance-card {
    max-height: unset;
  }
  .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbars(5px, #08c7b2, rgba(100, 100, 100, 0));
    @media (max-width: 839px) {
      //padding-bottom: 180px;
      //position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 1rem;
    }
  }
  .modal-content {
    width: 900px;
    min-height: 575px;
    // max-height: calc(100vh - 300px);
    display: flex;
    align-self: center;
    border-radius: 12px !important;
    // overflow: auto;
    @media (max-width: 839px) {
      width: 95%;
      min-height: unset;
      height: 75%;
      margin: 0 auto;
      overflow-y: auto;
    }
  }
  .modal-footer {
    border: none;
    @media (max-width: 839px) {
      // position: sticky;
      background-color: #ffffff;
      bottom: 0%;
      width: 100%;
      left: 0%;
      right: 0%;
      padding: 16px 0px;

      justify-content: center;
    }
  }
}
.arrow {
  position: absolute;
  height: 15px;
  right: 16px;
  top: 16px;
  width: 15px;
  cursor: pointer;
  @media (max-width: 839px) {
    right: 0px;
  }
}
.arrow::before,
.arrow::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0.15rem;
  height: 100%;
  transition: all 0.5s;
}
.account_preferences_title {
  color: #2e2e2f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.clinical_preferences_title {
  color: #2e2e2f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.clinical_preferences_sub_title {
  color: #2e2e2f;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.arrow::before {
  left: -5px;
  transform: rotate(-45deg);
  background-color: #08c7b2;
  width: 3px;
}

.arrow::after {
  left: 5px;
  transform: rotate(45deg);
  background-color: #08c7b2;
  width: 3px;
}

.open .arrow::before {
  left: -5px;
  transform: rotate(45deg);
}

.open .arrow::after {
  left: 5px;
  transform: rotate(-45deg);
}
.c-modal-onboarding {
  top: 20%;
  width: auto;
  .modal-header {
    border: none;
    padding-bottom: 8px;
  }
  color: $label;
  font-size: 14px;

  @media (max-width: 839px) {
    width: 95%;
    max-width: 95%;
  }
}
.c-modal-approve-ts {
  top: 5%;
  width: auto;
  .modal-header {
    border: none;
    padding-bottom: 8px;
  }
  color: $label;
  font-size: 14px;

  @media (max-width: 839px) {
    width: 95%;
    max-width: 95%;
  }
}
.modal-content {
  border-radius: 12px;
  @media (max-width: 839px) {
    border-radius: 0 !important;
  }
}

.cancel-mobile .modal-content {
  border-radius: 12px;
  @media (max-width: 839px) {
    border-radius: 12px !important;
  }
}

.new-modal-content {
  @media (max-width: 839px) {
    max-height: calc(100vh - 250px);
    position: relative;
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.dashboard-container {
  background: $bg-gray;
  padding: 6px 120px 0px 120px;
  border-radius: 24px 24px 0px 0px;
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  place-items: center;
  grid-column-gap: 8px;
  position: relative;
  @media (max-width: 839px) {
    padding: 30px 20px 10px 20px;
    grid-template-columns: 1fr;
    // grid-row-gap: 24px;
  }
}

.dashboard-actions-grid {
  display: grid;
  padding: 16px 0px;
  @media (max-width: 839px) {
    grid-row: 5;
  }
}
.loyalty-grid {
  display: grid;
  padding-top: 24px;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: 51px;
  text-align: center;
  //margin-left: 72px;
  @include caption-1-bold;
  line-height: 40px !important;
  @media (max-width: 1230px) {
    line-height: 30px !important;
  }
  @media (max-width: 1110px) {
    line-height: 25px !important;
    justify-content: space-between;
  }
  @media (max-width: 939px) {
    line-height: 20px !important;
  }
  @media (max-width: 839px) {
    grid-template-columns: repeat(6, auto);
    font-size: 7px !important;
    line-height: 25px !important;
    padding-top: 0px;
    margin: 0 auto;
    padding-left: unset;
    justify-items: center;
    justify-content: center;
  }
}
.loyalty-start {
  min-width: 40px;
  max-width: 152px;
  width: auto;
  margin-right: 28px;
  @media (max-width: 1230px) {
    width: 120px;
    margin-left: unset;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    width: 100px;
  }
  @media (max-width: 939px) {
    width: 80px;
  }
  @media (max-width: 839px) {
    width: 55px;
    margin-right: -2px;
  }
}
.loyalty-end {
  min-width: 40px;
  max-width: 152px;
  width: auto;
  margin-left: -92px;
  @media (max-width: 1230px) {
    width: 120px;
    margin-left: unset;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    width: 100px;
  }
  @media (max-width: 939px) {
    width: 80px;
  }
  @media (max-width: 839px) {
    width: 45px;
    margin-left: -8px;
  }
}
.loyalty-mid {
  margin-left: -82px;
  min-width: 40px;
  max-width: 152px;
  width: auto;
  @media (max-width: 1230px) {
    width: 120px;
    margin-left: unset;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    width: 100px;
  }
  @media (max-width: 939px) {
    width: 80px;
  }
  @media (max-width: 839px) {
    width: 52px;
    margin-left: -2px;
  }
}
.loyalty-bronze {
  background-image: url("../../../images/loyalty/loyalty-bronze.svg");
  color: #a47202;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-bronze-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 1230px) {
    margin-left: unset;
    margin-right: -100px;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-bronze-mobile.svg");
    color: #a47202;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-bronze-mobile-active.svg");
    }
  }
}
.loyalty-bronze-active {
  background-image: url("../../../images/loyalty/loyalty-bronze-active.svg");
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-bronze-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 1230px) {
    margin-left: unset;
    margin-right: -100px;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-bronze-mobile-active.svg");
    color: #a47202;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-bronze-mobile-active.svg");
    }
  }
}
.loyalty-silver {
  background-image: url("../../../images/loyalty/loyalty-mid.svg");
  color: #979797;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-silver-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 1230px) {
    margin-left: -40px;
    margin-right: unset;
  }
  @media (max-width: 939px) {
    margin-left: -60px;
    margin-right: unset;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-mid-mobile.svg");
    color: #979797;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: unset;
    margin-right: unset;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-silver-mobile-active.svg");
    }
  }
}
.loyalty-silver-active {
  background-image: url("../../../images/loyalty/loyalty-silver-active.svg");
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-silver-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 1230px) {
    margin-left: -40px;
    margin-right: unset;
  }
  @media (max-width: 939px) {
    margin-left: -60px;
    margin-right: unset;
  }

  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-silver-mobile-active.svg");
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: unset;
    margin-right: unset;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-silver-mobile-active.svg");
    }
  }
}
.loyalty-gold {
  background-image: url("../../../images/loyalty/loyalty-mid.svg");
  color: #ffb818;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: -94px;
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-gold-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 1230px) {
    margin-left: -140px;
    margin-right: unset;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-mid-mobile.svg");
    color: #ffb818;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-gold-mobile-active.svg");
    }
  }
}
.loyalty-gold-active {
  background-image: url("../../../images/loyalty/loyalty-gold-active.svg");
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: -94px;
  @media (max-width: 1230px) {
    margin-left: -140px;
    margin-right: unset;
  }
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-gold-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-mid-mobile.svg");
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-gold-mobile-active.svg");
    }
  }
}
.loyalty-platinum {
  background-image: url("../../../images/loyalty/loyalty-mid.svg");
  color: #4f5665;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: -104px;
  @media (max-width: 1230px) {
    margin-left: -160px;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    margin-left: -180px;
    margin-right: unset;
  }
  @media (max-width: 939px) {
    margin-left: -200px;
    margin-right: unset;
  }
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-platinum-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-mid-mobile.svg");
    color: #4f5665;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    padding-inline-start: 7px;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-platinum-mobile-active.svg");
    }
  }
}
.loyalty-platinum-active {
  background-image: url("../../../images/loyalty/loyalty-platinum-active.svg");
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: -104px;
  @media (max-width: 1230px) {
    margin-left: -160px;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    margin-left: -180px;
    margin-right: unset;
  }
  @media (max-width: 939px) {
    margin-left: -200px;
    margin-right: unset;
  }
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-platinum-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-platinum-mobile-active.svg");
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    padding-inline-start: 7px;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-platinum-mobile-active.svg");
    }
  }
}
.loyalty-diamond {
  background-image: url("../../../images/loyalty/loyalty-mid.svg");
  color: #74b1be;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: -112px;
  @media (max-width: 1230px) {
    margin-left: -180px;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    margin-left: -220px;
    margin-right: unset;
  }
  @media (max-width: 939px) {
    margin-left: -260px;
    margin-right: unset;
  }
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-diamond-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-mid-mobile.svg");
    color: #74b1be;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    padding-inline-start: 7px;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-diamond-mobile-active.svg");
    }
  }
}
.loyalty-diamond-active {
  background-image: url("../../../images/loyalty/loyalty-diamond-active.svg");
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: -112px;
  @media (max-width: 1230px) {
    margin-left: -180px;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    margin-left: -220px;
    margin-right: unset;
  }
  @media (max-width: 939px) {
    margin-left: -260px;
    margin-right: unset;
  }
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-diamond-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-mid-mobile.svg");
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    padding-inline-start: 7px;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-diamond-mobile-active.svg");
    }
  }
}
.loyalty-elite {
  background-image: url("../../../images/loyalty/loyalty-elite.svg");
  color: #00c7b1;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: -122px;
  @media (max-width: 1230px) {
    margin-left: -200px;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    margin-left: -260px;
    margin-right: unset;
  }
  @media (max-width: 939px) {
    margin-left: -320px;
    margin-right: unset;
  }
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-elite-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-elite-mobile.svg");
    color: #00c7b1;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: unset;
    margin-right: unset;
    cursor: pointer;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-elite-mobile-active.svg");
    }
  }
}
.loyalty-elite-active {
  background-image: url("../../../images/loyalty/loyalty-elite-active.svg");
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-left: -122px;
  @media (max-width: 1230px) {
    margin-left: -200px;
    margin-right: unset;
  }
  @media (max-width: 1110px) {
    margin-left: -260px;
    margin-right: unset;
  }
  @media (max-width: 939px) {
    margin-left: -320px;
    margin-right: unset;
  }
  &:hover {
    background-image: url("../../../images/loyalty/loyalty-elite-active.svg");
    opacity: 0.5 !important;
    color: #ffffff;
  }
  @media (max-width: 839px) {
    background-image: url("../../../images/loyalty/loyalty-elite-mobile.svg");
    color: #a47202;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-left: unset;
    margin-right: unset;
    &:hover {
      background-image: url("../../../images/loyalty/loyalty-elite-mobile-active.svg");
    }
  }
}

.bttom-border {
  border-top: 1px #dee2e6 solid;
  position: absolute;
  left: 17%;
  width: 73%;
  @media (max-width: 839px) {
    display: none;
  }
}
.loyalty-container {
  background: $bg-gray;
  padding: 16px 0px 20px 15%;
  border-radius: 0px 0px 24px 24px;
  @media (max-width: 839px) {
    padding: 0px 16px 56px 16px;
  }
}
.loyalty-header {
  font-family: Poppins !important;
  font-size: 18px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  //padding-inline-start: 78px;
  @media (max-width: 839px) {
    padding-inline-start: unset;
    font-family: Poppins !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
    padding-bottom: 8px;
    padding-left: 4px;
  }
}
.body-text {
  @include body;
}
.body-bold-text {
  @include body-bold;
}
.large-title-text {
  @include large-title;
}
.title-1-text {
  @include title-1;
}
.title-2-text {
  @include title-2;
}
.title-3-text {
  @include title-3;
}
.title-3-text-bold {
  @include title-3-bold;
}
.button-text {
  @include button;
}

.button-seme-bold-text {
  @include seme-bold-button;
}
.button-small-bold-text {
  @include small-bold-button;
}
.button-bold-text {
  @include button-bold;
}

.headline-text {
  @include headline;
}
.headline-bold-text {
  @include headline-bold;
}
.footnote-text {
  @include footnote;
}
.footnote-bold-text {
  @include footnote-bold;
}
.callout-text {
  @include callout;
}
.callout-bold-text {
  @include callout-bold;
}
.subhead-text {
  @include subhead;
}
.subhead-bold-text {
  @include subhead-bold;
}
.label-1-text {
  @include label-1;
}
.label-1-text-bold {
  @include label-1-bold;
}

.seme-text {
  @include caption-2-italic;
}
.body-italic {
  @include body-italic;
}

.caption-1-text {
  @include caption-1;
}
.caption-2-text {
  @include caption-2;
}

.caption-1-bold-text {
  @include caption-1-bold;
}
.footnote-text-select {
  .custom-control-label {
    @include footnote;
  }
}
.button-text-select {
  .custom-control-label {
    @include button;
    width: 100%;
  }
}
.border-right {
  height: 100%;
  border-left: 1px solid #eeeff2;
  @media (max-width: 839px) {
    border-bottom: 2px solid #eeeff2 !important;
    width: 100%;
  }
}
.dashboard-filters {
  display: grid;
  grid-row-gap: 32px;
  align-self: flex-start;
  @media (max-width: 839px) {
    grid-row: 3;
  }
}
.filter-circle {
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  color: $white;
  font-weight: bold;
}
.carousel-indicators .active {
  background-color: #75e6d9 !important;
  border-radius: 50%;
  height: 7px;
  width: 7px;
}
.carousel-indicators li {
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #c4c4c4;
}

.navbar-header-light {
  padding: 0 10px !important;
  background: $light-green;
}
// .navbar-header{
//   @media (max-width: 839px) {
//     //line-height: unset;
//   }
// }
.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.carousel-inner {
  background: none !important;
}
.c-banner {
  border-radius: 24px;
  background: $bg-gray !important;
}
.navbar-title {
  font-size: 14px;
  width: 100%;
  //  padding-left: 24px;
  @media (max-width: 839px) {
    display: none;
  }
}
.banner {
  @media (max-width: 839px) {
    grid-row: 1;
  }
}

.tooth-extraction {
  position: relative;
  padding: 24px;
  overflow: hidden;
  // opacity: 0.5;
  @media (max-width: 839px) {
    // padding: 0px;
  }
}
.Eon_to_recommend_no {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: fill;
  right: 0%;
  padding: 24px;
  display: grid;
  place-items: center;
  top: 0%;
  z-index: 1;
  background-color: white;
  @media (max-width: 839px) {
    display: block;
    text-align: center;
    font-weight: bold;
  }
}

.Eon_to_recommend_eon_yes {
  display: none;
}

.Eon_to_recommend_eon {
  position: absolute;
  width: 100%;
  overflow: hidden;

  height: 100%;
  object-fit: fill;
  right: 0%;
  display: grid;
  place-items: center;
  padding: 24px;
  color: black;
  top: 0%;
  bottom: 0%;
  z-index: 1;
  background: white;
  opacity: 0.8;
  @media (max-width: 839px) {
    display: block;
    text-align: center;
    font-weight: bold;
    // place-items: start;
  }
}
.Eon_to_recommend_text_eon {
  text-align: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  z-index: 99;
}
.Eon_to_recommend_text_no {
  text-align: center;
  justify-content: center;
  color: black;
  z-index: 99;
}
.Eon_to_recommend_text_eon_none {
  display: none;
}
.Eon_to_recommend_text_no_none {
  display: none;
}
.divider_module-middle {
  background: linear-gradient(
      270deg,
      rgba(227, 227, 227, 0.5) 0%,
      rgba(255, 255, 255, 0) 48.28%
    ),
    #ffffff;
  border-radius: 16px;
  width: 25px;
  height: 100%;
  @media (max-width: 839px) {
    margin-top: 16px;
    background: linear-gradient(
        20deg,
        rgba(227, 227, 227, 0.5) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #ffffff;
    border-radius: 16px;
    width: 100%;
    height: 15px;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    display: none;
  }
}
.teeth_selector_extraction {
  max-width: 100%;
  margin: 0 auto;
}

.light-green {
  color: $light-green;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.border-radius {
  border-radius: 8px;
}

.container-button {
  border-top: 1px solid #c8c8ca;
  z-index: 1;
  @media (max-width: 839px) {
    border-top: none;
  }
}

.text_malocclusions {
  font-family: Poppins;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  @media (max-width: 839px) {
    line-height: 22px;
  }
}

.title_font_malocclusions {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}
.Eon_to_recommend_layout {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  // position: fixed;
  line-height: 24px;
  color: #2e2e2f;
}

.backgrountTextarea {
  background: #f5f5f5 !important;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
  width: 70%;
  border: none;
  align-items: left !important;
  @media (max-width: 839px) {
    width: 100%;
  }
}

.TreatmentProtocols-textarea {
  width: 70%;
  background: #f5f5f5;
  margin-top: 32px;
  padding: 8px;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  align-items: left;
  @media (max-width: 839px) {
    width: 100%;
    margin-top: 16px;
  }
}
.Malocclusion-content {
  padding: 24px;
  padding-top: 0px;
  @media (max-width: 839px) {
    padding: 0px;
    padding-top: 30px;
  }
}
.title-padding {
  padding-top: 24px;
}
.buttom-paddingFORMalc {
  padding: 0.5rem 0px 2.5rem !important;
}
.ToothRateMovement-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  border-bottom: 1px solid #c8c8ca;
  padding-bottom: 32px;
  @media (max-width: 839px) {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.ToothRateMovement-grid-ts {
  .ToothRateMovement-grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
    border-bottom: none;
    padding-bottom: 32px;
  }
}

.backgwhite {
  background: white;
}
.rounded-circle {
  position: relative;
}

.padTOP {
  padding-top: 0.5rem;
  padding-left: 8px;
  @media (max-width: 839px) {
    padding-left: 0px;
  }
}
.padLEFT {
  padding-left: 8px;
}

.Malocc-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  color: #00c7b1;
}
.Malocc-title-spaces {
  padding-top: 32px;
  padding-left: 32px;

  @media (max-width: 839px) {
    padding-left: 1rem;
  }
}
.preferance-details-bottom-border {
  border-bottom: 1px solid #c8c8ca;
  max-width: 930px;
  //height: 1px;
}
.custom-options-title {
  font-size: 14px;
  line-height: 20px;
  color: $label;
}

.custom-title {
  font-size: 14px;
  line-height: 20px;
  color: $light-green;
  font-weight: 600;
  margin-bottom: 16px;
}

.beta {
  color: $text-color;
  border: 2px solid $light-green;
  border-radius: 12px;
  font-size: 12px;
  padding: 0 8px;
  margin-left: 4px;
}
.onboarding_preferance {
  overflow: hidden !important;
  max-height: unset !important;
}
.preferance-card {
  max-height: calc(100vh - 250px);
  overflow: auto;
  @media (max-width: 839px) {
    max-height: unset;
  }
}
.custom-dark-title {
  font-size: 14px;
  font-family: Poppins;
  line-height: 20px;
  color: $label;
  font-weight: 400;
  margin-bottom: 16px;
}
.add-step {
  font-size: 14px;
  line-height: 20px;
  color: $light-green;
  font-weight: 600;
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  &:disabled {
    color: $light-gray;
  }
}
.disabled-add-step {
  color: $light-gray;
  pointer-events: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 16px;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
}
.step-number::-webkit-outer-spin-button,
.step-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.step-number[type="number"] {
  -moz-appearance: textfield;
  max-width: 25px;
  height: 25px;
  color: $light-green;
  text-align: center;
  &:disabled {
    color: $light-gray;
  }
  &:focus {
    background-image: linear-gradient($light-green, $light-green),
      linear-gradient(#dee2e6, #dee2e6) !important;
  }
}
.remove-step {
  cursor: pointer;
}
.save-preferance-btn {
  margin-top: -24px;
  @media (max-width: 839px) {
    margin-top: 16px;
  }
}
.preferance-btn-height {
  height: 42px;
  width: 192px;
  @media (max-width: 839px) {
    height: 40px;
    width: 100%;
    justify-self: center;
    align-self: center;
    margin-bottom: 16px;
  }
}
.address-modal {
  top: 5%;
  width: 50%;
  max-width: 50%;
  @media (max-width: 839px) {
    // height: 60%;
    top: 5%;
    width: 95%;
    max-width: 95%;
  }
  .modal-content {
    overflow: hidden;
  }
}
.icon-button {
  font-size: 14px;
  padding-left: 4px;
}

.address-title {
  font-size: 14px;
  line-height: 20px;
  color: $light-green;
  font-weight: 600;
  margin-bottom: 8px;
}
.form-title {
  font-size: 14px;
  line-height: 20px;
  color: $light-green;
  font-weight: 600;
  margin-bottom: 8px;
}

.edit-address {
  cursor: pointer;
  width: fit-content;
}

.delete-address {
  color: $error;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
  margin-top: 16px;
}
.avatar {
  height: 86px;
  width: 86px;
  border-radius: 50%;
}
.c-pointer {
  cursor: pointer;
  width: fit-content;
}
.justify-self-end {
  justify-self: end;
}
.setting-text {
  color: $light-green;
  font-size: 14px;
  line-height: 16px;
}
#svgClass {
  visibility: hidden;
}
.optional {
  font-size: 12px;
  color: $text-color;
}
.add-new-address {
  font-size: 12px;
  color: $light-green;
  text-decoration: underline;
  cursor: pointer;
}
.css-16pqwjk-indicatorContainer {
  color: $light-green !important;
}
.submission-btn {
  width: 160px !important;
  @media (max-width: 839px) {
    width: 100% !important;
  }
}
.cancel-submission-btn {
  width: fit-content;
  @media (max-width: 839px) {
    width: 100%;
    font-size: 14px !important;
  }
}

.term-button .submission-btn {
  width: 160px !important;
  @media (max-width: 839px) {
    width: 100% !important;
  }
}

.patient-info-container {
  height: calc(100vh - 270px);
  // max-height: calc(100vh - 270px);
  margin: 32px 0 0;
  overflow: auto;

  overflow-x: clip;
  @media (max-width: 839px) {
    margin: 24px 0 0;
    overflow-x: visible;
    overflow: unset;
    height: auto;
    position: relative;
    // max-height: auto;
  }
}

.patient-info-container-patient {
  height: calc(100vh - 270px);
  max-height: calc(100vh - 280px);
  margin: 32px 0 0;
  // overflow: auto;
  @media (max-width: 839px) {
    height: calc(100vh - 390px);
    max-height: calc(100vh - 390px);
  }
}
.case-info-container {
  height: calc(100vh - 250px);
  margin: 32px 0 0;
  overflow: auto;
  margin: 32px 0 0;

  @media (max-width: 839px) {
    height: auto;
    overflow: unset;
  }
}
.case-info-container-ts {
  // height: calc(100vh - 180px);
  // overflow: auto;
}
.tabs-content-height {
  height: calc(100vh - 140px) !important;
  overflow: auto;
}
.c-content-container {
  height: 56vh;
  max-height: 56vh;
  padding: 0 16px;
  overflow: auto;

  @media (max-width: 839px) {
    height: 50vh;
    max-height: 50vh;
    overflow: auto;
    // padding: 0 20px;
  }
}

.c-content-container-pontics {
  height: 56vh;
  max-height: 56vh;
  // padding: 0 16px;
  overflow: initial;

  @media (max-width: 839px) {
    height: 50vh;
    max-height: 50vh;
    overflow: auto;
  }
}
.c-content-container5 {
  height: 56vh;
  max-height: 56vh;

  overflow: auto;

  @media (max-width: 839px) {
    height: 50vh;
    max-height: 50vh;
  }
}
.c-content-container4 {
  height: 56vh;
  max-height: 56vh;
  overflow: auto;

  @media (max-width: 839px) {
    height: 60vh;
    max-height: 60vh;
  }
}
.c-content-container1 {
  height: 56vh;
  max-height: 56vh;
  // padding: 0 16px;
  overflow: hidden;
  @media (max-width: 839px) {
    height: auto;
    max-height: 50vh;
  }
}

.c-content-container6 {
  height: 56vh;
  max-height: 56vh;

  overflow: auto;

  @media (max-width: 839px) {
    height: 40vh;
    max-height: 40vh;
  }
}
.c-content-container7 {
  height: 56vh;
  max-height: 56vh;

  overflow: hidden;

  @media (max-width: 839px) {
    height: 44vh;
    max-height: 44vh;
    overflow: hidden;
  }
}
.c-content-container8 {
  height: 56vh;
  max-height: 56vh;

  overflow: auto;

  @media (max-width: 839px) {
    height: 37vh;
    max-height: 37vh;
    overflow: hidden;
  }
}

.cut-padding {
  padding-top: 24px;
  padding-bottom: 16px;
}
.c-card-with-img {
  color: $text-color;
  font-size: 14px;
  border-radius: 12px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border: 3px solid $white;
  display: grid;
  align-items: center;
  max-width: 200px;
  text-align: start;

  .c-card-img {
    border-radius: 8px;
  }

  &:hover {
    border: 3px solid $text-color;
  }
  @media (max-width: 839px) {
    margin: 0 auto;
    max-width: 250px;
    max-height: 250px;
    height: 100%;
  }
}
.c-card-with-img-active {
  color: $light-green;
  font-size: 14px;
  border-radius: 12px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border: 3px solid $light-green;
  display: grid;
  align-items: center;
  max-width: 200px;
  text-align: start;

  .c-card-img {
    border-radius: 8px;
  }

  @media (max-width: 839px) {
    margin: 0 auto;
  }
}
.c-card {
  color: $light-green;
  display: flex;
  flex-direction: column;
}
.c-dragover {
  border: 3px dashed $light-green;
}
.c-divider {
  height: 100%;
  background: linear-gradient(
      270deg,
      rgba(227, 227, 227, 0.5) 0%,
      rgba(255, 255, 255, 0) 48.28%
    ),
    #ffffff;
  border-radius: 16px;
  @media (max-width: 839px) {
    height: 20px;
    background: linear-gradient(
        180deg,
        rgba(227, 227, 227, 0.5) 0%,
        rgba(255, 255, 255, 0) 48.28%
      ),
      #ffffff;
    display: none;
  }
}

.terms-info {
  height: 56vh;
  max-height: 56vh;
  // padding: 32px 16px 0;
  overflow: auto;
  margin-top: 8px;
  @media (max-width: 839px) {
    height: calc(100vh - 460px);
    max-height: calc(100vh - 460px);
  }
  @media (min-width: 501px) and (max-width: 839px) and (orientation: landscape) {
    height: 100vh;
    max-height: 100vh;
  }
}
.templete_module_grid_hybrid {
  grid-template-rows: auto 200px auto;
}
.c-card-img-fix-xray {
  border-radius: 8px;
  width: 100px !important;
}
.patient-info-header {
  position: relative;
}
.detailed_case_submission {
  color: $text-color;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.modules-modal-summary {
  height: 65vh;
  overflow: auto;
  @media (max-width: 839px) {
    height: 85vh;
  }
  .header_title {
    font-size: 16px;
  }
  .header_title2 {
    font-size: 18px;
  }
  .Malocc-title-spaces {
    padding-left: 0;
  }
  .templete_module_grid_maloccluison {
    grid-template-columns: 1fr;
    border: none;

    .divider_module-middle {
      display: none;
    }
  }
  .c-content-container {
    height: 100%;
    max-height: 100%;
  }
  .add-step {
    display: none;
  }
  .form-material {
    pointer-events: none;
    cursor: default;
    @media (max-width: 839px) {
      overflow: auto;
    }
  }
  .divider_module-middle {
    display: none;
    @media (max-width: 839px) {
      display: block;
    }
  }
  .container-button {
    border: none;
  }
  .templete_module_grid {
    margin-bottom: 24px;
    grid-template-columns: 1.2fr 3fr 20px;
    grid-column-gap: 16px;
    border: none;
    @media (max-width: 839px) {
      grid-template-columns: 1fr;
    }
  }
}
.case-submission-wrapper {
  padding: 32px 0px 0 0px !important;
  background: $white;
}
.step-img {
  float: right;
  //height: 100%;
}
.hamburger-mobile {
  display: none;
  @media (max-width: 839px) {
    display: block;
    display: none;
  }
}

.submit_case_btn_container {
  position: relative;
}
.submit_case_btn {
  position: absolute;
  right: 0;
  bottom: -15px;
  font-size: 14px;
  text-align: end;
  display: grid;
  grid-template-columns: 1fr;
  color: #00c7b1;
  font-weight: 600;
}

.TosterCard {
  background-color: #898a8d;
  min-height: 65px;
  display: grid;
  grid-template-columns: auto 1fr;
  border-radius: 6px;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 10px;
}
.Toastify__toast--error {
  background-color: #898a8d !important;
}
.Toastify__toast--success {
  background-color: #898a8d !important;
}
.Toastify__toast--info {
  background-color: #898a8d !important;
}
.TosterCard-image {
  display: grid;
  padding: 12px 10px;
  place-items: center;
}
.Toastify__toast {
  // width: 418px;
  height: 70px;
  border-radius: 6px !important;
  width: 95%;
}
.TosterCard-title {
  /* White */
  color: #ffffff;
}
.Toster-title {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
}

.Toster-desc {
  font-family: Poppins;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.ReactTable {
  border: none;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  border: none;
  .rt-thead .rt-th.-sort-desc {
    box-shadow: none !important;
  }
  .rt-thead .rt-th.-sort-asc {
    box-shadow: none !important;
  }
  .-pagination .-btn {
    border-radius: 8px;
  }
  .rt-table {
    height: 40vh;
    max-height: 40vh;
    overflow: auto;
    border: none;
  }

  .rt-thead {
    color: $label !important;
    background: #c8c8ca20;
    height: 60px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: none;
  }
  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: "";
      border: solid $light-green;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      margin-top: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      float: right;
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &:before {
      content: "";
      float: right;
      border: solid $light-green;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      margin-top: 8px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }
}

.case-details-container {
  padding: 0 56px;
  @media (max-width: 839px) {
    padding: 0 8px;
  }
}

.retainer-model-ts .case-details-container {
  padding: 0px 10px;
  @media (max-width: 839px) {
    padding: 0px;
  }
}

.ModalHeaderClosing .ModalHeaderClosing .close {
  // display: none;
  font-size: 30px;
}
.rounded-img {
  img {
    border-radius: 50%;
  }
}
.inbox-heartbit {
  width: fit-content;

  .notify-in-ts-padding .notify {
    top: -15px;
  }
  .notify {
    position: relative;
    top: -20px;
    right: -11px;
    .heartbit {
      position: absolute;
      top: -14px;
      right: -7px;
      height: 25px;
      width: 25px;
      z-index: 10;
      border: 5px solid $light-green;
      border-radius: 70px;
      -moz-animation: heartbit 1s ease-out;
      -moz-animation-iteration-count: infinite;
      -o-animation: heartbit 1s ease-out;
      -o-animation-iteration-count: infinite;
      -webkit-animation: heartbit 1s ease-out;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
    .point {
      width: 6px;
      height: 6px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-color: $light-green;
      position: absolute;
      right: 3px;
      top: -4px;
    }
  }
}

.lb-container .lb-header {
  background: linear-gradient(
      360deg,
      #c8c8ca -6.25%,
      rgba(255, 255, 255, 0) 13.28%
    ),
    #f5f5f5 !important ;

  box-shadow: 0px 0px 0px 0px none !important;

  // flex-direction: column;
  // @media (max-width: 800px) {
  //   .flex-container {
  //     display: flex;
  //     flex-direction: row;
  //   }
  // }
}
.lb-container {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(181, 181, 181, 0.75);
  /* Blurred background */

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 800px) {
    .flex-container {
      display: flex;
      flex-direction: column;
    }
  }
}
.lb-header .lb-title {
  color: #515151 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 22px !important;
  line-height: 28px !important;
}
.lb-header .lb-title span {
  padding: 0px 32px;
  order: -1;
}
.lb-icon-arrow {
  order: 1;
}
.lb-icon-zoomin {
  order: 2;
}
.lb-icon-zoomout {
  order: 3;
}
.lb-icon-rotate {
  order: 4;
}
.lb-icon-reset {
  order: 5 !important;
}
.lb-icon-close {
  order: 6 !important;
}
.lb-canvas .lb-img {
  // border-color: red;
  // border-style: solid;
  // border-width: 5px;
  border: 20px solid #ffffff;
  border-radius: 8px;
}

.lb-canvas {
  -webkit-transform: none !important;
}
.lb-header .lb-button:hover {
  background-color: #f5f5f5 !important;
}
.doctor_message {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-column-gap: 8px;
  align-items: flex-start;
  @media (max-width: 839px) {
    grid-template-columns: 0 1fr 50px;
  }
}
.doctor_message_contents {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  color: $label;
  .small-date {
    color: $label;
  }
}
.doctor_message_content {
  background: #75e6d9;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px 12px 12px 12px;
  text-align: end;
  padding: 8px 16px;
  max-height: 100px;
  overflow: auto;
}
.chat-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  padding: 0 16px;
  @media (max-width: 839px) {
    padding: 8px;
  }
}
.chat-item {
  @media (max-width: 839px) {
    margin-top: 0 !important;
  }
}
.admin_message {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-column-gap: 8px;
  align-items: flex-start;
  @media (max-width: 839px) {
    grid-template-columns: 50px 1fr 0;
  }
}
.admin_message_contents {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  color: $label;
  .small-date {
    color: $text-color;
  }
}
.admin_message_content {
  background: $input-bg;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px 12px 12px 12px;
  text-align: start;
  padding: 8px 16px;
  max-height: 100px;
  overflow: auto;
}
.inbox-textarea {
  width: 100% !important;
}
.text-area-grid {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  grid-column-gap: 16px;
  padding-right: 16px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }

  textarea {
    max-width: 100%;
  }
}

#main-wrapper[data-layout="vertical"][data-header-position="fixed"]
  .page-wrapper {
  padding-top: 64px !important;
}
.topbar {
  height: 64px !important ;
}

.gallery-container {
  color: $label;
}

.gallery-container {
  .card-img {
    aspect-ratio: 16/9;
    height: 100px;
    /* display: block; 
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #FFFFFF; */
    object-fit: contain;
  }

  .main-photos-grid {
    max-width: 960px;
    @media (max-width: 839px) {
    }
  }
}

.ts-shadow {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: 2px solid $light-green;
}
.share_link_toggle {
  position: relative;
  .share_link_content {
    position: absolute;
    right: -20px;
    z-index: 9999;
    @media (max-width: 839px) {
      bottom: -220px;
    }
  }
}
.share_img_link {
  position: relative;
  display: inline-block;
  @media (max-width: 839px) {
    display: none;
  }
}

.share_img_content {
  position: absolute;
  top: 18%;
  right: 5%;
  margin-bottom: 35px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
  @media (max-width: 839px) {
    position: relative;
    margin-bottom: 15px;
    max-width: 30em;
    background-color: #fff;
    padding: 1.125em 1.5em;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
      0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
    border: solid 1px $light-green;
    &:before {
      // layout
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      bottom: 100%;
      right: 3em;
      top: -10px;
      transform: rotate(45deg);
      border-radius: 0 0 12px 0;
      background-color: #fff;
      border-top: 1px solid $light-green;
      border-left: 1px solid $light-green;
    }
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }
}

.share_link_content_grid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-column-gap: 8px;
  padding-top: 18px;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
    width: 55vw;
  }
}
.copy-link-text {
  width: 400px;
  @media (max-width: 839px) {
    width: 100%;
  }
}

.light_green_icon {
  color: $light-green;
}
.v_align_middle {
  vertical-align: middle;
}
#page-numbers {
  list-style: none;
  display: flex;
}

#page-numbers > li {
  margin-right: 0.3em;
  color: $label;
  height: 32px;
  width: 32px;
  user-select: none;
  text-align: center;
  background: $white;
  cursor: pointer;
  border: 1px solid $light-gray;
  box-sizing: border-box;
  border-radius: 4px;
}
.pagination {
  display: inline-block;
  margin-top: 16px;
  margin: 0 auto;

  .disabled {
    pointer-events: none;
    background: $text-color;
    color: $white;
  }
}

.pagination li {
  color: black;
  float: left;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $light-gray;
  box-sizing: border-box;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  margin: 0 4px;
}
.pagination li div {
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  vertical-align: middle;
}
.pagination li.active {
  background-color: $light-green;
  color: white;
  cursor: pointer;
}

.pagination li:hover:not(.active) {
  background-color: #ddd;
}
.copy-link-group {
  input {
    padding-left: 32px;
  }
  #copy_link {
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
}

.model_ts {
  .logo-icon {
    display: none;
  }
  @media (max-width: 839px) {
    .logo-icon {
      display: block;
    }
  }
  .modal-content {
    border-radius: 0% !important;
  }

  // padding: 16px 32px 0px 32px;
}
.model_ts_navbar {
  display: grid;
  grid-template-columns: 1fr auto 1fr 1fr 1fr auto 1.7fr;
  align-items: baseline;
  background: #f5f5f5;
  //  place-items: center;
  border-radius: 10px;
  align-items: center;
  .logo-icon {
    display: block;
  }
}
.model_ts_nav-title {
  margin-bottom: 0px !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: #898a8d;
  padding: 16px 32px 0px;
}
.model_ts_nav_data {
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 0px !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #2e2e2f;
  padding: 0px 32px 16px;
}
.close_ts {
  width: 32px;
}
.inbox_ts {
  width: 20px;
  align-items: center;
}
.model_ts_nav-close-parent {
  align-self: center;
}
.model_ts_nav-close {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  align-items: baseline;
  grid-gap: 16px;
  height: 100%;
  align-self: center;
  place-items: center;
  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.inbox-container {
  position: relative;
  padding: 8px;
  cursor: pointer;
  width: 37px;
  border-radius: 50%;
  background-color: #fff;
  // margin-top: 5px;
}
.ts-main-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 18px;
  // height: calc(100vh - 200px);
  // overflow: auto;
  height: 100%;
}
.tsViewer {
  // padding: 8px 16px 16px 32px !important;
  height: calc(100vh - 180px);
  //overflow: auto;
  .container {
    padding: 0;
    height: 100%;
  }

  .c-tabs {
    height: 100%;
  }

  .case-info-container-ts {
    height: 100%;
  }
}
.tsViewer-tab {
  height: calc(100vh - 180px);
  // overflow: auto;
  .container {
    padding: 0;
    height: 100%;
  }

  .c-tabs {
    height: 95%;
  }

  .case-info-container-ts {
    height: 100%;
  }
  .tabs-content-height {
    height: calc(100vh - 230px) !important;
  }
}
.ts-dot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
}
.ts-dot-grid {
  grid-column-gap: 4px !important;
}
.ts-custom-tabs {
  font-size: 14px !important;
  width: 200px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 16px !important;
  color: #2e2e2f !important;
}
.ts-custom-tabs-retainer {
  font-size: 14px !important;
  width: 200px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 16px !important;
  color: #2e2e2f !important;

  @media (max-width: 839px) {
    font-size: 12px !important;
    width: 150px !important;
  }
}
.HT-ts {
  background: #f5f5f5;
  border-radius: 6px;
  margin-top: 16px;
  display: grid;
  align-items: baseline;
  grid-template-columns: 2fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
}
.HT-ts-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2e2e2f;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
}
.HT-ts-link {
  cursor: pointer;
  text-align: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Primary/Default */

  color: #00c7b1;
  width: fit-content;
}

.table-value {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 9.66667px;
  line-height: 12px;
  height: 35px;
  border-top: none;
  text-align: center;
  color: #2e2e2f;
}
.table-value-num {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 9.66667px;
  line-height: 12px;
  border-top: none;
  text-align: center;
  color: #2e2e2f;
}
.table th,
.table td {
  border-top: none !important;
}
.table-prop {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 9.66667px;
  line-height: 12px;

  align-items: baseline;
  text-align: baseline;

  color: #2e2e2f;
}
.movmentTable-ts {
  padding: 0px 32px 16px 32px;
}
.line {
  background-color: #c8c8ca !important ;
  height: 1px;
  border-width: 0;
}
.tsmodelTotalDiv {
  padding: 16px;
  height: 100%;
  @media (max-width: 839px) {
    padding: 0;
    .container {
      padding: 0;
    }
    .model_ts {
      display: grid;
      height: 70px;
      grid-template-columns: 1fr auto;
      align-items: center;
      padding: 1px;
    }
  }
}
.Parentlayout {
  // .tab-pane {
  //   height: calc(100vh - 300px) !important;
  //   overflow: auto;
  // }
  .c-tab-pane {
    height: calc(100vh - 300px) !important;
    overflow: auto;
  }
  // .ts-stepper{
  //   height: calc(100vh - 200px) !important;
  //   overflow: auto;
  // }
}
.subhead-container {
  margin-top: 24px;
}
.ts-modal-body {
  height: calc(100vh - 160px) !important;

  @media (max-width: 839px) {
    height: calc(100vh - 200px) !important;
  }
}
.revise_mobile_grid {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 16px;
  margin-top: 32px;
  img {
    width: 50px;
  }
  div {
    margin: 0 auto;
    text-align: center;
    padding: 0px 0px 40px 0px;
    color: #00c7b1;
  }
}
.spaceeInTS {
  // padding-right: 40px;
  // padding-left: 8px;
}
.notesGrid {
  display: grid;
  padding-top: 40px;
  // height: calc(100vh - 320px);
  grid-template-rows: auto 1fr;
  grid-gap: 32px;
}
.parentNote {
  height: calc(100vh - 320px);
}
.TechnicianNotes {
  .header_title {
    height: calc(100vh - 320px);
    grid-template-rows: 1fr 1fr;
    grid-gap: 16px;
  }
}
.TechnicianNotes {
  .header_title {
    font-weight: normal;
  }
}
.ManufacturingNotes {
  .header_title {
    font-weight: normal;
  }
}
.ManufacturingNotes-text {
  height: 100px;
  padding: 16px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
}
.TechnicianNotes-text {
  height: 100px;
  padding: 16px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
}
.onboarding-btn-save {
  color: #ffffff;
  border: 2px solid #00c7b1;
  border-radius: 20px;
  background-color: #00c7b1;
  width: 156px;
  height: 42px;
  grid-row: 1;
  grid-column: 2;
  @media (max-width: 839px) {
    width: 141px;
    height: 42px;
  }
}
.onboarding-btn-skip {
  border: 2px solid #00c7b1;
  color: #00c7b1;
  border-radius: 20px;
  background-color: #ffffff;
  width: 156px;
  height: 42px;
  grid-row: 1;
  grid-column: 1;
  box-shadow: none;
  &:hover {
    border: 2px solid #75e5d9;
    box-sizing: border-box;
    color: #75e5d9;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: none;
  }
  @media (max-width: 839px) {
    width: 141px;
    height: 42px;
  }
}
.back-button-container {
  .white-btn-ts {
    border: 2px solid $light-green;
    box-sizing: border-box;
    color: $light-green;
    font-family: Poppins;
    background-color: $white;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    box-shadow: none;
    // min-width: 120px;
    // padding: .5rem 1rem!important;
    padding: 8px 32px !important;
    @include hover {
      border: 2px solid #75e5d9;
      box-sizing: border-box;
      color: #75e5d9;
      background-color: $white;
      border-radius: 20px;
      box-shadow: none;
    }
    &.disabled,
    &:disabled {
      pointer-events: none;
      color: $light-gray !important;
      border: 2px solid $light-gray !important;

      background-color: $white;
      @include box-shadow(none);
    }
    &:active {
      background: $white !important;
      border: 2px solid #00776a !important;
      color: #00776a !important;
      box-shadow: none !important;
    }
    @media (max-width: 839px) {
      min-width: 100px;
      width: 100%;
    }
  }
}

.pointer {
  cursor: pointer;
}
.total-height {
  height: calc(100vh - 220px);
  overflow: auto;
}
.back-container {
  .main-inbox-grid {
    grid-template-columns: 1fr;
    padding-top: 32px;
  }
  .doctor_message_content {
    background: rgba(117, 230, 217, 0.3) !important;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
    border-radius: 0px 9.31517px 9.31517px 9.31517px !important;
    // transform: matrix(-1, 0, 0, 1, 0, 0)  !important;
  }
  .custome-textarea {
    background: #f5f5f5 !important;
    /* Text Field Shadow */

    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
  }
  .form-control {
    border: 1px normal !important;
  }
  .text-area-grid {
    // grid-template-columns: 1fr !important;
    padding-right: 0% !important;
  }
  .title_active {
    display: none;
  }
}

.ts-close-button {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  right: 0%;
  top: -40px;
  padding-right: 32px;
  color: #898a8d;
}
.ts-close-button-none {
  display: none;
}
.container-disable-layout {
  position: relative;
}
.disable-layout {
  position: absolute;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
  background: white;
  opacity: 0.2;
}
.ToothNumber-Grid {
  display: grid;
  grid-template-columns: 1fr 8px 2fr 1fr;
  grid-column-gap: 5px;
  place-items: center;
  .ts_nav_grid {
    display: grid;
    grid-template-columns: 0.5fr repeat(10, minmax(auto, 1fr)) 0.5fr;
    grid-column-gap: 16px;
    align-items: bottom;
    text-align: center;
  }
}
.zoom_in_out_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}
.position_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  place-items: center;
  line-height: 20px;
  div {
    align-self: flex-end;
  }
  svg {
    align-self: center;
  }
}

.tooth_movement_container {
  .header_title {
    font-weight: 500;
  }
}

.select_tooth_to_edit {
  font-family: Poppins;
  display: grid;
  height: calc(100vh - 700px);
  place-items: center;
  font-size: 18px;
  line-height: 24px;
  color: $label;
}
.select_tooth_to_edit_ipr {
  font-family: Poppins;
  display: grid;
  grid-template-columns: auto auto 1fr;
  height: calc(100vh - 770px);
  width: fit-content;
  place-items: center;
  font-size: 18px;
  line-height: 24px;
  color: $label;
  margin: 0 auto;
  grid-column-gap: 8px;
  img {
    height: 18px;
    width: 18px;
  }
}
.increment-decrement-icon {
  cursor: pointer;
  &:active {
    .increment_decrement {
      fill: $light-green;
    }
  }
}

.save-movement {
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}
.save-movement-active {
  text-decoration: underline;
  color: $light-green;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  justify-self: end;
}
.updated-value {
  color: #ffb100;
}
.old-value {
  color: $light-gray;
}

.restrict_tooth {
  .updated-value {
    color: $disabled-text;
  }
  .increment-decrement-icon {
    cursor: default;
    path {
      fill: $white;
    }
    .increment_decrement {
      fill: $disabled-text;
    }
  }
  .tooth_movement_grid {
    color: $disabled-text;
  }

  .movement_value_grid {
    color: $disabled-text;
  }
  .value_grid {
    color: $disabled-text;
  }
}

.event-none {
  pointer-events: none;
}

.custome-select-disabled {
  .css-19bqh2r {
    color: $disabled-text !important;
  }
}

.ReasonsRejection-textarea {
  width: 100%;
  background: #f5f5f5;
  // margin-top: 32px;
  padding: 8px;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  align-items: left;
  @media (max-width: 839px) {
    width: 100%;
  }
}
.ReasonsRejection-subtitle {
  padding-top: 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #898a8d;
}
.ReasonsRejection-container-image {
  display: grid;
  position: relative;
  place-items: center;
}
.ReasonsRejection-image {
  padding-top: 32px;
  width: 90%;
  padding-left: 16px;
  height: auto;
}
.ReasonsRejection-container {
  top: 5%;
  // height: 35%;
  width: 40% !important;
  max-width: 40% !important;
  @media (max-width: 839px) {
    // height: 60%;
    top: 5%;
    width: 95%;
    max-width: 95%;
  }
}
.ReasonsRejection-container-button {
  padding: 23px;
}
.ReasonsRejection-button {
  float: right;
  padding-left: 60px;
  padding-right: 60px;
}
.biteChech-mainButton {
  background: none;
  color: $error;
  border-bottom: 1px solid $error;
  width: fit-content;
  cursor: pointer;
}

// #container {
//   height: 100% !important;
// }

.fa-plus-circle {
  color: #d8d8d8;
  cursor: pointer;
  &:hover {
    color: $light-green;
  }
  &:active {
    color: $light-green;
  }
}
.circle-active {
  color: $light-green;
}

.ipr_amount {
  background: $text-color;
}
.ipr_before_step {
  background: $light-gray;
}
.edited_ipr {
  background: #ffb100;
}
.exeeding_recommended {
  background: #ff5c00;
}
.ipr_circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: $white;
  line-height: 18px;
  font-size: 11px;
  margin: 4px 0;
}
.ipr_cir_val {
  transform: translate(1px, -35px);
}
.ipr_cir_val-adcance {
  display: none;
}
.ipr_cir_val_lower {
  transform: translate(1px, -15px);
}
.positions_image {
  img {
    aspect-ratio: 16/9;
    height: 29vh;
    width: 100%;
    object-fit: scale-down;
    /* left: 0px; */
    object-position: center;
  }
  text-align: start;
}

.active-right {
  width: fit-content;
  text-align: center;
  svg {
    .right {
      fill: $light-green;
    }
  }
  color: $light-green;
}
.not-active-right {
  width: fit-content;
  text-align: center;
  color: $label;
}

.not-active-front {
  width: fit-content;
  text-align: center;
  svg {
    path {
      fill: #515151;
    }
  }
  color: $label;
}

.active-front {
  width: fit-content;
  text-align: center;
  color: $light-green;
}
.active-left {
  width: fit-content;
  text-align: center;
  svg {
    .left {
      fill: $light-green;
    }
  }
  color: $light-green;
}

.not-active-left {
  width: fit-content;
  text-align: center;
  color: $label;
}

.ReasonsRejection-title {
  align-items: center;
}
.test-roles {
  color: #ff5c00 !important;
}

.ts-stepper {
  display: block;
  .multi-step {
    height: calc(100vh - 220px);
  }

  .progtrckr {
    display: grid;
    grid-template-columns: repeat(5, minmax(auto, 1fr));
    margin-bottom: 70px;
    align-items: center;
    margin-left: 10%;

    li {
      width: 100%;
    }
  }
  .footer-buttons {
    display: none;
  }
}
.ts-stepper-footer {
  display: grid;
  grid-template-columns: auto 1fr auto;
}
.ts-stepper-actions {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 8px;
  align-items: center;
  justify-content: end;
  text-align: center;
}
.ts-steps {
  height: calc(100vh - 320px);
  overflow: auto;
  @media (max-width: 839px) {
    height: auto;
    overflow: auto;
  }
}

.summary-tooth-title {
  color: $label;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
}
.summary-tooth-movement-title {
  color: $label;
  font-size: 14px;
  font-family: Poppins;
}
.cpr4 {
  padding-right: 4px;
}
.cpl4 {
  padding-left: 4px;
}
.approved-bite-record {
  color: $light-green;
  font-weight: 500;
  font-family: Poppins;
}
.rejected-bite-record {
  color: #ff5c00;
  font-weight: 500;
  font-family: Poppins;
}
.bite-record-summary {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
}

.summary-divider {
  margin: 16px 0;
  border: 1px solid $light-gray;
}
.note-disapprove {
  color: $light-green;
}
.confirmation-disapprove {
  color: $text-color;
}

.c-border-light {
  border: none;
  border-radius: 16px;
  //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  top: 15%;
  width: 50%;
  max-width: 50%;
  @media (max-width: 839px) {
    top: 5%;
    width: unset;
    max-width: unset;
  }
}
.c-border-light-address {
  border: none;
  border-radius: 16px;
  //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  // top: 15%;
  width: 50%;
  max-width: 50%;
  @media (max-width: 839px) {
    top: 5%;
    width: unset;
    max-width: unset;
  }
  .modal-content {
    border: none;
    @media (max-width: 839px) {
      max-width: 900px !important;
      border-radius: 12px !important;
      max-width: 90% !important;
      display: block;
      margin: auto;
    }
  }
}
.avanced-modal {
  border: none;
  border-radius: 16px;
  //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  top: 15%;
  width: 50%;
  max-width: 50%;
  @media (max-width: 839px) {
    top: 5%;
    width: 95%;
    max-width: 95%;
    height: 90%;
  }
  .modal-content {
    border: none;
    @media (max-width: 839px) {
      display: block;
      margin: auto;
    }
  }
}

.exit-button {
  width: 156px;
}

.referencing-advance-module-ts {
  .templete_module_grid {
    display: grid;
    grid-template-columns: 1fr 0.3fr 3fr;
    border-bottom: none;
    height: auto;
    // padding-bottom: 50px;
    @media (max-width: 839px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}

.pointer_disable {
  pointer-events: none;
  padding-left: 10px;
}
.admin-Grid-Advance {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  place-items: center;
  // align-items: baseline;
}
.admin-Grid-Advance-inner {
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center;
}

.customHeaderTS {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  place-items: unset;
}
.ts-close-button-new {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  right: 0%;
  top: 18px;
  padding-right: 32px;
  color: #898a8d;
}

.reative-close-ts {
  // position: relative;
}
.torque-header {
  .admin-Grid-Advance {
    padding-top: 16px;
  }
}

.teeth-selector-ts {
  .teeth_selector {
    max-width: 500px;
  }
}

.no-revision-data {
  display: grid;
  place-items: center;
  width: fit-content;
  text-decoration: underline;
  color: #515151;
  height: 100%;
  margin: 0 auto;
  margin-top: -24px;
  font-size: 14px;
}

.max-btn-width {
  max-width: 160px;
  padding: 2px !important;
  font-size: 12px !important;
  line-height: 16px !important;
}
.colorful {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Default */

  color: #00c7b1 !important;
}
.colorful-grig {
  padding: 8px 0px;
  display: grid;
  grid-template-columns: 1fr auto;
}
.colorful-update {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Default */

  color: #3366ff !important;
}
.colorful-validation {
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 21px !important;
  font-weight: 400 !important;

  /* identical to box height, or 143% */

  /* Primary/Default */

  color: #fc4b6c !important;
}

.red-text {
  float: left;
  padding: 8px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Default */

  color: #f91212 !important;
}
.optional_color {
  color: #c8c8ca;
}
.note-tooltip-gender {
  z-index: 0 !important;
  .note-tooltip {
    z-index: -1 !important;
  }
}
.custom-titlecase-rejected {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  display: flex;
  align-items: flex-end;
  color: #2e2e2f;
}

.record-color {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* Primary/Default */

  color: #00c7b1;
}
.record-photo {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  color: #00c7b1;
}

.tabes-case-details2 {
  // font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
}

.new-table-cile {
  padding-left: 0;
  padding-right: 0;
}

.ciles {
  background-color: #f4f4f4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
}
.ciles-row {
  // background-color: #f4f4f4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
}

.p0 {
  padding: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.ciles-text {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e2f;
  padding: 16px 8px;
  margin-bottom: 0px;
  width: 100%;
  @media (max-width: 839px) {
    text-align: center;
  }
}
.ciles-text2 {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2e2e2f;
  padding: 16px 8px;
  margin-bottom: 0px;
  width: 100%;
  @media (max-width: 839px) {
    text-align: center;
  }
}
.ciles-text-first {
  padding: 16px 8px 16px 32px;
  @media (max-width: 839px) {
    padding: 16px;
  }
}

.first-cile {
  border-top-left-radius: 12px;
  @media (max-width: 839px) {
    border-top-left-radius: 0px;
  }
}

.last-cile {
  border-top-right-radius: 12px;
  @media (max-width: 839px) {
    border-top-right-radius: 0px;
  }
}

.new-table-info {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e2f;
  padding: 16px 8px;
  margin-bottom: 0px;
  @media (max-width: 839px) {
    text-align: center;
  }
}

.new-table-info2 {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #2e2e2f;
  padding: 16px 8px;
  margin-bottom: 0px;
  @media (max-width: 839px) {
    text-align: center;
  }
}
.new-table-info-first {
  padding: 16px 8px 16px 32px;
  @media (max-width: 839px) {
    padding: 16px;
  }
}

.new-history {
  width: 55vw;
  @media (max-width: 839px) {
    width: 100%;
  }
}

.p8_mobile {
  @media (max-width: 839px) {
    padding: 0px 8px;
  }
}
.p0_mobile {
  @media (max-width: 839px) {
    padding: 0px;
  }
}

.new-table {
  max-width: 65vw;
  width: 100%;
  @media (max-width: 839px) {
    max-width: 100%;
    width: 100%;
    padding-right: 0px;
  }
}

.new-nav {
  @media (max-width: 839px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.new-hr {
  @media (max-width: 839px) {
    margin-left: 0;
    width: 85%;
  }
}

.new-settings-buttons-grid {
  @media (max-width: 839px) {
    display: grid;
    grid-template-columns: 1fr;
    left: 0;
    right: 0;
    padding: 0;
    height: 80px;
    // position: fixed;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    // padding: 0px 16px;
  }
}
.new-addres-buttons-grid {
  @media (max-width: 839px) {
    left: 0;
    right: 0;
    padding: 0;
    height: 80px;
    display: grid;
    grid-template-columns: 1fr;
    position: fixed;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    padding: 0px 16px;
  }
}

.new-addres-buttons-grid-onPreferences {
  @media (max-width: 839px) {
    left: 0;
    right: 0;
    padding: 0;
    height: 80px;
    display: grid;
    grid-template-columns: 1fr;
    // position: fixed;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    // padding: 0px 16px;
  }
}
.new-edit-addres-buttons-grid {
  @media (max-width: 839px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    height: 80;
    bottom: 0px;
    // top: calc(100vh - 280px);
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;
    background-color: #fff;
    width: 100%;
    padding: 0rem 2rem;
    border-radius: 0px 0px 12px 12px;
  }
}

.new-general-settings-grid {
  @media (max-width: 839px) {
    // max-height: calc(100vh - 375px);
  }
}

.new-btn-height {
  @media (max-width: 839px) {
    height: 40px;
    width: 100%;
    justify-self: center;
    align-self: center;
    margin-bottom: 16px;
  }
}

.mb136 {
  // @media (max-width: 839px) {
  //   margin-bottom: 88px;
  // }
}

.new-addres-delete-buttons {
  @media (max-width: 839px) {
    display: grid;
    grid-row-gap: 12px;
    grid-template-columns: 1fr;
  }
}

.addres-delete-margin {
  margin-left: 8px;
  @media (max-width: 839px) {
    margin-left: 0px;
  }
}

.header-container {
  @media (max-width: 839px) {
    .c-line::after {
      display: inline-block;
      content: "";
      border-top: none;
      width: 0;
      margin: 0;
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
  }
}

// .button-cont{
//   height: 30vh;
//   max-width: 30vh;
// }

.mobile-but-adv {
  @media (max-width: 839px) {
    padding: 0.5rem 7rem !important;
    float: none !important;
  }
}

.btn-adv-mobile {
  @media (max-width: 839px) {
    // display: grid;
    place-items: center;
  }
}

.image-cassSubmission-mobile label {
  display: flex !important;
}
.summary-casedetails {
  @media (max-width: 839px) {
    font-size: 12px;
    font-weight: bold;
  }
}

.confirmation-model-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  float: right;
  // grid-gap: 1cm;
  @media (max-width: 839px) {
    float: unset;
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
}

.onboarding-skip-text {
  padding-top: 16px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
  float: right;
  @media (max-width: 839px) {
    float: right;
  }
}

.confirmation-model-grid .light-green-btn {
  @media (max-width: 839px) {
    width: 100%;
  }
}
.w80 {
  width: 75%;
}

.new-send-btn {
  @media (max-width: 839) {
    width: 60% !important;
  }
}
.small-header {
  font-size: 18px !important;
  font-weight: normal !important;
  letter-spacing: normal !important;
  line-height: normal !important;
}
.close-hybrid-treatment {
  position: relative;
  .ts-close-button-new {
    transform: translateY(-40px);
    padding-right: 0;
  }
}
.hybrid-treatment-container {
  height: calc(100vh - 250px);
  overflow: auto;
}
.hybrid-treatment-container_summary {
  height: calc(100vh - 271px);
  overflow: auto;
}

.hybrid_text_note {
  color: $text-color;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}
.braces_before_aligner_img {
  position: relative;
  width: fit-content;
  float: right;
  margin-right: 150px;
}

.braces_before_aligner_img2 {
  position: absolute;
  right: 1.5%;
  top: 15%;
}

.retainer-header-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.retainer-header {
  text-align: left;
}

.photoRetainer {
  display: none;
}
.display-retainer-type {
  display: none;
}
.hybrid_both_types_title {
  font-weight: 500;
  font-family: Poppins;
  color: $label;
  margin-bottom: 8px;
}

.grid-Stepper {
  display: grid;
  grid-template-columns: 1fr auto;
}

.step-number-grid {
  margin-top: 23px;
  color: black;
  font-weight: bold;
  transform: translateX(-0.75em);
}
.label-step-title {
  text-align: center;
  // font-family: Poppins;
  // font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #2e2e2f;
}
.container-footer-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  padding: 0 !important;
  padding-top: 24px !important;
  @media (max-width: 839px) {
    display: block;
    // padding: 24px 0px 80px 0px !important;
  }
}
.container-footer-mobile-bite {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  padding: 0 !important;
  padding-top: 24px !important;
  @media (max-width: 839px) {
    display: unset;
  }
}
.container-footer-mobile-fix {
  display: grid;
  @media (max-width: 839px) {
    padding: 24px 0px !important;
  }
}

.none {
  display: inline-block;
  @media (max-width: 839px) {
    display: none;
  }
}

.none-c-divider {
  display: none;
}
.order-save-button {
  grid-column: 2;
  @media (max-width: 839px) {
    margin-bottom: 42px;
  }
}

.head-mobile-caseSubmission {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  display: flex;
  align-items: center;

  /* Primary/Default */

  color: #00c7b1;
  margin-top: 18px;
}

.naming-in-summary {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  // line-height: 24px;
  /* or 114% */

  letter-spacing: 0.04em;

  /* Primary/Default */

  color: #00c7b1;
}

.notifacation-container-inMobile {
  display: none;
  @media (max-width: 767px) {
    display: inline-block;
  }
}

.notifacation-container-desktop {
  display: inline-block;
  @media (max-width: 767px) {
    display: none;
  }
}
.both_type_note {
  color: $light-green;
  text-align: end;
  // border-bottom: 1px solid $light-gray;
  width: 100%;
  display: inline-block;
}
.revision_submitted_successfully {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  grid-column-gap: 16px;
  height: calc(100vh - 270px);
}
.hybrid_submitted_successfully {
  font-size: 24px;
  color: $text-color;
}
.circle-orange {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
  background-color: #ff5c00;
}

.re-res-title-prop .modal-title {
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  padding: 16px 16px 0px 16px;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary/Default */

  color: #00c7b1 !important;
}

.overFlow {
  overflow: hidden;
}

.customHeaderTS {
  color: $disabled-text;
}
.admin-Grid-Advance {
  color: $disabled-text;
}
.D-head-ts {
  color: $disabled-text !important;
}

.D-toggle-ts {
  background-color: $disabled-text !important;
}

.D-total-cont {
  color: $disabled-text !important;

  .header_title {
    color: $disabled-text !important;
  }
}

.D-total-cont-toggle {
  .custom-toggle input:checked[type="checkbox"] {
    color: $disabled-text !important;
    background-color: $disabled-text !important;
  }
  .custom-toggle input:checked[type="checkbox"]:before {
    background-color: $disabled-text !important;
  }
}

.D-total-cont-checkBox {
  .radio-item label {
    color: $disabled-text !important;
    cursor: unset !important;
  }
  .replacment-checkbox .custom-control-label::after {
    background-color: $disabled-text !important;
    cursor: unset !important;
  }

  .radio-item input[type="radio"]:checked + label:after {
    background-color: $disabled-text !important;
    cursor: unset !important;
  }
  .custom-checkbox .custom-control-label {
    color: $disabled-text !important;
    cursor: unset !important;
  }
}

.Dsables-text {
  .radio-item label {
    color: $disabled-text !important;
    cursor: unset !important;
  }
}
.passive-aliginer-form-material {
  .form-material {
    input {
      color: $disabled-text !important;
      cursor: unset !important;
    }
  }
}
.desable-input-text {
  .backgrountTextarea {
    background-color: $disabled-text !important;
    // color: $disabled-text !important;
    cursor: unset !important;
  }
}

.disable-input {
  .backgrountTextarea {
    background-color: $disabled-text !important;
    // color: $disabled-text !important;
    cursor: unset !important;
  }
}

.floating {
  float: right;
}
.bold-line {
  font-weight: bold;
  font-family: Poppins;
}

.btn-adv-mobile .light-green-btn {
  @media (max-width: 839px) {
    width: 100%;
  }
}

.trem-grid {
  @media (max-width: 839px) {
    display: block;
    // grid-template-columns:  1fr;
    // grid-column-gap: 16px;
  }
}
.pointer-Event {
  pointer-events: none;
}

.bite_stepper {
  .step_indicator {
    margin: 3px !important;
  }
}
.bite_steps_container {
  height: calc(100vh - 350px);
  margin: 32px 0 0;
  overflow: auto;
  @media (max-width: 839px) {
    height: auto;
    padding-bottom: 12px;
    overflow: hidden !important;
  }
}

.case-submission-steps {
  height: calc(100vh - 290px);
  overflow: unset;
  @media (max-width: 839px) {
    height: auto;
  }
}
.green_text {
  color: $light-green !important;
}

.step-progress-adjustment {
  .step {
    height: 50vh;
    overflow: auto;
  }
}
.Impression_num_setImpression_num_set-container {
  padding-top: 30px;
}

.Impression_num_set {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-row-gap: 5px;
}
.Number_of_sets {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 10px;
}

.line-setNumber {
  text-align: start;
  align-items: flex-start;
  display: grid;
  // height: 10px;
  grid-template-columns: 4fr 1fr;
}

.line-setNumber-request {
  text-align: start;
  align-items: flex-start;
}
.ArchCheckBoxImpression {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 2fr;
  @media (max-width: 839px) {
    grid-template-columns: 0.5fr 1fr auto;
  }
}

.ArchSelectionCheckBox {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 8px;
  @media (max-width: 839px) {
    grid-template-rows: 1fr 1fr;
  }
}
.impression_CRG .impressions_method_Arch {
  width: 400px !important;
}

.impression-container-setNumber {
  height: 300px;
}
.white-btn-ts-container-in-grid .white-btn-ts {
  width: 150px;
  @media (max-width: 839px) {
    width: 100%;
    font-weight: 12px;
  }
}
.green-btn-ts-container-in-grid .green-green-btn {
  width: 150px;
  @media (max-width: 839px) {
    width: 100%;
    font-weight: 12px;
  }
}
.Impression_hieght {
  height: calc(100vh - 360px);
  overflow: auto;
  overflow-x: clip;
}

.Impression_hieght_retainer_request {
  max-height: calc(100vh - 290px);
  overflow: auto;
  overflow-x: clip;
  @media (max-width: 839px) {
    // height: calc(100vh - 390px);
  }
}

.Impression_hieght_retainer_request_tabel {
  overflow-x: clip;
}
.tabelInRetainer {
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
}

.stepper-Bite-record-photos-and-xray .ts-steps {
  overflow: unset;
  @media (max-width: 839px) {
    height: calc(100vh - 270px);
    overflow: auto;
  }
}
.fix-photo-bite-record {
  overflow: auto;
  height: calc(100vh - 250px);
}
.Terms-bite-record {
  .terms-info {
    // height: 42vh !important;
    height: calc(100vh - 380px) !important;
  }
}
.Prescription-bite-record .patient-info-container {
  height: calc(100vh - 380px);
  max-height: calc(100vh - 380px);
}
.Prescription-bite-record {
  overflow: auto;
}

.Impressions-bite-record {
  overflow: auto;
  height: calc(100vh - 150px);
}

.Impressions-bite-record .patient-info-container {
  height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);
}

.Impressions-bite-record .container-footer-mobile {
  display: flow-root;
}
.Prescription-bite-record .container-footer-mobile {
  display: flow-root;
}

.step-progress-adjustment ol.progtrckr {
  padding-left: 13%;
}
.fix-photo-bite-record-container .fix-photo-bite-record {
  overflow: unset;
  height: auto;
}
.footerAdjusment {
  .card-footer {
    border-top: none;
  }
}

.retainer-request-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // border-radius: 12px;
}
.tabel-body-total {
  max-height: calc(100vh - 390px);
  overflow: auto;
}
.retainer-request-table-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.tabel-request-retqiner-container-total {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  padding: 40px 0px;
  @media (max-width: 839px) {
    padding: 10px 0px;
    grid-template-columns: 1fr;
  }
}
.tabel-request-retqiner-container {
  // padding: 20px 100px;
  box-shadow: 0px 6.80563px 13.6113px 1.70141px rgba(97, 97, 97, 0.1),
    0px 13.6113px 27.2225px 1.70141px rgba(97, 97, 97, 0.1);
  border-radius: 10.2085px;
}

.font_request {
  font-size: 14px !important;
  font-weight: 500;
}

.font_request_body {
  font-size: 14px !important;
  font-weight: 500;

  @media (max-width: 839px) {
    font-size: 12px !important;
  }
}
.inmobile {
  display: none;
  @media (max-width: 839px) {
    display: unset;
    margin-bottom: 182px;
  }
}
.inmobile2 {
  display: none;
  @media (max-width: 839px) {
    display: block;
    margin-bottom: 82px;
  }
}
.inWebsit {
  // display: unset;
  @media (max-width: 839px) {
    display: none;
  }
}
.marginTop {
  margin-top: 12px !important;
}

.inmobile {
  .light-green-btn {
    margin-bottom: 12px !important;
  }
}
.advanceModuleInmobile {
  display: none;
  @media (max-width: 839px) {
    display: unset;
  }
}
.advanceModuleInWebsite {
  display: unset;
  @media (max-width: 839px) {
    display: none;
  }
}
.retainer-mobile-total-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 12px;
  @media (max-width: 839px) {
    padding-top: 16px;
  }
}
.title-impression {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  color: #2e2e2f;
}
.label-in-classlll {
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  color: #2e2e2f;
}
.ipr-teeth-selector-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.backgrountTextareaContainer {
  .backgrountTextarea {
    width: 100% !important;
  }
}

.displayNone_inmobile {
  display: none;
  @media (max-width: 839px) {
    display: unset;
  }
}
.displayNone_website {
  display: unset;
  @media (max-width: 839px) {
    display: none;
  }
}
.full-width {
  width: 100%;
}
.head-mobile-caseSubmission-container {
  .head-mobile-caseSubmission {
    margin-top: 12px;
  }
}

.patient-terms {
  @media (max-width: 839px) {
    position: absolute;
    top: 1px;
  }
}
.case-terms {
  @media (max-width: 839px) {
    position: absolute;
  }
}

.displayNone {
  @media (max-width: 839px) {
    display: none;
  }
}
.grid-note-block-mobile {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 90px 40px 0px 40px;
  grid-row-gap: 12px;
}
.bolcked-title-mobile {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #000000;
}
.bolcked-body-mobile {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* or 150% */

  letter-spacing: -0.0008em;

  color: #515151;
}
.bolcked-body-mobile-email {
  font-style: normal;
  font-weight: bold;
}
.grid-note-block-mobile-inner {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 12px;
}

.margin-button {
  @media (max-width: 839px) {
    margin-bottom: 50px !important;
  }
}

.margin-top {
  @media (max-width: 839px) {
    margin-top: 20px;
  }
}
.margin-top2 {
  @media (max-width: 839px) {
    margin-top: 50px;
  }
}

.margin-top3 {
  @media (max-width: 839px) {
    margin-top: 30px;
  }
}

.fas {
  @media (max-width: 839px) {
    display: none !important;
  }
}

.pagination {
  .fas {
    @media (max-width: 839px) {
      display: unset !important;
    }
  }
}

.package-type-tooltip {
  .fas {
    @media (max-width: 839px) {
      display: unset !important;
    }
  }
}

.question-circle .fas {
  @media (max-width: 839px) {
    display: unset !important;
  }
}

.add-address-module-patiant {
  @media (max-width: 839px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 12px;
  }
}
.Prescription-bite-record {
  .add-address-module-patiant-height {
    @media (max-width: 839px) {
      overflow: auto;
      height: calc(100vh - 200px);
    }
  }
}

.add-address-height {
  @media (max-width: 839px) {
    overflow: auto;
    overflow-x: clip;
    height: calc(100vh - 200px);
  }
}

.Pagination-Container {
  display: grid;
  align-items: center;
}

.mobile-order {
  @media (max-width: 839px) {
    grid-row: 1;
  }
}

.treatment_setup_grid {
  .fas {
    @media (max-width: 839px) {
      display: unset !important;
    }
  }
}
.retainerPicker .react-datepicker {
  @media (max-width: 839px) {
    overflow: auto;
    height: 250px;
  }
}

.grid-approve-mobile {
  display: grid;
  grid-row-gap: 12px;
}

.custom-case-review-btn {
  width: 125px;
  height: 33px;
}

.approve_and_revise_grid {
  display: none;
  @media only screen and (max-width: 839px) {
    display: grid;
    // position: absolute;
    bottom: 50px;
    left: 0px;
    grid-template-columns: 0.2fr 1fr 1fr 0.2fr;
    grid-column-gap: 12px;
    width: 100%;
    margin-top: 12px;
  }
}
.steps-color {
  color: $black;
  font-weight: 400;
}
.pickup-container {
  padding-top: 24px;
  padding-left: 12px;
  color: #515151;
}
.spaces_pickup {
  padding-top: 12px;
  color: black !important;
  padding-bottom: 12px;
}
.spaces_pickup_inner {
  padding-top: 12px;
}
.spaces_pickup_inner_duable {
  padding-top: 50px;
}
.spaces_padding_buttom {
  padding-bottom: 50px;
}

.templete_module_grid {
  .tooth-extraction {
    @media only screen and (max-width: 839px) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}

.close-modules {
  position: absolute;
  right: 5px;
  padding: 5px;
  font-size: 24px;
  @media (max-width: 839px) {
    padding: 3px;
    right: 0;
  }
}

.center_num {
  place-content: center;
  align-items: center;
  display: grid;
}

.physical_padding_top {
  padding-top: 16px;
}

.confirmResume_grid {
  display: grid;
  grid-template-columns: 100px 100px;
  grid-gap: 12px;
  align-self: end;
  place-content: end;
  margin-top: 8px;
  @media (max-width: 839px) {
    display: grid;
    margin-top: 24px;
    grid-template-columns: 1fr;
  }
}

.exit_summary_button_position {
  grid-column: 2;
  align-items: end;
  place-self: end;
}

.add-container {
  .fas {
    @media (max-width: 839px) {
      display: unset !important;
    }
  }
}

.custom-mb {
  @media (max-width: 839px) {
    margin-bottom: 40px;
  }
}
.tooltips-container {
  display: inline-flex;

  align-items: end;
}
.eon_header_text_2 {
  @media (max-width: 839px) {
    display: block;
  }
}
.eon_basic_footer {
  padding-top: 8px;
  @media (max-width: 839px) {
    width: 86%;
  }
}
.eon-basic-tooltip {
  display: grid;
  justify-items: center;
  padding: 16px;
  text-align: center;
  .eon-basic-img {
    width: 40%;
    height: auto;
    @media (max-width: 839px) {
      width: 60%;
      height: auto;
    }
  }
  .eon_basic_hedaer {
    padding: 16px 0px;
    @media (max-width: 839px) {
      width: 75%;
      padding: 16px 0px 32px 0px;
    }
  }
  .eon_plus_hedaer {
    padding: 16px 0px;
    @media (max-width: 839px) {
      width: 74%;
      font-weight: 400 !important;
      font-size: 18px !important;
      padding: 16px 0px 48px 0px;
    }
  }
  .eon_pro_hedaer {
    padding: 16px 0px;
    @media (max-width: 839px) {
      width: 100%;
      line-height: 24px !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      padding: 16px 8px 32px 8px;
    }
  }
  .eon_basic_text_3 {
    padding: 8px 0px 24px 0px;
    @media (max-width: 839px) {
      width: 57%;
      padding: 16px 0px 32px 0px;
    }
  }
}
.eon_basic_text_2 {
  @media (max-width: 839px) {
    display: block;
    padding: 8px 0px;
  }
}
.eon_pro_text_3 {
  padding: 8px 0px 24px 0px;
  width: 75%;
  @media (max-width: 839px) {
    width: 100%;
    padding: 24px 0px 32px 0px;
  }
}

.tooltip_showUpperRight {
  position: absolute;
  display: inline-block;
}
.tooltip_showUpperRight .tooltiptext_showUpperRight {
  visibility: hidden;
  width: 150px;

  top: -45px;
  font-size: 10px;
  background-color: #898a8d;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  @media (max-width: 839px) {
    left: -10px;
    width: 130px;
  }
}

.tooltip_showLowerRight {
  position: absolute;
  display: inline-block;
}
.tooltip_showLowerRight .tooltiptext_showLowerRight {
  visibility: hidden;
  width: 150px;

  top: 190px;
  // top: 240px;
  font-size: 10px;
  background-color: #898a8d;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  @media (max-width: 839px) {
    top: 75px;
    left: -10px;
    width: 130px;
  }
}

.tooltip_showUpperLeft {
  position: absolute;
  display: inline-block;
}
.tooltip_showUpperLeft .tooltiptext_showUpperLeft {
  visibility: hidden;
  width: 150px;

  top: -93px;
  left: 90px;
  font-size: 10px;
  background-color: #898a8d;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  line-height: 15px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;

  @media (max-width: 839px) {
    left: -10px;
    top: -90px;
    width: 130px;
  }
}

.tooltip_showLowerLeft {
  position: absolute;
  display: inline-block;
}
.tooltip_showLowerLeft .tooltiptext_showLowerLeft {
  visibility: hidden;
  width: 150px;

  top: 190px;
  left: 90px;
  font-size: 10px;
  background-color: #898a8d;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  line-height: 15px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  @media (max-width: 839px) {
    top: 77px;
    left: -10px;
    width: 130px;
  }
}

.booking {
  text-decoration-line: underline;
  color: #00c7b1;
}
.p-all {
  padding: 5px;
}

.booking:hover {
  text-decoration-line: underline;
  color: #00c7b1;
}

.Bronze {
  path,
  circle {
    fill: #c68902;
  }
}
.Silver {
  path,
  circle {
    fill: #c4c4c4;
  }
}

.Gold {
  path,
  circle {
    fill: #ffb818;
  }
}

.Platinum {
  path,
  circle {
    fill: #4f5665;
  }
}

.Diamond {
  path,
  circle {
    fill: #74b1be;
  }
}

.Elite {
  path,
  circle {
    fill: #00c7b1;
  }
}

.tooltip_showUpperRight i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: -70px;
  top: -8px;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: $text-color;
  box-shadow: 0px 2px 4px rgba(33, 36, 41, 0.05);

  @media (max-width: 839px) {
    right: -50px;
  }
}

.tooltip_showLowerRight i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: -70px;
  bottom: -204px;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: $text-color;
  box-shadow: 0px 2px 4px rgba(33, 36, 41, 0.05);

  @media (max-width: 839px) {
    bottom: -124px;
    right: -50px;
  }
}

.tooltip_showUpperLeft i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: -160px;
  top: -55px;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: $text-color;
  box-shadow: 0px 2px 4px rgba(33, 36, 41, 0.05);
  @media (max-width: 839px) {
    right: -95px;
    top: -53px;
  }
}

.tooltip_showLowerLeft i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  right: 165px;
  top: 192px;
  right: -165px;
  transform: translate(50%, -50%) rotate(-45deg);
  background-color: $text-color;
  box-shadow: 0px 2px 4px rgba(33, 36, 41, 0.05);
  @media (max-width: 839px) {
    right: -95px;
    top: 114px;
  }
}

.benefits-note {
  margin-left: 30px;
  color: rgb(0, 196, 179);
  text-decoration: underline;
  cursor: pointer;
}

/* Tooltip container */
.ctooltip {
  position: relative;
  // display: inline-block;
  @media (max-width: 839px) {
    position: unset;
  }
}

/* Tooltip text */
.ctooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #898a8d;
  color: #fff;
  text-align: start;
  font-size: 12px;
  border-radius: 10px;
  padding: 4px;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 125%;
  // left: 50%;
  // margin-left: -60px;
  transform: translateX(-58%);
  @media (max-width: 839px) {
    width: 130px;
    transform: translateX(-10%);
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.ctooltip:hover .tooltiptext {
  visibility: visible;
}
.ctooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #898a8d transparent;
  @media (max-width: 839px) {
    display: none;
  }
}

//top

.ctooltipTop {
  position: relative;
  //  display: inline-block;
  @media (max-width: 839px) {
    position: unset;
  }
}

.ctooltipTop .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #898a8d;
  color: #fff;
  text-align: start;
  border-radius: 10px;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  // left: 50%;
  // margin-left: -60px;
  transform: translateX(-55%);
}

.ctooltipTop .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #898a8d transparent transparent transparent;
  @media (max-width: 839px) {
    display: none;
  }
}

.ctooltipTop:hover .tooltiptext {
  visibility: visible;
}

#teeth_container_grid.top-left {
  @media (max-width: 839px) {
    bottom: 70% !important;
    width: 130px !important;
    transform: unset !important;
    right: 0 !important;
  }
}
#teeth_container_grid.top-right {
  @media (max-width: 839px) {
    left: 0 !important;
    transform: translateX(0) !important;
    bottom: 70% !important;
    width: 130px !important;
  }
}
#teeth_container_grid.lower-left {
  @media (max-width: 839px) {
    top: 49% !important;
    width: 130px !important;
    right: 0 !important;
    transform: translateX(0) !important;
  }
}
#teeth_container_grid.lower-right {
  @media (max-width: 839px) {
    top: 50% !important;
    width: 130px !important;
    left: 0 !important;
    transform: translateX(0) !important;
  }
}

.custome_tooltipTop {
  position: unset;
  //  display: inline-block;
  @media (max-width: 839px) {
    position: unset;
  }
}

.custome_tooltipTop .right_tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #898a8d;
  color: #fff;
  text-align: start;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: -18px;
  right: 130%;
  @media (max-width: 839px) {
    left: 130%;
    right: unset;
  }
}

.custome_tooltipTop .right_tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #898a8d;

  @media (max-width: 839px) {
    right: 100%;
    left: unset;
    border-color: transparent #898a8d transparent transparent;
  }
}
.custome_tooltipTop:hover .right_tooltiptext {
  visibility: visible;
}

.custome_tooltipTop .left_tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #898a8d;
  color: #fff;
  text-align: start;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: -18px;
  left: 130%;
  @media (max-width: 839px) {
    right: 130%;
    left: unset;
  }
}

.custome_tooltipTop .left_tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #898a8d transparent transparent;

  @media (max-width: 839px) {
    left: 100%;
    right: unset;
    border-color: transparent transparent transparent #898a8d;
  }
}
.custome_tooltipTop:hover .left_tooltiptext {
  visibility: visible;
}

.container-height-loyalty-page {
  max-height: calc(100vh - 130px);
  overflow: auto;
  @media (max-width: 839px) {
    max-height: 100%;
    overflow: unset;
  }
}

/***************/
.top-right2 {
  @media (max-width: 839px) {
    left: 0 !important;
    transform: translateX(-10%) !important;
  }
}
.ctooltipTopBlack {
  position: relative;
  //  display: inline-block;
  @media (max-width: 839px) {
    position: relative;
  }
}

.ctooltipTopBlack .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: #000000;
  color: #fff;
  text-align: start;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  // left: 50%;
  // margin-left: -60px;
  transform: translateX(-45%);
}

.ctooltipTopBlack .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 15%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
  // @media (max-width: 839px) {
  //   display: none;
  // }
}

.ctooltipTopBlack:hover .tooltiptext {
  visibility: visible;
}

.ctooltipTopBlack .tooltiptextSmall {
  visibility: hidden;
  width: max-content;
  background-color: #000000;
  color: #fff;
  text-align: start;
  border-radius: 8px;
  padding: 12px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  // left: 50%;
  // margin-left: -60px;
  transform: translateX(-25%);
}

.ctooltipTopBlack .tooltiptextSmall::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 15%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
  // @media (max-width: 839px) {
  //   display: none;
  // }
}

.ctooltipTopBlack:hover .tooltiptextSmall {
  visibility: visible;
}

.pickup_address_grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  @media (max-width: 839px) {
    grid-template-columns: 1fr 1fr;
  }
}

.select-address-impression-width {
  width: 50%;
  @media (max-width: 839px) {
    width: 90%;
  }
}

.treatment-set-up-pop-up {
  background: rgba(48, 132, 240, 0.1);
  border-radius: 8px;
  align-items: center;
  padding: 12px 16px;
  gap: 64px;
  margin-bottom: 16px;
}
.attention-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 0px 0px 0px;
  line-height: 16px;
  color: #3084f0;
}
.Heads-up-pop-up {
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  background: #f7e9c9;
  border-radius: 8px;
}

.Heads-up-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: #2e2e2f;
}

.attention-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0px;

  color: #2e2e2f;
}

.CaseType_change_button {
  color: #0070ff;
  position: relative;

  text-decoration: underline;
  -webkit-text-decoration-color: #0070ff; /* Safari */
  text-decoration-color: #0070ff;
  padding: 0px 12px;
  cursor: pointer;
}

.CaseType_change_title {
  color: #2e2e2f !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 20px;
}

.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.caseType_and-arch-popup {
  border: 1px solid #c8c8ca;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: white;
  padding: 1rem;
  width: 268px;
  height: auto;
  // min-height: 100px;
  &.left {
    display: block;
    transform: translate(50%, 52%);

    z-index: 99;
    bottom: -3rem;
    &::after {
      content: " ";
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 13px solid rgb(255, 255, 255);

      position: absolute;
      left: -10px;
      top: calc(22% - 14px);
    }
  }
}

.popup-arch {
  border: 1px solid #c8c8ca;
  border-radius: 8px;
  background-color: white;
  padding: 1rem;
  width: 268px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  height: auto;
  // min-height: 100px;
  &.left {
    display: block;
    transform: translate(50%, 35%);

    z-index: 99;
    bottom: -3rem;
    &::after {
      content: " ";
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 13px solid rgb(255, 255, 255);

      position: absolute;
      left: -10px;
      top: calc(35% - 14px);
    }
  }
}
.case_type_popup {
  position: absolute;
}

.withBorder {
  border: 1px solid #c8c8ca;
  border-radius: 8px;
  padding: 0px 4px;
}

.withBorderActive {
  border: 1px solid #00c7b1;
  border-radius: 8px;
  padding: 0px 4px;
}
.caseType-popup-button {
  display: grid;
  grid-gap: 12px;
  margin-top: 8px;
  grid-template-columns: 1fr auto auto;
}

.Arch-popup-button {
  display: grid;
  grid-gap: 12px;
  // margin-top: 8px;
  grid-template-columns: 1fr auto auto;
}
.caseType-popup-button-add {
  padding: 4px 8px;
  background-color: #3084f0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
}

.caseType-popup-button-close {
  font-size: 12px;
  color: #898a8d;
  line-height: 20px;
  cursor: pointer;
  background-color: white;
  padding: 4px 0 8px;
}

.CaseType-popup-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 4px;
  line-height: 20px;
  color: #2e2e2f;
}

.medFont {
  font-size: 14px;
  padding: 0px 8px;
}

.checkBox-arch-container {
  padding: 8px;
  border: 1px solid #c8c8ca;
  border-radius: 8px;
}

.checkBox-arch-container-active {
  border: 1px solid #00c7b1;
}

//   .disabled-custom-checkbox{
//     color: rgb(175, 174, 174);
//   }

//   .padding-custom-checkbox{
//     padding: 0px 8px  !important;
//   }

//   .input-container-checkbox-new {
//     position: relative;
//     display: grid;
//     grid-template-columns: auto 1fr;
//     align-items: center;
//     margin: 1px 0px;
//     padding: 0 2px;
//   }

//   input.button-text-select_checkbox-new,
// label.button-text-select_checkbox-new {
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   color: #898a8d;
//   margin-right: 8px;
//   @media (max-width: 839px) {
//     font-size: 12px;
//   }
// }

// .ml8{
//   margin-left: 8px;
// }
// .disabled{
//   background-color:#C8C8CA ;
//   pointer-events: none;

// }

.need-help-button {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  text-decoration-line: underline;

  color: #777777;
}
.case_package_changing_popup {
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 8px;
  grid-column-gap: 8px;
}

.case_package_changing_popup_title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  padding-top: 2px;
  font-size: 18px;
  line-height: 24px;

  color: #101840;
}

.case_package_changing_popup_body {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #484848;
}

.padding-case-package {
  padding-top: 12px;
}

.upgrade-steps {
  padding-top: 12px;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 0.5fr;
}
.first-upgrade-steps {
  background: #f0f0f0;
  border-radius: 5px;
  font-size: 14px;
  color: #707070;
  font-weight: 600;
  text-align: center;
  padding: 6px;
  text-decoration-line: line-through;
}
.sec-upgrade-steps {
  color: #3366ff;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding: 6px;
  background: rgba(51, 102, 255, 0.08);
  border-radius: 5px;
  line-height: 20px;
}

.image-step {
  display: grid;
  align-content: center;
  justify-content: center;
}

.case_package_changing_popup_button {
  padding: 12px;
  // display: grid;
  // grid-template-columns: 1fr 1fr ;
  // float: right ;
}
.case_package_changing_popup_button_floating {
  float: right;
}

.need-help-button {
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  text-decoration-line: underline;

  color: #777777;
}

// assessment tools classes
.tooltip-inner {
  // max-width: $tooltip-max-width;
  // padding: $tooltip-padding-y $tooltip-padding-x;
  // color: $tooltip-color;
  // text-align: center;
  // background-color: $tooltip-bg;
  // @include border-radius($tooltip-border-radius);
  display: flex;
  max-width: 260px;
  text-align: left;
  padding: 8px;
  background-color: #fff;
  color: #2e2e2f;
  opacity: 1;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.app-accordion .accordion-item .accordion-header .accordion-button {
  padding: 10px;
  font-size: 14px;

  color: #393838;
  justify-content: space-between;
  /* align-items: flex-start; */
}

.app-accordion .accordion-item .accordion-body {
  padding: 10px;
}

.app-accordion .accordion-button::after {
  background-color: #f1f1f1;
  border-radius: 5px;
  /* padding: 4px; */
  // background-image: url('./assets/images/chevron.svg');
  background-position: 2px 4px;
  background-size: 16px;
  margin-inline-start: 5px;
}

.app-accordion .checked .accordion-button::after {
  /* transform: unset; */
  background-color: transparent;
  background-image: url("../../../../views/assessment-tool/assets/images/chevron-check.svg");
  background-position: 2px 4px;
  background-size: 16px;
  margin-inline-start: 5px;
}

.app-accordion .selected-ans {
  padding: 10px;
  margin-bottom: 0px;
  font-weight: 600;
}

.disabled {
  background-color: #c8c8ca;
  pointer-events: none;
}
